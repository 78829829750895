import { useEffect, useState } from "react";
import { Card, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import {
  checkIntegrationUser,
  getAllIntegrations,
  getProjectIntegrations,
  getUpstashDatabases,
  deactivateIntegration,
  addProjectIntegration,
  getIntegrationEnvironmentVariablesList,
  connectExternalOAuth,
  disconnectIntegration,
  getCurrentCollaboratorDetails,
} from "../../network/ApiAxios";
import { CollaboratorDetails } from "../../models/CollaborationModels";
import UpstashDatabase from "../../components/AdvancedUI/Modals/UpstashDatabase";
import DeleteIntegrationModal from "../../components/AdvancedUI/Modals/DeleteIntegrationModal";
import DeactivateIntegrationModal from "../../components/AdvancedUI/Modals/DeactivateIntegrationModal";
import DatabaseConnect from "./DatabaseConnect";
import IntegrationBreadcrumbs from "./IntegrationBreadcrumbs";
import useProjectDetails from "../../hooks/useProjectDetails";
import { getCodeSnippetsOptions, UpstashOauthConnectionUrl } from "./utils";

const upstashEnum = {
  projectId: "",
  envId: "",
};

const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    color: "white", // Set placeholder color to white
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918",
    border: "none",
    color: "white",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918", // Set background color of the options parent
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4a4a4a" : "#090918",
    color: "white", // Set text color
    ":hover": {
      backgroundColor: "#4a4a4a", // Change background color on hover
    },
  }),
};

const IntegrationDetails = () => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  const { project, activeEnv } = useProjectDetails(projectId || "", envId || "");
  upstashEnum.projectId = projectId || upstashEnum.projectId;
  upstashEnum.envId = envId || upstashEnum.envId;
  const [loading, setLoading] = useState<boolean>(false);
  const [databasesLoading, setDatabasesLoading] = useState<boolean>(false);
  const [projectIntegrations, setProjectIntegrations] = useState<any>([]);
  const [upstashRedisIntegration, setUpstashRedisIntegration] = useState<any>([]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [selectedDatabase, setSelectedDatabase] = useState<any>({});
  const [upstashModal, setUpstashModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [disconnectModal, setDisconnectModal] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [databases, setDatabases] = useState<any>([]);
  const [editDatabase, setEditDatabase] = useState<boolean>(false);
  const [editDatabaseLoading, setEditDatabaseLoading] = useState<boolean>(false);
  const isInstalled = projectIntegrations.includes(upstashRedisIntegration?.name) ? true : false;
  const [integrationEnv, setIntegrationEnv] = useState<any>([]);
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const navigate = useNavigate();

  if (currentCollaboratorDetails.role === "collaborator" && activeEnv.name === "prod") {
    navigate(`/project/${projectId}/${envId}/integrations`);
  }

  const checkIfUserIsConnected = async () => {
    const res = await checkIntegrationUser("upstash");
    if (res?.data?.connection_status) {
      setIsConnected(true);
      if (activeStep < 2) {
        setActiveStep(2);
      }
    } else {
      setSelectedDatabase({});
      setActiveStep(1);
      setIsConnected(false);
    }

    // Check if the integration is installed and if it's connected, set the active step to 3.
    if (isConnected && isInstalled) {
      setActiveStep(3);
    }
  };

  useEffect(() => {
    checkIfUserIsConnected();
  }, [isConnected, isInstalled]);

  // TODO: fix this
  // Run the function every 1 seconds
  useEffect(() => {
    if (!isConnected) {
      const intervalId = setInterval(checkIfUserIsConnected, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [activeStep, isConnected]);

  // Fetch upstash-redis integration details and fetch all active integrations for this project
  useEffect(() => {
    fetchUpstashRedisIntegration();
    fetchActiveIntegrations();
  }, []);

  // Fetch upstash-redis databases
  useEffect(() => {
    fetchDatabases();
  }, [upstashModal, activeStep]);

  useEffect(() => {
    fetchIntegrationEnvs();
  }, [isInstalled]);

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  const fetchIntegrationEnvs = async () => {
    const res: any = await getIntegrationEnvironmentVariablesList(projectId || "", envId || "", "upstash-redis");
    if (res?.status === 200) {
      setIntegrationEnv(res.data.environmentVariables);
    }
  };

  const fetchActiveIntegrations = async () => {
    const res: any = await getProjectIntegrations(projectId || "", envId || "");

    if (res?.status === 200) {
      setProjectIntegrations(res?.data?.integrations);
    }
  };

  const fetchDatabases = async () => {
    setDatabasesLoading(true);
    const res: any = await getUpstashDatabases();
    if (res?.status === 200) {
      setDatabases([...(res?.data?.database || [])]);
    }
    setDatabasesLoading(false);
  };

  const fetchUpstashRedisIntegration = async () => {
    setLoading(true);
    const res: any = await getAllIntegrations();
    if (res?.status === 200) {
      setUpstashRedisIntegration(
        res?.data?.integrations.filter((integration: any) => integration.name === "UPSTASH-REDIS")[0],
      );
    }
    setLoading(false);
  };

  const handleDatabaseSelect = (e: any) => {
    const { addDatabase } = e;

    if (addDatabase) {
      setActiveStep(2);
      setUpstashModal(true);
      setSelectedDatabase({});
      return;
    }

    setSelectedDatabase(e);
  };

  const handleDisconnect = async () => {
    setLoading(true);
    setActiveStep(1);
    setIsConnected(false);
    setEditDatabase(false);
    await disconnectIntegration("upstash");

    if (isInstalled) {
      await deactivateIntegration(projectId ?? "", envId ?? "", "UPSTASH-REDIS");
    }

    await fetchActiveIntegrations();
    setLoading(false);
  };

  const toggleIntegration = async () => {
    // Delete case
    if (isInstalled) {
      setDeleteModal(true);
      return;
    }

    // Install case
    setLoading(true);
    await addProjectIntegration(upstashEnum.projectId, upstashEnum.envId, {
      integrationName: "UPSTASH-REDIS",
      envVars: [selectedDatabase?.value?.database_id],
    });
    toast.success("Upstash Integration Added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setLoading(false);

    await fetchActiveIntegrations();
  };

  const handleDatabaseSelectDefault = (database: any) => {
    setSelectedDatabase({
      label: database.name,
      value: database,
    });
  };

  const handleDatabaseEdit = () => {
    setActiveStep(2);
    setEditDatabase(true);
    setSelectedDatabase({});
  };

  const deleteIntegrationModalClose = async () => {
    setDeleteModal(false);
    await fetchActiveIntegrations();
  };

  // Content for each step
  const steps = [
    {
      title: "Connect to Upstash Account",
      content: () => {
        return (
          <>
            {isConnected ? (
              <>
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"12px"} fill={"#28A745"}>
                    <path d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z" />
                  </svg>
                  <p className="mb-0 mx-1">Connected successfully</p>
                </div>
                <Button
                  disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                  variant="outline-danger"
                  onClick={() => setDisconnectModal(true)}
                  className="my-3"
                  size="sm"
                >
                  Disconnect
                </Button>
              </>
            ) : (
              <Button
                disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                onClick={() => connectExternalOAuth(UpstashOauthConnectionUrl)}
                className="mb-3"
                size="sm"
              >
                Connect
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "Select your Upstash Redis database",
      content: () => {
        return (
          <>
            {activeStep > 1 && (
              <>
                {isInstalled && activeStep > 2 && !editDatabase ? (
                  <>
                    <div className="d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"12px"} fill={"#28A745"}>
                        <path d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z" />
                      </svg>
                      <p className="mb-0 mx-1">Your Database is successfully selected</p>
                    </div>
                    <Button onClick={() => handleDatabaseEdit()} className="my-3" size="sm">
                      Edit
                    </Button>
                  </>
                ) : (
                  <>
                    <p className="mb-1">Databases</p>
                    <Select
                      styles={darkMode ? customStyles : undefined}
                      key={selectedDatabase?.label}
                      isDisabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                      value={
                        selectedDatabase.label && {
                          label: selectedDatabase.label,
                          value: selectedDatabase.value,
                        }
                      }
                      options={[
                        // Default value to add new database
                        {
                          label: "+ Add New Database",
                          value: "addNewDatabase",
                          addDatabase: true,
                        },
                        // List stored databases
                        ...databases.map((database: any) => ({
                          label: database.name,
                          value: database,
                          isDisabled: database?.isDisabled,
                        })),
                        // Show loading spinner if we are sending fetch databases
                        ...(databasesLoading
                          ? [
                              {
                                label: <Spinner variant="primary" animation="border" size="sm" />,
                                value: "loading",
                                isDisabled: true,
                              },
                            ]
                          : []),
                      ]}
                      onChange={handleDatabaseSelect}
                    />
                    <Button
                      onClick={async () => {
                        // handle edit database req
                        if (editDatabase) {
                          setEditDatabaseLoading(true);
                          await addProjectIntegration(upstashEnum.projectId, upstashEnum.envId, {
                            integrationName: "UPSTASH-REDIS",
                            envVars: [selectedDatabase?.value?.database_id],
                          });
                          setEditDatabaseLoading(false);
                        }

                        setActiveStep(3);
                        setEditDatabase(false);
                      }}
                      disabled={editDatabaseLoading || !selectedDatabase.value ? true : false}
                      className="my-3"
                      size="sm"
                    >
                      {editDatabase ? "Save" : "Continue"}
                      {editDatabaseLoading && <Spinner className="ms-2" animation="border" size="sm" />}
                    </Button>

                    {editDatabase && (
                      <Button
                        onClick={() => {
                          setActiveStep(3);
                          setEditDatabase(false);
                        }}
                        className="my-3 mx-2"
                        size="sm"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    )}
                  </>
                )}

                <UpstashDatabase
                  onAddDBModalClose={() => setUpstashModal(false)}
                  handleDatabaseSelectDefault={handleDatabaseSelectDefault}
                  open={upstashModal}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Secrets",
      content: () => {
        return (
          <>
            {activeStep > 2 && (
              <>
                <p className="text-muted">
                  <span style={{ fontWeight: "500" }}>
                    {isInstalled && integrationEnv !== null
                      ? "The secrets below are set as environment variables in this project:"
                      : "The secrets below will be set as environment variables in this project:"}
                  </span>
                </p>
                <Row>
                  <p className="mb-1">UPSTASH_REDIS_URL, UPSTASH_REDIS_REST_URL, UPSTASH_REDIS_REST_TOKEN</p>
                </Row>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer />

      <DeactivateIntegrationModal
        integrationName="Upstash"
        show={disconnectModal}
        onDeleteIntegrationClose={() => {
          setDisconnectModal(false);
        }}
        handleDisconnect={handleDisconnect}
      />

      <DeleteIntegrationModal
        projectId={projectId}
        envId={envId}
        show={deleteModal}
        integrationName={upstashRedisIntegration?.name}
        setActiveStep={() => setActiveStep(2)}
        resetSelectedDatabase={() => setSelectedDatabase({})}
        onDeleteIntegrationClose={() => deleteIntegrationModalClose()}
      />

      {/* Integrations Variables Card Content */}
      <Row className="mt-3">
        <Col sm={12}>
          {currentCollaboratorDetails.role === "collaborator" ? (
            <Alert variant="warning text-muted">You are unable to edit integrations as a collaborator</Alert>
          ) : (
            ""
          )}
          <Card style={{ padding: "0 0 59px 14px", minHeight: "75vh" }}>
            <Card.Body className="pt-0">
              {loading ? (
                <Row className="my-5">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner variant="primary" animation="border" />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <h4 className="card-header card-title px-0 pb-0">{`${
                      isInstalled ? "MANAGE" : "INSTALL"
                    } ${upstashRedisIntegration?.name} INTEGRATION`}</h4>
                    <p className="text-muted my-1" style={{ fontSize: "12px" }}>
                      {upstashRedisIntegration?.description}
                    </p>

                    <a
                      href={"https://console.upstash.com/"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary text-decoration-underline"
                    >
                      Upstash Console
                    </a>

                    {steps.map((step, index) => (
                      <div key={step.title} className="d-flex align-items-center pos-relative">
                        <div>
                          <div className="d-flex align-items-center">
                            <div
                              className={`step-number my-2 ${
                                activeStep > index + 1 || activeStep === index + 1 ? "step-number-success" : ""
                              }`}
                            >
                              {index + 1}
                            </div>
                            <h6 className="mb-0 mx-3">{step.title}</h6>
                          </div>

                          <div className="w-100 mx-5">{step.content()}</div>
                        </div>

                        {/* Connectors */}
                        {index < steps.length - 1 && (
                          <div className="connector ">
                            <div className={`line ${activeStep > index + 1 ? "line-success" : ""}`} />
                          </div>
                        )}
                      </div>
                    ))}
                    {activeStep === 3 && (
                      <Button
                        className="mx-5"
                        size="sm"
                        variant={isInstalled ? "outline-danger" : "primary"}
                        onClick={() => toggleIntegration()}
                        disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                      >
                        {isInstalled ? "Delete" : "Save"}
                      </Button>
                    )}
                  </Col>

                  <Col md={7} sm={12} className="m-3">
                    {/* Code snippet for connecting to db component */}
                    <DatabaseConnect
                      connectionType={"database"}
                      secondaryText={"Use this code after you complete all the steps."}
                      integrationEnv={integrationEnv}
                      options={getCodeSnippetsOptions("upstashRedis")}
                      integrationName="upstash-redis"
                    />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default IntegrationDetails;
