import { useState, useEffect } from "react";
import { Accordion, Form, Button, Spinner } from "react-bootstrap";
import { updateEmailTemplates, getAuthEmailTemplate } from "../../network/ApiAxios";
import { EmailTemplatesProps } from "../../models/AuthModels";
import { sendToast } from "../Integrations/utils";

const EmailTemplates: React.FC<EmailTemplatesProps> = ({ envId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [verificationLoading, setVerificationLoading] = useState<boolean>(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState<boolean>(false);
  const [authData, setAuthData] = useState<any>([]);
  const [initialAuthData, setInitialAuthData] = useState<any>([]);

  const darkMode = localStorage.getItem("darkMode") === "true";

  const fetchAuthTemplates = async () => {
    setLoading(true);
    const res: any = await getAuthEmailTemplate(envId);
    if (res.status === 200) {
      let templates = res.data.templates;
      setInitialAuthData(res?.data?.templates);
      if (templates.length === 0) {
        templates = [
          { type: "VERIFICATION", template: {}, variables: [] },
          { type: "PASS_RESET", template: {}, variables: [] },
        ];
      } else if (templates.length === 1) {
        const singleTemplateType = templates[0].type;
        if (singleTemplateType === "PASS_RESET") {
          templates.unshift({ type: "VERIFICATION", template: {}, variables: [] });
        } else if (singleTemplateType !== "VERIFICATION") {
          templates = [{ type: "VERIFICATION", template: {}, variables: [] }, templates[0]];
        }
      } else {
        const verificationIndex = templates.findIndex((template: any) => template.type === "VERIFICATION");
        if (verificationIndex !== 0) {
          const verificationTemplate = templates.splice(verificationIndex, 1)[0];
          templates.unshift(verificationTemplate);
        }
      }

      setAuthData(templates);
    } else {
      sendToast(res.response.data.error.message, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAuthTemplates();
  }, []);

  const handleVerificationSave = async () => {
    setVerificationLoading(true);
    const res: any = await updateEmailTemplates(envId, {
      templates: [authData[0]], // index 0 is for verification template
    });

    setInitialAuthData((prevState: any) => [res?.data?.templates[0], prevState[1]]);

    if (res.status !== 200) {
      sendToast(res.response.data.error.message, "error");
    }
    setVerificationLoading(false);
  };

  const handlePasswordResetSave = async () => {
    setResetPasswordLoading(true);
    const res: any = await updateEmailTemplates(envId, {
      templates: [authData[1]], // index 1 is for reset pass template
    });

    setInitialAuthData((prevState: any) => [prevState[0], res?.data?.templates[1]]);

    if (res.status !== 200) {
      sendToast(res.response.data.error.message, "error");
    }
    setResetPasswordLoading(false);
  };

  const useSetAuthData = (authData: any, setAuthData: any) => {
    const setAuthTemplate = (index: number, newValue: any) => {
      setAuthData((prevData: any) => {
        const updatedAuthData = [...prevData]; // Create a copy of the array
        if (index >= 0 && index < updatedAuthData.length) {
          // Check if index is valid
          updatedAuthData[index] = {
            // Update the object at the specified index
            ...updatedAuthData[index], // Keep other properties unchanged
            template: {
              ...updatedAuthData[index].template, // Keep other template properties unchanged
              ...newValue, // Update the template properties with new values
            },
          };
        }
        return updatedAuthData; // Return the updated array
      });
    };

    return setAuthTemplate;
  };
  const setAuthTemplate = useSetAuthData(authData, setAuthData);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center p-3">
          <Spinner variant="primary" animation="border" />
        </div>
      ) : (
        <>
          <p className="fw-medium text-muted mt-3">Configure the account management emails that will be sent.</p>
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Verification</Accordion.Header>
              <Accordion.Body>
                <p className="fw-medium text-muted">This email is sent to verify the user's email address.</p>
                <Form.Label className={`${darkMode ? "text-white" : "text-black"} mb-2 mt-3`}>Redirect Url</Form.Label>
                <Form.Control
                  onChange={(e) => setAuthTemplate(0, { [e.target.name]: e.target.value })}
                  value={authData[0]?.template?.redirectUrl}
                  name="redirectUrl"
                  placeholder="Redirect Url"
                />

                <Form.Label className={`mb-2 mt-3 ${darkMode ? "text-white" : "text-black"}`}>
                  Add the following placeholders to enable dynamic content for your template.{" "}
                  <a
                    href={`${process.env.REACT_APP_GENEZIO_DOCUMENTATION}features/authentication#edit-email-templates`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-underline text-black"
                  >
                    Learn more
                  </a>
                </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    className="text-center"
                    onClick={(e: any) => {
                      e.target.select();
                      document.execCommand("copy");
                      sendToast("Copied to clipboard!", "success");
                    }}
                    placeholder=""
                    value={"{{redirectUrl}}"}
                    readOnly
                    style={{
                      cursor: "pointer",
                      maxWidth: "120px",
                      fontSize: "13px",
                      color: "#999",
                      fontWeight: "500",
                      padding: "10px 5px 10px 5px",
                    }}
                  />
                  <Form.Control
                    className="mx-2 text-center"
                    onClick={(e: any) => {
                      e.target.select();
                      document.execCommand("copy");
                      sendToast("Copied to clipboard!", "success");
                    }}
                    placeholder=""
                    value={"{{name}}"}
                    readOnly
                    style={{
                      cursor: "pointer",
                      maxWidth: "75px",
                      fontSize: "13px",
                      color: "#999",
                      fontWeight: "500",
                      padding: "10px 5px 10px 5px",
                    }}
                  />
                </div>
                <Form.Label className={`${darkMode ? "text-white" : "text-black"} mb-2 mt-3`}>Subject</Form.Label>
                <Form.Control
                  onChange={(e) => setAuthTemplate(0, { [e.target.name]: e.target.value })}
                  value={authData[0]?.template?.subject}
                  name="subject"
                  placeholder="Subject"
                />
                <Form.Group className="mb-3">
                  <Form.Label className={`${darkMode ? "text-white" : "text-black"} mt-3 mb-2`}>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="Message"
                    rows={3}
                    onChange={(e) => setAuthTemplate(0, { [e.target.name]: e.target.value })}
                    value={authData[0]?.template?.message}
                  />
                </Form.Group>
                <div className="d-flex justify-content-end pt-2">
                  <Button
                    onClick={() => {
                      setAuthData((prevValue: any) => [initialAuthData[0], prevValue[1]]);
                    }}
                    className="btn border mx-2 px-2"
                    size="sm"
                    variant=""
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={verificationLoading}
                    onClick={() => handleVerificationSave()}
                    size="sm"
                    variant="primary"
                    className="px-3"
                  >
                    Save {verificationLoading && <Spinner animation="border" size="sm" className="mx-1" />}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <hr style={{ border: "1px solid rgba(242, 242, 242, 1)" }} />

            <Accordion.Item eventKey="1">
              <Accordion.Header>Reset Password</Accordion.Header>
              <Accordion.Body>
                <p className="fw-medium text-muted">This email is sent when the user requests a reset password.</p>

                {/* <Form.Label className="text-black mb-2">Sender name</Form.Label>
                <Form.Control
                  onChange={(e) => setAuthTemplate(1, { [e.target.name]: e.target.value })}
                  value={authData[1]?.template?.senderName}
                  placeholder="Sender name"
                  name="senderName"
                /> */}

                {/* <Form.Label className="text-black mb-2 mt-3">Sender email</Form.Label>
                <Form.Control
                  onChange={(e) => setAuthTemplate(1, { [e.target.name]: e.target.value })}
                  value={authData[1]?.template?.senderEmail}
                  placeholder="Sender email"
                  name="senderEmail"
                /> */}

                <Form.Label className={`${darkMode ? "text-white" : "text-black"} mb-2 mt-3`}>Redirect Url</Form.Label>
                <Form.Control
                  onChange={(e) => setAuthTemplate(1, { [e.target.name]: e.target.value })}
                  value={authData[1]?.template?.redirectUrl}
                  name="redirectUrl"
                  placeholder="Redirect Url"
                />

                <Form.Label className={`mb-2 mt-3 ${darkMode ? "text-white" : "text-black"}`}>
                  Add the following placeholders to enable dynamic content for your template.{" "}
                  <a
                    href={`${process.env.REACT_APP_GENEZIO_DOCUMENTATION}features/authentication#edit-email-templates`}
                    rel="noreferrer"
                    target="_blank"
                    className="text-decoration-underline text-black"
                  >
                    Learn more
                  </a>
                </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    className="text-center"
                    onClick={(e: any) => {
                      e.target.select();
                      document.execCommand("copy");
                      sendToast("Copied to clipboard!", "success");
                    }}
                    placeholder=""
                    value={"{{redirectUrl}}"}
                    readOnly
                    style={{
                      cursor: "pointer",
                      maxWidth: "120px",
                      fontSize: "13px",
                      color: "#999",
                      fontWeight: "500",
                      padding: "10px 5px 10px 5px",
                    }}
                  />
                  <Form.Control
                    className="mx-2 text-center"
                    onClick={(e: any) => {
                      e.target.select();
                      document.execCommand("copy");
                      sendToast("Copied to clipboard!", "success");
                    }}
                    placeholder=""
                    value={"{{name}}"}
                    readOnly
                    style={{
                      cursor: "pointer",
                      maxWidth: "75px",
                      fontSize: "13px",
                      color: "#999",
                      fontWeight: "500",
                      padding: "10px 5px 10px 5px",
                    }}
                  />
                </div>

                <Form.Label className={`${darkMode ? "text-white" : "text-black"} mb-2 mt-3`}>Subject</Form.Label>
                <Form.Control
                  onChange={(e) => setAuthTemplate(1, { [e.target.name]: e.target.value })}
                  value={authData[1]?.template?.subject}
                  name="subject"
                  placeholder="Subject"
                />

                <Form.Group className="mb-3">
                  <Form.Label className={`${darkMode ? "text-white" : "text-black"} mt-3 mb-2`}>Message</Form.Label>
                  <Form.Control
                    onChange={(e) => setAuthTemplate(1, { [e.target.name]: e.target.value })}
                    value={authData[1]?.template?.message}
                    name="message"
                    placeholder="Message"
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>

                <div className="d-flex justify-content-end pt-2">
                  <Button
                    onClick={() => setAuthData((prevValue: any) => [prevValue[0], initialAuthData[1]])}
                    className="btn border mx-2 px-2"
                    size="sm"
                    variant=""
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={resetPasswordLoading}
                    onClick={() => handlePasswordResetSave()}
                    size="sm"
                    variant="primary"
                    className="px-3"
                  >
                    Save {resetPasswordLoading && <Spinner animation="border" size="sm" className="mx-1" />}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <hr style={{ border: "1px solid rgba(242, 242, 242, 1)" }} />
          </Accordion>
        </>
      )}
    </div>
  );
};

export default EmailTemplates;
