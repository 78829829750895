import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Card, Button, Spinner, Modal, Alert } from "react-bootstrap";
import sdk, { VM } from "@stackblitz/sdk";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  commitUsingBuildMachine,
  deployUsingBuildMachine,
  getFrontendByProjectId,
  getProjectById,
} from "../network/ApiAxios";
import { useParams, useNavigate } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { copyToClipboard, getEmbedProject, uploadProjectFilesToS3 } from "../utils";
import { ReactComponent as GenezioAIStarIcon } from "../assets/img/svgs/genezio_ai_star.svg";
import { getLinkedRepositories, checkIntegrationUser } from "../network/ApiAxios";
import { sendToast } from "../views/Integrations/utils";
import { AppContext } from "../shade/layouts/App";
import { Input } from "@material-ui/core";

const GENEZIOAI_IFRAME = "genezioai-iframe";

const initialFilesToOpen = ["README.md", "index.mjs"];
const ignoreFilesFromInsights = [
  "node_modules",
  "package-lock.json",
  "yarn.lock",
  ".gitignore",
  "LICENSE",
  ".env",
  ".env.prod",
  ".env.dev",
];
const fileNameToNotShowInEditor = ["package-lock.json", "yarn.lock", "pnpm-lock.yaml"];

export interface SourceCodeFile {
  filename: string;
  content: string;
}

export enum ProjectType {
  NEXTJS = "nextjs",
  FUNCTION = "function",
  EXPRESS = "express",
  TYPESAFE = "typesafe",
  VANILLA = "vanilla",
}

const GENEZIO_AI_NOT_SUPPORTED_TECHNOLOGIES = [ProjectType.NEXTJS, ProjectType.TYPESAFE, ProjectType.VANILLA];

export interface SourceCodeFile {
  filename: string;
  content: string;
}

export interface Insights {
  projectId: string;
  projectName: string;
  environmentId: string;
  environmentName: string;
  userId: string;
  userEmail: string;
  codeFromEditor?: SourceCodeFile[];
}

export function EditCode() {
  const enabledOnDevFlag = process.env.REACT_APP_GITHUB_APP_NAME === "genezio-dev";

  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const params = new URLSearchParams(window.location.search);
  const jobID = params.get("jobID");

  const navigate = useNavigate();

  const [commitMessage, setCommitMessage] = useState<string>("");

  const [aiInsights, setAiInsights] = useState("");
  const [error, setError] = useState("");
  const [project, setProject] = useState<any>({});
  const [activeEnv, setActiveEnv] = useState<any>({});
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [vm, setVm] = useState<VM>();

  const [isDeploying, setIsDeploying] = React.useState<boolean>(false);

  const [showModalBuild, setShowModalBuild] = useState(false);
  const [showModalCommit, setShowModalCommit] = useState(false);
  const [showModalClone, setShowModalClone] = useState(false);
  const [showModalURLs, setShowModalURLs] = useState(false);
  const [showModalLeave, setShowModalLeave] = useState(false);
  const [discardChangesLink, setDiscardChangesLink] = useState<string>("");
  const [linkedRepos, setLinkedRepos] = useState<any>([]);
  const [isConnectedToGithub, setIsConnectedToGithub] = useState<boolean>(false);

  const [deployButtonText, setDeployButtonText] = useState<string>("Deploy");

  const checkIfIsConnectedToGithub = async () => {
    const res: any = await checkIntegrationUser("github");
    if (res?.status === 200 && res?.data?.connection_status) {
      setIsConnectedToGithub(true);
    }
  };

  const handleCloseModalClone = () => setShowModalClone(false);
  const handleShowModalClone = () => setShowModalClone(true);
  const handleCloseModalLeave = () => {
    if (discardChangesLink) {
      window.location.href = discardChangesLink;
    }
    setShowModalLeave(false);
  };

  const handleCloseModalURLs = (openApp: boolean) => {
    setShowModalURLs(false);
    if (openApp == true) {
      // open the app URL in a new tab
      if (frontendsList?.length > 0) {
        window.open(`https://${frontendsList[0].fullDomain}`, "_blank");
      } else if (activeEnv?.functions?.length > 0) {
        window.open(activeEnv.functions[0].cloudUrl, "_blank");
      }
    }
  };
  const handleShowModalURLs = (fromButton: boolean) => {
    if (fromButton) {
      haveShownURLs.current = true;
    }
    setShowModalURLs(true);
    setTimeout(() => {
      haveShownURLs.current = true;
    }, 10000);
  };

  const handleShowModalLeave = () => setShowModalLeave(true);

  const handleShowModalBuild = () => setShowModalBuild(true);
  const handleCloseModalBuild = () => setShowModalBuild(false);
  const handleShowModalCommit = () => setShowModalCommit(true);
  const handleCloseModalCommit = () => setShowModalCommit(false);

  const haveShownURLs = useRef<boolean>(false);

  const [buttonCopyState, setButtonCopyState] = useState<string>("");

  const [showNotFoundModal, setShowNotFoundModal] = useState(false);

  const [frontendsList, setFrontendsList] = useState<any>([]);

  const [cantEditCode, setCantEditCode] = useState<boolean>(false);

  const [vmLoading, setVmLoading] = useState<boolean>(true);

  const [isZipDownloading, setIsZipDownloading] = useState<boolean>(false);

  const [files, setFiles] = React.useState<{ [key: string]: string }>();
  const [filesToOpen, setFilesToOpen] = React.useState<string[]>(initialFilesToOpen);

  const [isGenezioAi, setIsGenezioAi] = useState<boolean>(false);

  const [binaryFiles, setBinaryFiles] = React.useState<{ [key: string]: string }>();
  const [isBase64Encoded, setIsBase64Encoded] = React.useState<{ [key: string]: boolean }>();

  const { projectCodeArchive, projectKey, setProjectKey } = useContext<any>(AppContext);

  useEffect(() => {
    runAsyncGetProjectInfo(true);
  }, []);

  useEffect(() => {
    checkIfIsConnectedToGithub();
  }, []);

  useEffect(() => {
    fetchLinkedRepos();
  }, []);

  const runAsyncGetProjectInfo = async (firstTime = false, afterGithubCommit = false) => {
    const res: any = await getProjectById(projectId ?? "");
    let localActiveEnv: any = {};
    let localProject: any = {};
    let localFrontendsList: any = [];
    let stack: string | undefined = undefined;
    if (res.data && res.data.status === "ok") {
      stack = res.data.project.stack;
      const resFrontend: any = await getFrontendByProjectId(projectId ?? "", envId ?? "");
      if (resFrontend.data && resFrontend.data.status === "ok") {
        localFrontendsList = resFrontend.data.list;
        setFrontendsList(resFrontend.data.list);
      }
      localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
      setActiveEnv(localActiveEnv);
      localProject = res.data.project;
      setProject(res.data.project);

      // if showModalURLs is a url parameter
      const params = new URLSearchParams(window.location.search);
      const shouldShowModalURLs = params.get("showModalURLs") === "true";
      if (shouldShowModalURLs && !afterGithubCommit) {
        handleShowModalURLs(false);
      }
    } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
      navigate("/dashboard");
      return;
    }

    if (firstTime) {
      createEmbedProject(localProject.name, localProject.region, localActiveEnv.name, stack);
      if (jobID) {
        setIsDeploying(true);
        const token = localStorage.getItem("apiToken") || "";
        let deployInfoId = toast.info("Project Deployment in Progress", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        let reason = "";
        const res = await new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            const jobStatusResponse = await axios.get(process.env.REACT_APP_BUILD_API_BASE_URL + "/state/" + jobID, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            const buildStatus = jobStatusResponse.data.BuildStatus;
            if (buildStatus === "FAILED") {
              clearInterval(interval);
              reject();
              return;
            }

            if (buildStatus === "SUCCEEDED") {
              clearInterval(interval);
              resolve(true);
            }

            const transitions: any[] = jobStatusResponse.data.Transitions;
            const reasonNew = transitions.find((t) => t.To === buildStatus)?.Reason;

            if (reason !== reasonNew) {
              reason = reasonNew;
              deployInfoId && toast.dismiss(deployInfoId);
              deployInfoId = toast.info(reason || "Project Deployment in Progress", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }, 1000);
        }).catch((error) => {
          console.error(error);
          deployInfoId && toast.dismiss(deployInfoId);
          toast.error("There was an error deploying the project. Please try again.", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return null;
        });

        await runAsyncGetProjectInfo();
        deployInfoId && toast.dismiss(deployInfoId);
        if (res) {
          toast.success("Project deployed successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          if (!haveShownURLs.current) {
            handleShowModalURLs(false);
          }
        }
        setIsDeploying(false);
      }
    }
  };

  React.useEffect(() => {
    document.title = "Genezio | Project Edit Code";
  }, []);

  useEffect(() => {
    if (
      project &&
      project.stack &&
      GENEZIO_AI_NOT_SUPPORTED_TECHNOLOGIES.some((technology) => project.stack.includes(technology))
    ) {
      setIsGenezioAi(false);
    } else {
      setIsGenezioAi(true);
    }
  }, [activeEnv, project]);

  const toggleIframeVisibility = () => {
    setIsIframeVisible(!isIframeVisible);
  };

  const fetchToken = async () => {
    return localStorage.getItem("apiToken");
  };

  const fetchLinkedRepos = async () => {
    const res: any = await getLinkedRepositories(projectId);

    if (res.status === 200 && res.data.status === "ok") {
      setLinkedRepos(res.data.repositories);
      if (res.data.repositories.length > 0) {
        setDeployButtonText("Commit & Deploy");
      }
      return;
    }

    if (res?.response?.data?.error.code !== 3) {
      sendToast(res?.response?.data?.error?.message || "Unable to fetch linked repositories", "error");
    }
  };

  async function createEmbedProject(projectName: string, region: string, stage: string, stack: string | undefined) {
    let response: any;

    if (projectKey !== `${projectId}_${envId}`) {
      response = await getEmbedProject(projectName, region, stage);
    } else {
      response = await projectCodeArchive;
    }

    setProjectKey("");

    if (!response) {
      setShowNotFoundModal(true);
      // delete all #card-code
      const carCode: HTMLElement | null = document.getElementById("card-code");
      if (!carCode) return;
      carCode.style.display = "none";
      return;
    }

    setVmLoading(true);
    setCantEditCode(false);

    let template: "javascript" | "node" = "javascript";

    // if (stack && stack.indexOf("nextjs") !== -1) {
    //   template = "node";
    // }

    let filesToOpenClone = [...filesToOpen];
    if (stack && stack.includes("typesafe")) {
      filesToOpenClone = ["genezio.yaml", "server/backend.ts", "client/src/App.tsx"];
    } else if (stack && stack.includes("cron")) {
      filesToOpenClone = ["genezio.yaml", "backend.ts"];
    } else if (stack && stack.includes("html")) {
      filesToOpenClone = ["genezio.yaml", "index.html"];
    }

    const vm = await sdk
      ?.embedProject(
        "embed",
        {
          files: response.filesContent,
          template: template,
          title: "Getting Started on Genezio",
          description: "A simple project to get you started on Genezio.",
          settings: {
            compile: {
              trigger: "auto",
            },
          },
        },
        {
          openFile: filesToOpenClone.join(","),
          terminalHeight: 0,
          height: "100%",
          showSidebar: true,
          view: "editor",
        },
      )
      .catch((error) => {
        console.log(error);
        setCantEditCode(true);
        return;
      });

    if (vm) {
      setVm(vm);
    }

    setFiles(response.files);
    setBinaryFiles(response.binaryFilesContent);
    setIsBase64Encoded(response.isBase64EncodedContent);

    setVmLoading(false);
  }

  // useEffect(() => {
  async function applyVmDiffs(filesLocal: { [key: string]: string }, filesToOpenLocal: string[]) {
    if (vm) {
      if (filesLocal) {
        vm.applyFsDiff({
          create: filesLocal,
          destroy: [],
        });
        await vm.editor.openFile(filesToOpenLocal.join(","));
      }
    }
  }

  // applyVmDiffs();
  // }, [files, filesToOpen, vm]);

  window.addEventListener("message", handleEventMessage);

  async function handleDeploy() {
    if (isConnectedToGithub && linkedRepos.length > 0) {
      handleShowModalCommit();
    } else {
      deployProject();
    }
  }

  async function deployProject(name?: string, region?: string, commitMessage?: string) {
    const iframe: HTMLIFrameElement | null = document.getElementById("embed") as HTMLIFrameElement;
    if (!iframe) return;
    const vm = await sdk?.connect(iframe);
    const localFiles = (await vm.getFsSnapshot()) as any;
    if (files) {
      // include the files that are not shown in the editor
      for (const file of fileNameToNotShowInEditor) {
        if (files[file]) {
          localFiles[file] = await (files[file] as any).async("string");
        }
      }
    }

    setIsDeploying(true);
    const token = localStorage.getItem("apiToken");

    // Show initial toast with loading message
    const toastId = toast.info("Starting deployment...", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      isLoading: true,
      theme: "light",
    });

    const uploadResponse = await uploadProjectFilesToS3(
      name ?? project.name,
      region ?? project.region,
      activeEnv.name,
      localFiles,
    ).catch((e) => {
      setIsDeploying(false);
      toast.dismiss(toastId);
      setError(e.message);
      return null;
    });
    if (!uploadResponse) {
      return;
    }

    const isCommit = isConnectedToGithub && linkedRepos.length && commitMessage;

    try {
      const response = isCommit
        ? await commitUsingBuildMachine(envId!, commitMessage)
        : await deployUsingBuildMachine(envId!);
      const jobIDLocal = response.data.jobID;
      let reason = "";

      const res = await new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          const jobStatusResponse: any = await axios.get(
            process.env.REACT_APP_BUILD_API_BASE_URL + "/state/" + jobIDLocal,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          const buildStatus = jobStatusResponse.data.BuildStatus;
          if (buildStatus === "FAILED") {
            const reasonMessage = jobStatusResponse.data.Transitions.slice(-1)[0].Reason;
            const currentError = jobStatusResponse.data.Transitions?.reverse()?.find((item: any) => item.Stderr !== "");
            // TODO Remove this check to enable the AI insights on production
            if (enabledOnDevFlag) {
              setAiInsights(jobStatusResponse?.data?.Insights);
            }
            clearInterval(interval);
            setError(currentError?.Stderr);
            reject(new Error(reasonMessage || "There was an error deploying the project. Please try again."));
            return;
          }

          if (buildStatus === "SUCCEEDED") {
            clearInterval(interval);
            resolve(true);
          }

          // Update the toast message based on the current build status
          const transitions: any[] = jobStatusResponse.data.Transitions;
          const reasonNew = transitions.find((t) => t.To === buildStatus)?.Reason;

          if (reason !== reasonNew) {
            reason = reasonNew;
            // Update the single toast with new message
            toast.update(toastId, {
              render: reason || "Deployment in progress...",
              type: toast.TYPE.INFO,
              autoClose: false,
            });
          }
        }, 1000);
      });

      if (isCommit) {
        // Update the toast to success message
        toast.update(toastId, {
          render: "Changes pushed to remote repository!",
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
        });
        handleShowModalBuild();
      } else {
        await runAsyncGetProjectInfo();

        // Update the toast to success message
        toast.update(toastId, {
          render: "Project deployed successfully!",
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
        });

        if (!haveShownURLs.current) {
          handleShowModalURLs(false);
        }
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error instanceof Error ? error.message : "There was an error deploying the project. Please try again.";
      toast.dismiss(toastId);
      // Update the toast to error message
      // toast.update(toastId, {
      //   render: errorMessage,
      //   type: toast.TYPE.ERROR,
      //   isLoading: false,
      //   autoClose: false,
      //   hideProgressBar: false,
      //   closeOnClick: false,
      // });
    } finally {
      setIsDeploying(false);
      await runAsyncGetProjectInfo(false, isCommit);
    }
  }

  async function downloadProject() {
    setIsZipDownloading(true);
    const json = JSON.stringify({
      projectName: project.name,
      region: project.region,
      stage: activeEnv.name,
    });

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/core/get-project-code-url`,
      data: json,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apiToken") || ""}`,
        "Accept-Version": `genezio-webapp/${process.env.REACT_APP_VERSION}`,
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });

    // download response.data.presignedURL
    const link = document.createElement("a");
    link.href = response.data.presignedURL;
    link.setAttribute("download", `${project.name}.zip`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsZipDownloading(false);
  }

  function getCloneSnippet() {
    return `npm i genezio -g
genezio clone --name ${project.name} --region ${project.region} --stage ${activeEnv.name} ./${project.name}`;
  }

  function handleEventMessage(event: MessageEvent) {
    window.addEventListener("message", (event) => {
      if (event.data.type === "FUNCTION_AI_RESPONSE") {
        if (event.data && event.data.code) {
          const code: SourceCodeFile[] = event.data.code;
          const newFiles: { [key: string]: string } = {};
          code.forEach((file) => {
            if (file.content !== "") {
              newFiles[file.filename] = file.content;
            }
          });
          setFiles(newFiles);
          setFilesToOpen(Object.keys(newFiles));
          applyVmDiffs(newFiles, Object.keys(newFiles));
        }
      } else if (event.data.type === "REQUEST_TOKEN") {
        fetchToken().then((token) => {
          const iframe = document.getElementById(GENEZIOAI_IFRAME) as HTMLIFrameElement;
          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({ type: "AUTH_TOKEN", token }, event.origin);
          }
        });
      } else if (event.data.type === "REQUEST_INSIGHTS") {
        const iframe = document.getElementById(GENEZIOAI_IFRAME) as HTMLIFrameElement;
        let codeFromEditor: SourceCodeFile[] = [];
        if (vm) {
          vm.getFsSnapshot().then((editorFiles) => {
            if (editorFiles) {
              codeFromEditor = Object.keys(editorFiles).map((filename) => {
                if (ignoreFilesFromInsights.includes(filename.split("/").pop() || filename)) {
                  return {
                    filename: filename,
                    content: "",
                  };
                }
                return {
                  filename: filename,
                  content: editorFiles[filename],
                };
              });

              const user = localStorage.getItem("user");
              let userEmail = "";
              let userId = "";
              if (user) {
                userEmail = JSON.parse(user).email;
                userId = JSON.parse(user).id;
              }

              const insights: Insights = {
                projectId: projectId || "",
                projectName: project.name,
                environmentId: envId || "",
                environmentName: activeEnv.name,
                userId: userId,
                userEmail: userEmail,
                codeFromEditor: codeFromEditor,
              };
              if (iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage({ type: "INSIGHTS", insights }, event.origin);
              }
            }
          });
        }
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal show={showNotFoundModal} className="smaller-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>Source Code Not Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Source code for this project is not on the server.
            <br />
            Please deploy the project to get the source code here.
          </p>
          <p>Make sure you have Genezio updated by running:</p>
          <SyntaxHighlighter
            customStyle={{
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="p-4 rounded"
            language={"npm"}
            style={atomOneDark}
          >
            npm update genezio -g
          </SyntaxHighlighter>
          {/* <p>Please deploy the project to get the source code here.</p> */}
          <p className="mt-3">
            Run the following command in your terminal in the root folder of the project to deploy it:
          </p>
          <SyntaxHighlighter
            customStyle={{
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="p-4 rounded"
            language={"Text"}
            style={atomOneDark}
          >
            genezio deploy
          </SyntaxHighlighter>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => navigate(`/project/${projectId}/${envId}/`)}>Back To Project</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalURLs} onHide={() => handleCloseModalURLs(false)} className="medium-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>{haveShownURLs.current ? <>Your App URLs</> : <>Project succesfully deployed</>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeEnv?.classes?.length > 0 && (
            <div style={{ marginBottom: "1em" }}>
              <h6>Type-safe classes</h6>
              <div className="mt-2">
                Test your classes using the{" "}
                <a
                  className="p-0 mb-1"
                  href={`/test-interface/${projectId}/${envId}`}
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                  rel="noreferrer"
                >
                  genezio test interface
                </a>{" "}
              </div>
            </div>
          )}

          {!project?.stack?.includes("nextjs") && activeEnv?.functions?.length > 0 && (
            <div style={{ marginBottom: "1em" }}>
              <h6>Deployed functions</h6>
              <div className="mt-2">
                <ul className="m-0 p-0" style={{ listStyle: "none" }}>
                  {activeEnv.functions.map((func: { name: string; cloudUrl: string }) => (
                    <li className="mb-2" key={func.name}>
                      <b>{func.name}: </b>
                      <a style={{ textDecoration: "underline" }} href={func.cloudUrl} target="_blank" rel="noreferrer">
                        {func.cloudUrl}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {frontendsList?.length > 0 && (
            <div style={{ marginBottom: "1em" }}>
              <h6>Deployed frontends</h6>
              <div className="mt-2">
                <ul style={{ listStyle: "none" }} className="m-0 p-0">
                  {frontendsList.map((frontend: any) => (
                    <li className="mb-0" key={frontend.id}>
                      <a
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                        href={`https://${frontend.fullDomain}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://{frontend.fullDomain}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="text-start flex-end justify-content-end">
          <Button
            variant="secondary"
            className="border rounded py-2 px-4 mx-2 env-vars-btn"
            onClick={() => handleCloseModalURLs(false)}
          >
            Close
          </Button>
          {(frontendsList.length > 0 || activeEnv?.functions?.length > 0) && (
            <Button className="border rounded py-2 px-4 mx-2 env-vars-btn" onClick={() => handleCloseModalURLs(true)}>
              Open App
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showModalClone} onHide={handleCloseModalClone} className="medium-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>Clone your Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isConnectedToGithub || !linkedRepos?.length ? (
            <>
              <p>You can clone the project locally by running the following commands in your terminal:</p>
              <SyntaxHighlighter
                customStyle={{
                  fontSize: "15px",
                  fontWeight: "500",
                }}
                className="p-4 rounded"
                language={"Text"}
                style={atomOneDark}
              >
                {getCloneSnippet()}
              </SyntaxHighlighter>

              <div
                onClick={() => copyToClipboard(getCloneSnippet(), "codeSnippet", buttonCopyState, setButtonCopyState)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "10px",
                  width: "100%",
                  maxWidth: "20px",
                }}
              >
                {buttonCopyState === "codeSnippet" ? (
                  <i style={{ fontSize: "20px" }} className="fa fa-check text-success" aria-hidden="true" />
                ) : (
                  <img
                    src={require("./../assets/img/svgs/copy_icon.svg").default}
                    alt="Copy"
                    style={{ width: "120%", maxWidth: "120%" }}
                  />
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {linkedRepos?.length && isConnectedToGithub ? (
            <>
              <p className="my-3">You can clone the project using git clone command:</p>
              <SyntaxHighlighter
                customStyle={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "red",
                }}
                className="p-4 rounded"
                language={"markdown"}
                style={atomOneDark}
              >
                {`git clone https://github.com/${linkedRepos[0]?.owner}/${linkedRepos[0]?.name}`}
              </SyntaxHighlighter>
              <div
                onClick={() =>
                  copyToClipboard(
                    `git clone https://github.com/${linkedRepos[0]?.owner}/${linkedRepos[0]?.name}`,
                    "cloneRepoSnippet",
                    buttonCopyState,
                    setButtonCopyState,
                  )
                }
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  bottom: "100px",
                  width: "100%",
                  maxWidth: "20px",
                }}
              >
                {buttonCopyState === "cloneRepoSnippet" ? (
                  <i style={{ fontSize: "20px" }} className="fa fa-check text-success" aria-hidden="true" />
                ) : (
                  <img
                    src={require("./../assets/img/svgs/copy_icon.svg").default}
                    alt="Copy"
                    style={{ width: "120%", maxWidth: "120%" }}
                  />
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="text-start flex-end justify-content-end">
          <Button className="border rounded py-2 px-4 mx-2 env-vars-btn" onClick={() => handleCloseModalClone()}>
            Close
          </Button>
          <Button onClick={() => downloadProject()} className="btn btn-primary ms-2">
            {isZipDownloading ? "Downloading Zip..." : "Download Zip Archive"}{" "}
            {isZipDownloading && <Spinner variant="" animation="border" size="sm" className="mx-2" />}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalCommit} onHide={handleCloseModalCommit} className="medium-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>Commit and push changes to your remote repository</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {linkedRepos?.length && isConnectedToGithub ? (
            <>
              <p className="my-3">Please provide a commit message:</p>
              <Input
                className="w-100 border rounded py-2 px-4"
                placeholder="Commit message"
                onChange={(e) => {
                  setCommitMessage(e.target.value);
                }}
              />
            </>
          ) : (
            "You should't see this"
          )}
        </Modal.Body>
        <Modal.Footer className="text-start flex-end justify-content-end">
          <Button className="border rounded py-2 px-4 mx-2 env-vars-btn" onClick={() => handleCloseModalCommit()}>
            Close
          </Button>
          <Button
            onClick={() => {
              handleCloseModalCommit();
              deployProject(undefined, undefined, commitMessage);
            }}
            className="btn btn-primary ms-2"
            disabled={!commitMessage}
          >
            Commit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalBuild} onHide={handleCloseModalBuild} className="medium-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>Changes pushed to remote repository!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {linkedRepos?.length && isConnectedToGithub ? (
            <>
              <p className="my-3">
                A new deployment was triggered and should be available soon in your Deployments History page.
              </p>
            </>
          ) : (
            "You should't see this"
          )}
        </Modal.Body>
        <Modal.Footer className="text-start flex-end justify-content-end">
          <Button className="border rounded py-2 px-4 mx-2 env-vars-btn" onClick={() => handleCloseModalBuild()}>
            Close
          </Button>
          <Button
            onClick={() => {
              handleCloseModalBuild();
              navigate(`/project/${projectId}/${envId}/deployments-history`);
            }}
            className="btn btn-primary ms-2"
            disabled={!commitMessage}
          >
            See Deployments History
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalLeave} onHide={handleCloseModalLeave} className="medium-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>You could have undeployed changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            If you leave without saving, you will lose all the changes you have made. Please deploy your code to ensure{" "}
            no changes are lost.
          </p>
        </Modal.Body>
        <Modal.Footer className="text-start flex-end justify-content-end">
          <Button className="border rounded py-2 px-4 mx-2 env-vars-btn" onClick={() => handleCloseModalLeave()}>
            Discard Changes
          </Button>
          <Button onClick={() => deployProject()} className="btn btn-primary ms-2">
            {!isDeploying ? "Deploy" : "Deploying"}
            {isDeploying && <Spinner variant="" animation="border" size="sm" className="mx-2" />}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- row  --> */}
      <Row>
        <Col sm={12} className="col-12">
          <Card id="card-code">
            <div style={{ display: "flex", margin: "0px", height: "calc(100vh - 69px)" }}>
              {isIframeVisible && (
                <div style={{ width: "50%", height: "100%", position: "relative" }}>
                  <>
                    <iframe
                      id={GENEZIOAI_IFRAME}
                      src={process.env.REACT_APP_GENEZIO_AI_URL}
                      style={{ width: "100%", height: "100%", border: "none" }}
                      title="Genezio AI Micro Frontend"
                      allow="clipboard-read; clipboard-write"
                    />
                    <Button
                      onClick={toggleIframeVisibility}
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        fontSize: "10px",
                        padding: "4px",
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "1",
                        border: "1px solid gray",
                        backgroundColor: "transparent",
                        color: "gray",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "gray";
                        e.currentTarget.style.color = "white";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                        e.currentTarget.style.color = "gray";
                      }}
                    >
                      &#x2715;
                    </Button>
                  </>
                </div>
              )}
              <Card.Body className="p-0 example1-table" style={{ height: "100%" }}>
                <div
                  className={`editor-header ${cantEditCode ? "cant-edit" : ""}`}
                  style={{
                    display: vmLoading ? "none" : "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    minHeight: "42px",
                    height: "auto",
                  }}
                >
                  <div>
                    <Button
                      onClick={() => handleDeploy()}
                      disabled={isDeploying || cantEditCode || vmLoading}
                      className="first"
                    >
                      {!isDeploying ? deployButtonText : "Deploying"}
                      {isDeploying && <Spinner variant="" animation="border" size="sm" className="mx-2" />}
                    </Button>
                    <Button onClick={() => handleShowModalClone()}>Clone Locally</Button>
                    {isGenezioAi && (
                      <Button
                        onClick={() => toggleIframeVisibility()}
                        disabled={vmLoading || cantEditCode}
                        className="genezioai-button"
                      >
                        <GenezioAIStarIcon />
                        Ask Genezio AI (Beta)
                      </Button>
                    )}
                  </div>

                  <div>
                    <Button
                      disabled={isDeploying || vmLoading}
                      onClick={() => handleShowModalURLs(true)}
                      className="mx-0 mx-md-2"
                    >
                      View App URLs
                    </Button>
                  </div>
                </div>
                {aiInsights && (
                  <div
                    style={{ backgroundColor: "#2F3239" }}
                    className="d-flex w-100 align-items-start justify-content-start pb-2"
                  >
                    <svg
                      fill="#ffdf4f"
                      height="20px"
                      width="60px"
                      version="1.1"
                      id="Icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      stroke="#ffdf4f"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <path d="M12,17c0.8-4.2,1.9-5.3,6.1-6.1c0.5-0.1,0.8-0.5,0.8-1s-0.3-0.9-0.8-1C13.9,8.1,12.8,7,12,2.8C11.9,2.3,11.5,2,11,2 c-0.5,0-0.9,0.3-1,0.8C9.2,7,8.1,8.1,3.9,8.9C3.5,9,3.1,9.4,3.1,9.9s0.3,0.9,0.8,1c4.2,0.8,5.3,1.9,6.1,6.1c0.1,0.5,0.5,0.8,1,0.8 S11.9,17.4,12,17z"></path>{" "}
                          <path d="M22,24c-2.8-0.6-3.4-1.2-4-4c-0.1-0.5-0.5-0.8-1-0.8s-0.9,0.3-1,0.8c-0.6,2.8-1.2,3.4-4,4c-0.5,0.1-0.8,0.5-0.8,1 s0.3,0.9,0.8,1c2.8,0.6,3.4,1.2,4,4c0.1,0.5,0.5,0.8,1,0.8s0.9-0.3,1-0.8c0.6-2.8,1.2-3.4,4-4c0.5-0.1,0.8-0.5,0.8-1 S22.4,24.1,22,24z"></path>{" "}
                          <path d="M29.2,14c-2.2-0.4-2.7-0.9-3.1-3.1c-0.1-0.5-0.5-0.8-1-0.8c-0.5,0-0.9,0.3-1,0.8c-0.4,2.2-0.9,2.7-3.1,3.1 c-0.5,0.1-0.8,0.5-0.8,1s0.3,0.9,0.8,1c2.2,0.4,2.7,0.9,3.1,3.1c0.1,0.5,0.5,0.8,1,0.8c0.5,0,0.9-0.3,1-0.8 c0.4-2.2,0.9-2.7,3.1-3.1c0.5-0.1,0.8-0.5,0.8-1S29.7,14.1,29.2,14z"></path>{" "}
                          <path d="M5.7,22.3C5.4,22,5,21.9,4.6,22.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3C4,22.7,4,22.9,4,23s0,0.3,0.1,0.4 c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2C4.7,24,4.9,24,5,24c0.1,0,0.3,0,0.4-0.1s0.2-0.1,0.3-0.2 c0.1-0.1,0.2-0.2,0.2-0.3C6,23.3,6,23.1,6,23s0-0.3-0.1-0.4C5.9,22.5,5.8,22.4,5.7,22.3z"></path>{" "}
                          <path d="M28,7c0.3,0,0.5-0.1,0.7-0.3C28.9,6.5,29,6.3,29,6s-0.1-0.5-0.3-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.8,0 c-0.1,0-0.2,0.1-0.3,0.2C27.1,5.5,27,5.7,27,6c0,0.3,0.1,0.5,0.3,0.7C27.5,6.9,27.7,7,28,7z"></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                    <p className="ms-1 text-white mb-0">Genezio AI: {aiInsights}</p>
                  </div>
                )}
                {error !== "" && (
                  <div style={{ backgroundColor: "#2F3239" }}>
                    <div style={{ marginLeft: "50px", marginRight: "16px" }}>
                      <Alert variant="danger" className="m-0 d-flex justify-content-between">
                        {error}
                        <svg
                          style={{ cursor: "pointer" }}
                          onClick={() => setError("")}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </Alert>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    opacity: cantEditCode || vmLoading ? "0" : "1",
                    display: cantEditCode ? "none" : "block",
                    height: vmLoading ? "0" : "calc(100% - 42px)",
                  }}
                >
                  <div id="embed" style={{ height: "100%" }}></div>
                </div>
                {vmLoading && (
                  <div
                    className="w-100 d-flex justify-content-center align-items-center flex-column"
                    style={{ height: "90vh" }}
                  >
                    <p className="m-0">
                      Your app is deployed and available{" "}
                      <span
                        className="text-primary text-decoration-underline"
                        onClick={() => setShowModalURLs(true)}
                        style={{ cursor: "pointer" }}
                      >
                        here
                      </span>
                      .
                    </p>
                    <p>In just a few seconds, you'll be able to edit your code in the browser.</p>
                    <Spinner variant="primary" animation="border" />
                  </div>
                )}

                {cantEditCode && (
                  <Alert variant="info">
                    <p className="alert-heading h5">The project is too large to edit in the browser.</p>
                    <p className="h6">You can clone the project locally and edit it in your favorite code editor.</p>
                  </Alert>
                )}
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
