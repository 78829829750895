import React from "react";

const ProgressLine = ({ activeStep }: { activeStep: number }) => {
  const steps = ["Set up your project", "Deploy"];
  return (
    <>
      {steps.map((step, index) => (
        <div key={step} className="d-flex align-items-center pos-relative">
          <div>
            <div className="d-flex align-items-center">
              <div
                style={{ width: "10px", height: "10px" }}
                className={`step-number my-2 ${
                  activeStep > index + 1 || activeStep === index + 1 ? "step-number-success-purple" : ""
                }`}
              ></div>
              <h6 className="mb-0 mx-3">{step}</h6>
            </div>
          </div>

          {/* Connectors */}
          {index < steps.length - 1 && (
            <div className="connector-purple">
              <div className={`line ${activeStep > index + 1 ? "line-success-purple" : ""}`} />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ProgressLine;
