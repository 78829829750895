import { useState, useEffect } from "react";
import { getAllIntegrations } from "../network/ApiAxios";
import { IntegrationProps } from "../models/IntegrationModels";

const useCurrentIntegration = (integrationName: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [integration, setIntegration] = useState<IntegrationProps>();

  const fetchCurrentIntegration = async () => {
    setLoading(true);
    try {
      const res = await getAllIntegrations();
      if (res?.status === 200) {
        const currentIntegrationData = res?.data?.integrations.find(
          (integration: IntegrationProps) => integration.name === integrationName,
        );
        setIntegration(currentIntegrationData);
      }
    } catch (error) {
      console.error(`Error fetching ${integrationName} integration:`, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentIntegration();
  }, []);

  return { loading, integration };
};

export default useCurrentIntegration;
