import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Alert } from "react-bootstrap";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AuthCLILogin = () => {
  const [err, setError] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");
  let navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem("apiToken");

    axios
      .post(
        API_BASE_URL + "/access-tokens",
        {
          tokenName: "Auto Generated Token By Browser Login - " + new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const { status, accessToken, error } = res.data;
        if (status === "ok") {
          // get redirect url from localstorage
          const redirect_url = localStorage.getItem("redirect_url_cli_login");
          if (redirect_url) {
            // send token to redirect url
            window.location.href = redirect_url + `?token=${accessToken}`;
          } else {
            setError("Something went wrong with parsing the redirect url");
          }
        } else {
          setError(error.message);
        }
      })
      .catch(function (error) {
        // check if the error is 401 and redirect to login
        if (error.response.status === 401) {
          const redirect_url = localStorage.getItem("redirect_url_cli_login") || "";
          localStorage.clear();
          localStorage.setItem("redirect_url_cli_login", redirect_url);
          navigate("/auth/login?redirect=cli");
        }
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page bg-primary">
        <div className="page-single">
          <div className="container" style={{ marginTop: "89px" }}>
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin">
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="">
                        <div className="main-signup-header">
                          <h2 className="text-center mb-4">CLI Login in progress</h2>
                          {err && <Alert variant="danger">{err}</Alert>}
                          {successMsg && <Alert variant="success">{successMsg}</Alert>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthCLILogin;
