import { useEffect, useState } from "react";
import { Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import IntegrationBreadcrumbs from "./IntegrationBreadcrumbs";
import DeleteIntegrationModal from "../../components/AdvancedUI/Modals/DeleteIntegrationModal";
import DatabaseConnect from "./DatabaseConnect";
import useProjectDetails from "../../hooks/useProjectDetails";
import useCurrentIntegration from "../../hooks/useCurrentIntegration";
import {
  addProjectIntegration,
  getProjectIntegrations,
  getIntegrationEnvironmentVariablesList,
} from "../../network/ApiAxios";
import { getCodeSnippetsOptions } from "./utils";
import { sendToast } from "./utils";

interface emailServiceResponse {
  data: {};
  response?: {
    data: {
      error: {
        code: number;
        message: string;
      };
    };
  };
}

const EmailServiceIntegration = () => {
  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  const { project, activeEnv } = useProjectDetails(projectId || "", envId || "");
  const { loading, integration } = useCurrentIntegration("EMAIL-SERVICE");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [projectIntegrations, setProjectIntegrations] = useState<any>([]);
  const [integrationEnv, setIntegrationEnv] = useState<any>([]);

  const isInstalled = projectIntegrations.includes(integration?.name) ? true : false;

  const toggleIntegration = async () => {
    // Delete case
    if (isInstalled) {
      setDeleteModal(true);
      return;
    }

    // Install case
    setIsLoading(true);

    const res: emailServiceResponse = await addProjectIntegration(projectId || "", envId || "", {
      integrationName: "EMAIL-SERVICE",
    });

    if (res?.response?.data?.error.code && res.response.data.error.message) {
      sendToast(res?.response?.data?.error?.message, "error");
    } else {
      toast.success("Email Service Successfully Added", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    setIsLoading(false);

    await fetchActiveIntegrations();
  };

  const deleteIntegrationModalClose = async () => {
    setDeleteModal(false);
    await fetchActiveIntegrations();
  };

  const fetchActiveIntegrations = async () => {
    const res: any = await getProjectIntegrations(projectId || "", envId || "");
    if (res?.status === 200) {
      setProjectIntegrations(res?.data?.integrations);
    }
  };

  const fetchIntegrationEnvs = async () => {
    const res: any = await getIntegrationEnvironmentVariablesList(projectId || "", envId || "", "email-service");
    if (res?.status === 200) {
      setIntegrationEnv(res.data.environmentVariables);
    }
  };

  useEffect(() => {
    fetchActiveIntegrations();
  }, []);

  useEffect(() => {
    fetchIntegrationEnvs();
  }, [isInstalled]);

  useEffect(() => {
    document.title = "Genezio | Email Services";
  }, []);

  return (
    <>
      <ToastContainer />

      <DeleteIntegrationModal
        projectId={projectId}
        envId={envId}
        show={deleteModal}
        integrationName={"EMAIL-SERVICE"}
        setActiveStep={() => {}}
        resetSelectedDatabase={() => {}}
        onDeleteIntegrationClose={() => deleteIntegrationModalClose()}
      />

      {/* Integrations Variables Card Content */}
      <Row className="mt-3">
        <Col sm={12}>
          <Card style={{ padding: "0 0 59px 14px", minHeight: "75vh" }}>
            <Card.Body className="pt-0">
              {loading ? (
                <Row className="my-5">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner variant="primary" animation="border" />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm={12}>
                    <h4 className="card-header card-title px-0 pb-0">{`${
                      isInstalled ? "MANAGE" : "INSTALL"
                    } ${integration?.name} INTEGRATION`}</h4>
                    <p className="text-muted my-1" style={{ fontSize: "12px" }}>
                      {integration?.description}
                    </p>

                    <Button
                      className=""
                      variant={isInstalled ? "outline-danger" : "primary"}
                      onClick={() => toggleIntegration()}
                      disabled={isLoading}
                    >
                      {isInstalled ? "Uninstall" : "Install"} {isLoading && <Spinner animation="border" size="sm" />}
                    </Button>
                  </Col>

                  <Col sm={8} style={{ opacity: isInstalled ? 1 : 0.5 }}>
                    {/* Code snippet for connecting to db component */}
                    <DatabaseConnect
                      connectionType={"email service"}
                      secondaryText={
                        "Copy the code snippet below and paste it in your application to connect to your email service."
                      }
                      integrationEnv={integrationEnv}
                      options={getCodeSnippetsOptions("emailService")}
                      integrationName="email-service"
                    />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EmailServiceIntegration;
