import { useState } from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";

type DeleteProps = {
  databaseName: string;
  show: boolean;
  onHide: () => void;
  deleteDatabaseLinkCallback: () => void;
};

const DeleteDatabaseLink: React.FC<DeleteProps> = ({ show, onHide, databaseName, deleteDatabaseLinkCallback }) => {
  const [inputDeleteName, setInputDeleteName] = useState<string>("");

  const handleClose = () => {
    setInputDeleteName("");
    onHide();
  };

  const handleDelete = () => {
    setInputDeleteName("");
    deleteDatabaseLinkCallback();
    onHide();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "40px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Delete Database Link
        </h3>
        <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />
        <p className="mb-2">
          Are you sure you want to delete the link to database: <b>{databaseName}</b>?
        </p>
        <Alert variant="danger" style={{ color: "#842029" }}>
          This action will permanently delete your link! You can undo this action by creating a new link.
        </Alert>
        <p className="mb-2">Enter the name of the database if you want to proceed:</p>
        <Form.Control value={inputDeleteName || ""} onChange={(e) => setInputDeleteName(e.target.value)} />
        <hr style={{ borderColor: "#E5E5E5" }} />
        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            variant="danger"
            className="px-4"
            size="sm"
            disabled={inputDeleteName !== databaseName}
            onClick={() => handleDelete()}
          >
            Delete Link
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteDatabaseLink;
