// Generate default error log data when no metrics data is available
export const getNoMetricsOptions = (selectedTimeGranularity: any, selectedTimelineOption: any, chartConfig: any) => {
  const currentTime = new Date().getTime();
  let startTime, endTime;

  if (selectedTimelineOption.value === "Custom") {
    // Use provided startTime and endTime for the Custom option
    startTime = selectedTimelineOption.startTime;
    endTime = selectedTimelineOption.endTime;
  } else {
    // Determine the time range based on the predefined timeline option
    const timeInterval = selectedTimelineOption.value;
    endTime = currentTime; // Set endTime to the current time for non-Custom ranges
    startTime = currentTime - (typeof timeInterval === "number" ? timeInterval : 0);
  }

  // Adjust startTime to the nearest previous hour for hourly granularity if not Custom
  if (selectedTimeGranularity?.value === "hourly" && selectedTimelineOption.value !== "Custom") {
    const startDate = new Date(startTime);
    startDate.setMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to zero
    startTime = startDate.getTime(); // Update startTime to the adjusted value
  }

  // Generate time range with zero values
  const timeRange: any[] = [];
  let increment;

  // Determine the increment based on the selected granularity
  if (selectedTimeGranularity?.value === "daily") {
    increment = 24 * 3600 * 1000; // One day in milliseconds
  } else {
    increment = 3600 * 1000; // One hour in milliseconds
  }

  // Populate the time range array from startTime to endTime
  for (let time = startTime; time <= endTime; time += increment) {
    const date = new Date(time);
    timeRange.push(
      selectedTimeGranularity?.value === "daily"
        ? date.toISOString().split("T")[0] // YYYY-MM-DD format for daily
        : date.toISOString(), // Full ISO string for hourly
    );
  }

  // Set xAxisData and yAxisData
  const xAxisData = timeRange.map((time) => {
    return selectedTimeGranularity?.value === "daily"
      ? new Date(time).toLocaleDateString() // Format date for daily granularity
      : `${new Date(time).toLocaleDateString()} ${new Date(time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}`; // Hourly with AM/PM format
  });

  const yAxisData = new Array(timeRange.length).fill(0); // Set all Y values to zero

  return {
    title: {
      text: chartConfig?.title,
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const time = params[0].name;
        const count = params[0].value;
        return `${time}: ${count} ${chartConfig?.tooltip}`;
      },
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      name: "Time",
      axisLabel: {
        rotate: 45, // Rotate labels for better readability
        formatter: (value: any) => value, // Use the formatted value directly
      },
    },
    yAxis: {
      type: "value",
      name: chartConfig?.yAxisTitle,
      min: 0, // Start Y-axis from 0
      minInterval: 1, // Ensure tick intervals are whole numbers
      axisLabel: {
        formatter: (value: number) => Math.round(value).toString(), // Round to nearest integer
      },
    },
    series: [
      {
        name: "Error Logs",
        type: "line",
        smooth: true,
        data: yAxisData,
        lineStyle: {
          color: "#6f42c1",
        },
      },
    ],
    grid: {
      left: "7%",
      right: "4%",
      bottom: "10%", // Increased for rotated labels
      containLabel: true,
    },
  };
};

// Error chart
export const getErrorLogOption = (metricsData: any, selectedTimeGranularity: any) => {
  const errorLogs: any = metricsData?.errorLogs;

  // Group error logs by time based on the selected granularity
  const errorLogCountByTime = errorLogs.reduce((acc: any, log: any) => {
    const date = new Date(log.hour);
    let timeKey;

    if (selectedTimeGranularity?.value === "daily") {
      // Use the date in YYYY-MM-DD format for daily granularity
      timeKey = date.toISOString().split("T")[0]; // YYYY-MM-DD
    } else {
      // Use the hour in ISO format for hourly granularity
      timeKey = date.toISOString(); // YYYY-MM-DDTHH:MM:SSZ
    }

    if (!acc[timeKey]) {
      acc[timeKey] = 0; // Initialize count if not already in the accumulator
    }

    // Sum the counts of logs for this time period
    acc[timeKey] += log.logs.length; // Assuming log.logs is an array

    return acc;
  }, {});

  // Sort the keys of the accumulated error log counts using getTime()
  const sortedKeys = Object.keys(errorLogCountByTime).sort((a: string, b: string) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  // Extract the time and summed error log counts for the chart
  const xAxisData = sortedKeys.map((time) => {
    return selectedTimeGranularity?.value === "daily"
      ? new Date(time).toLocaleDateString() // Format date for daily
      : `${new Date(time).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`; // Format time for hourly
  });

  const yAxisData = sortedKeys.map((key) => errorLogCountByTime[key]);

  return {
    title: {
      text: "Error Log Count",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const time = params[0].name;
        const count = params[0].value;
        return `${time}: ${count} Errors`;
      },
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      name: "Time",
      axisLabel: {
        rotate: 45, // Rotate labels for better readability
        formatter: (value: any) => value, // Use the formatted value directly
      },
    },
    yAxis: {
      type: "value",
      name: "Errors Count",
      min: 0, // Start Y-axis from 0
      minInterval: 1, // Ensure tick intervals are whole numbers
      axisLabel: {
        formatter: (value: number) => Math.round(value).toString(), // Round to nearest integer
      },
    },
    series: [
      {
        name: "Error Logs",
        type: "line",
        smooth: true,
        data: yAxisData,
        lineStyle: {
          color: "#6f42c1",
        },
      },
    ],
    grid: {
      left: "6%",
      right: "4%",
      bottom: "10%", // Increased for rotated labels
      containLabel: true,
    },
  };
};

// Bandwidth chart
export const getBandwidthOption = (metricsData: any, selectedTimeGranularity: any) => {
  // Combine backendMetrics and frontendMetrics into one array
  const combinedMetrics = [...metricsData?.backendMetrics, ...metricsData?.frontendMetrics];

  // Group by time based on the selected granularity
  const bandwidthByTime = combinedMetrics.reduce((acc, metric) => {
    // Determine the time key based on granularity
    const date = new Date(metric.hour);
    let timeKey;

    if (selectedTimeGranularity?.value === "daily") {
      // Use the date in YYYY-MM-DD format for daily granularity
      timeKey = date.toISOString().split("T")[0]; // YYYY-MM-DD
    } else {
      // Use the hour in ISO format for hourly granularity
      timeKey = date.toISOString(); // YYYY-MM-DDTHH:MM:SSZ
    }

    if (!acc[timeKey]) {
      acc[timeKey] = 0;
    }
    acc[timeKey] += metric.bandwidth; // Summing the bandwidth in bytes
    return acc;
  }, {});

  // Extract the time and summed bandwidth for the chart
  const xAxisData = Object.keys(bandwidthByTime).map((time) => {
    return selectedTimeGranularity?.value === "daily"
      ? new Date(time).toLocaleDateString() // Format date for daily
      : new Date(time).toLocaleString(); // Format time for hourly
  });

  // Convert bandwidth from bytes to gigabytes
  const yAxisData = Object.values(bandwidthByTime).map((bytes: any) => bytes / 1024 ** 3); // Convert bytes to GB

  return {
    title: {
      text: `Bandwidth Usage`,
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const time = params[0].name;
        const bandwidthGB = params[0].value.toFixed(6); // Format to 2 decimal places
        return `${time}: ${bandwidthGB} GB`;
      },
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      name: "Time",
      axisLabel: {
        rotate: 45, // Rotate labels for better readability
        formatter: (value: any) => value, // Use the formatted value directly
      },
    },
    yAxis: {
      type: "value",
      name: "Bandwidth (GB)", // Updated to GB
    },
    series: [
      {
        name: "Bandwidth",
        type: "line",
        smooth: true,
        data: yAxisData,
        lineStyle: {
          color: "#6f42c1", // Color for bandwidth line
        },
      },
    ],
    grid: {
      left: "6%",
      right: "4%",
      bottom: "10%", // Increased for rotated labels
      containLabel: true,
    },
  };
};

// Invocations chart
export const getInvocationOption = (metricsData: any, selectedTimeGranularity: any) => {
  const backendMetrics = metricsData?.backendMetrics;

  // Group invocations by time based on the selected granularity
  const invocationsByTime = backendMetrics.reduce((acc: any, metric: any) => {
    const date = new Date(metric.hour);
    let timeKey;

    if (selectedTimeGranularity?.value === "daily") {
      // Use the date in YYYY-MM-DD format for daily granularity
      timeKey = date.toISOString().split("T")[0]; // YYYY-MM-DD
    } else {
      // Use the hour in ISO format for hourly granularity
      timeKey = date.toISOString(); // YYYY-MM-DDTHH:MM:SSZ
    }

    if (!acc[timeKey]) {
      acc[timeKey] = 0; // Initialize count if not already in the accumulator
    }

    // Sum the invocations for this time period
    acc[timeKey] += metric.invocations; // Assuming invocations is a numeric property

    return acc;
  }, {});

  // Extract the time and summed invocations for the chart
  const xAxisData = Object.keys(invocationsByTime).map((time) => {
    return selectedTimeGranularity?.value === "daily"
      ? new Date(time).toLocaleDateString() // Format date for daily
      : new Date(time).toLocaleString(); // Format time for hourly
  });

  const yAxisData = Object.values(invocationsByTime);

  return {
    title: {
      text: "Invocations",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const time = params[0].name;
        const count = params[0].value;
        return `${time}: ${count} Invocations`;
      },
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      name: "Time",
      axisLabel: {
        rotate: 45, // Rotate labels for better readability
        formatter: (value: any) => value, // Use the formatted value directly
      },
    },
    yAxis: {
      type: "value",
      name: "Invocations Count",
    },
    series: [
      {
        name: "Invocations",
        type: "line",
        smooth: true,
        data: yAxisData,
        lineStyle: {
          color: "#6f42c1", // Color for invocations
        },
      },
    ],
    grid: {
      left: "6%",
      right: "4%",
      bottom: "10%", // Increased for rotated labels
      containLabel: true,
    },
  };
};

// Backend duration
export const getDurationOption = (metricsData: any, selectedTimeGranularity: any) => {
  const backendMetrics = metricsData?.backendMetrics;

  // Group duration by time based on the selected granularity
  const durationByTime = backendMetrics.reduce((acc: any, metric: any) => {
    const date = new Date(metric.hour);
    let timeKey;

    if (selectedTimeGranularity?.value === "daily") {
      // Use the date in YYYY-MM-DD format for daily granularity
      timeKey = date.toISOString().split("T")[0]; // YYYY-MM-DD
    } else {
      // Use the hour in ISO format for hourly granularity
      timeKey = date.toISOString(); // YYYY-MM-DDTHH:MM:SSZ
    }

    if (!acc[timeKey]) {
      acc[timeKey] = 0; // Initialize duration if not already in the accumulator
    }

    // Calculate the adjusted duration based on vmSize
    let adjustedDuration = 0;
    if (metric.vmSize === 1 || metric.vmSize === 2) {
      adjustedDuration = (256 / 1024) * (metric.duration / 3600); // Convert to hours
    } else if (metric.vmSize === 3) {
      adjustedDuration = (512 / 1024) * (metric.duration / 3600); // Convert to hours
    }

    // Convert to seconds and sum the adjusted duration for this time period
    // acc[timeKey] += adjustedDuration * 3600; // Back to seconds
    acc[timeKey] += adjustedDuration; // Back to seconds

    return acc;
  }, {});

  // Sort the keys of the accumulated durations using getTime()
  const sortedKeys = Object.keys(durationByTime).sort((a: string, b: string) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  // Extract the time and summed durations for the chart
  const xAxisData = sortedKeys.map((time) => {
    return selectedTimeGranularity?.value === "daily"
      ? new Date(time).toLocaleDateString() // Format date for daily
      : `${new Date(time).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`; // Format time for hourly
  });

  const yAxisData = sortedKeys.map((time) => durationByTime[time]);

  return {
    title: {
      text: "Function Duration",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const time = params[0].name;
        const duration = params[0].value;
        return `${time}: ${duration.toFixed(6)} GB/hour`;
      },
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      name: "Time",
      axisLabel: {
        rotate: 45, // Rotate labels for better readability
        formatter: (value: any) => value, // Use the formatted value directly
      },
    },
    yAxis: {
      type: "value",
      name: "GB/hour",
      min: 0, // Start the Y-axis from 0
    },
    series: [
      {
        name: "Duration",
        type: "line",
        smooth: true,
        data: yAxisData,
        lineStyle: {
          color: "#6f42c1", // Color for duration
        },
      },
    ],
    grid: {
      left: "6%",
      right: "4%",
      bottom: "10%", // Increased for rotated labels
      containLabel: true,
    },
  };
};

// Performance chart
export const getPerformanceOption = (metricsData: any, selectedTimeGranularity: any) => {
  const backendMetrics = metricsData?.backendMetrics;

  // Group duration by time based on the selected granularity
  const durationByTime = backendMetrics.reduce((acc: any, metric: any) => {
    const date = new Date(metric.hour);
    let timeKey;

    if (selectedTimeGranularity?.value === "daily") {
      // Use the date in YYYY-MM-DD format for daily granularity
      timeKey = date.toISOString().split("T")[0]; // YYYY-MM-DD
    } else {
      // Use the hour in ISO format for hourly granularity
      timeKey = date.toISOString(); // YYYY-MM-DDTHH:MM:SSZ
    }

    // Initialize if not already in the accumulator
    if (!acc[timeKey]) {
      acc[timeKey] = {
        totalDuration: 0,
        count: 0, // Keep track of the number of entries
      };
    }

    // Calculate the adjusted duration based on vmSize
    let adjustedDuration = 0;
    if (metric.vmSize === 1 || metric.vmSize === 2) {
      adjustedDuration = (256 / 1024) * (metric.duration / 3600); // Convert to hours
    } else if (metric.vmSize === 3) {
      adjustedDuration = (512 / 1024) * (metric.duration / 3600); // Convert to hours
    }

    // Convert adjusted duration back to seconds
    // adjustedDuration *= 3600;

    // Sum the adjusted duration for this time period
    acc[timeKey].totalDuration += adjustedDuration; // Sum of adjusted durations
    acc[timeKey].count += 1; // Increment count for the number of entries

    return acc;
  }, {});

  // Sort the keys of the accumulated durations using getTime()
  const sortedKeys = Object.keys(durationByTime).sort((a: string, b: string) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  // Calculate average performance (total duration per count)
  const xAxisData = sortedKeys.map((time) => {
    return selectedTimeGranularity?.value === "daily"
      ? new Date(time).toLocaleDateString() // Format date for daily
      : `${new Date(time).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`; // Format time for hourly
  });

  const yAxisData = sortedKeys.map((time) => {
    const data = durationByTime[time];
    // Calculate the average duration for the day or hour
    return data.count > 0 ? data.totalDuration / data.count : 0; // Avoid division by zero
  });

  return {
    title: {
      text: "Performance",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const time = params[0].name;
        const averageDuration = params[0].value;
        return `${time}: ${averageDuration.toFixed(6)} GB/hour`;
      },
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      name: "Time",
      axisLabel: {
        rotate: 45, // Rotate labels for better readability
        formatter: (value: any) => value, // Use the formatted value directly
      },
    },
    yAxis: {
      type: "value",
      name: "Avg GB/hour",
      min: 0, // Start the Y-axis from 0
    },
    series: [
      {
        name: "Performance",
        type: "line",
        smooth: true,
        data: yAxisData,
        lineStyle: {
          color: "#6f42c1", // Color for performance
        },
      },
    ],
    grid: {
      left: "6%",
      right: "4%",
      bottom: "10%", // Increased for rotated labels
      containLabel: true,
    },
  };
};
