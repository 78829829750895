import { useState, useEffect } from "react";
import { ListGroup, Row, Col, Card, Container, Spinner, Alert, Button } from "react-bootstrap";
import { getDeployStatus } from "../../network/ApiAxios";
import { DeployActionsProps } from "../../models/NewProjectModels";
import { handleLinkOpen } from "./utils";

import { useParams, useNavigate } from "react-router-dom";

const ProjectDeployDetails = () => {
  const [backendActionError, setBackendActionError] = useState<boolean>(false);
  const [frontendActionError, setFrontendActionError] = useState<boolean>(false);

  const [backendDeployProgress, setBackendDeployProgress] = useState([]);
  const [frontendDeployProgress, setfrontendDeployProgress] = useState([]);

  const { projectName, region, backendName, backendOwner, frontendName, frontendOwner } = useParams();
  let navigate = useNavigate();

  const isMultiRepo = backendName && frontendName ? true : false;

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        // Backend
        if (backendName && backendOwner) {
          const backendDeployRes: any = await getDeployStatus(backendOwner, backendName);
          if (backendDeployRes?.data.status === "ok") {
            setBackendDeployProgress(backendDeployRes.data.action);
          }
        }
        // Frontend
        if (frontendName && frontendOwner) {
          const frontendRepo: any = await getDeployStatus(frontendOwner, frontendName);
          if (frontendRepo?.data.status === "ok") {
            setfrontendDeployProgress(frontendRepo.data.action);
          }
        }

        const isBackendCompleted = backendDeployProgress?.every(
          (item: any) => item.status === "completed" && item.conclusion === "success",
        );
        const isFrontendCompleted = frontendDeployProgress?.every(
          (item: any) => item.status === "completed" && item.conclusion === "success",
        );

        // Handle succes condition
        if (
          isMultiRepo
            ? backendDeployProgress?.length &&
              backendDeployProgress !== null &&
              frontendDeployProgress?.length &&
              frontendDeployProgress !== null &&
              isBackendCompleted &&
              isFrontendCompleted
            : backendDeployProgress?.length && backendDeployProgress !== null && isBackendCompleted
        ) {
          navigate(
            `/new-project/congrats/${projectName}/${region}/${backendName ? backendName : ""}/${
              backendOwner ? backendOwner : ""
            }${frontendName ? "/" + frontendName : ""}${frontendOwner ? "/" + frontendOwner : ""}`,
            {
              replace: true,
            },
          );
          clearInterval(interval); // Stop the interval when all are completed
        }
        // handle logic for errors
        if (
          !backendActionError &&
          backendDeployProgress?.length > 2 &&
          backendDeployProgress !== null &&
          backendDeployProgress.some((item: any) => item.conclusion === "failure")
        ) {
          setBackendActionError(true);
        }
        if (
          !frontendActionError &&
          frontendDeployProgress?.length > 2 &&
          frontendDeployProgress !== null &&
          frontendDeployProgress.some((item: any) => item.conclusion === "failure")
        ) {
          setFrontendActionError(true);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, [backendDeployProgress, frontendDeployProgress]);

  useEffect(() => {
    document.title = "Genezio | New Project Deploying";
  }, []);

  return (
    <Row>
      <Container>
        <h4 className="card-header card-title px-0 pb-0 my-4 bg-transparent">Deploy</h4>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {/* Backend */}
                {backendName && (
                  <>
                    <Row className="pt-4 d-flex justify-content-center">
                      <Col className="d-flex align-items-center" lg={11}>
                        <Col lg={11}>
                          {backendActionError ? (
                            <Row className="p-0">
                              <Col className="p-0" md={12}>
                                <Alert variant="danger">Error on GitHub Action</Alert>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center justify-content-center" lg={12}>
                      <Col lg="11">
                        <h5>
                          Deploying your Backend
                          <Button
                            variant="outline-primary ms-2"
                            size="sm"
                            onClick={() => handleLinkOpen(`https://github.com/${backendOwner}/${backendName}`)}
                          >
                            GitHub
                          </Button>
                        </h5>
                        <ListGroup>
                          {!backendDeployProgress?.length || backendDeployProgress === null ? (
                            <ListGroup.Item className="d-flex justify-content-between border">
                              <Row className="d-flex justify-content-between w-100 py-2">
                                <Col className="col-10">
                                  <h6 className="mb-0 mx-0">Creating the repository</h6>
                                </Col>
                                <Col className="d-flex justify-content-end col-2">
                                  <Spinner variant="primary" animation="border" size="sm" />
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ) : (
                            <>
                              <ListGroup.Item className="d-flex justify-content-between border">
                                <Row className="d-flex justify-content-between w-100 py-2">
                                  <Col className="col-10">
                                    <h6 className="mb-0 mx-0">Creating the repository</h6>
                                  </Col>
                                  <Col className="d-flex justify-content-end col-2">
                                    <img
                                      className="position-absolute"
                                      src={require("../../assets/img/svgs/check_mark.svg").default}
                                      alt="integrationStatus"
                                    />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              {backendDeployProgress?.map((action: DeployActionsProps) => (
                                <ListGroup.Item className="d-flex justify-content-between border" key={action.name}>
                                  <Row className="d-flex justify-content-between w-100 py-2">
                                    <Col className="col-10">
                                      <h6 className="mb-0 mx-0">{action.name}</h6>
                                    </Col>
                                    <Col className="d-flex justify-content-end col-2">
                                      {action.status === "completed" ? (
                                        <img
                                          className="position-absolute"
                                          src={
                                            action.conclusion === "success"
                                              ? require("../../assets/img/svgs/check_mark.svg").default
                                              : require("../../assets/img/svgs/wrong_mark.svg").default
                                          }
                                          alt="integrationStatus"
                                        />
                                      ) : action.status === "in_progress" ? (
                                        <Spinner variant="primary" animation="border" size="sm" />
                                      ) : (
                                        <div
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                            border: "2px solid #6F42C1",
                                            borderRadius: "200px",
                                          }}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              ))}
                            </>
                          )}
                        </ListGroup>
                      </Col>
                    </Row>
                  </>
                )}
                {/* Frontend */}
                {frontendName &&
                backendDeployProgress?.every(
                  (item: any) => item.status === "completed" && item.conclusion === "success",
                ) &&
                backendDeployProgress?.length &&
                backendDeployProgress !== null ? (
                  <>
                    <Row className="pt-4 d-flex justify-content-center">
                      <Col className="d-flex align-items-center" lg={11}>
                        <Col lg={11}>
                          {frontendActionError ? (
                            <Row className="p-0">
                              <Col className="p-0" md={12}>
                                <Alert variant="danger">Error on GitHub Action</Alert>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center justify-content-center" lg={12}>
                      <Col lg="11">
                        <h5>
                          Deploying your Frontend{" "}
                          <Button
                            variant="outline-primary ms-2"
                            size="sm"
                            onClick={() => handleLinkOpen(`https://github.com/${frontendOwner}/${frontendName}`)}
                          >
                            GitHub
                          </Button>
                        </h5>
                        <ListGroup>
                          {!frontendDeployProgress?.length || frontendDeployProgress === null ? (
                            <ListGroup.Item className="d-flex justify-content-between border">
                              <Row className="d-flex justify-content-between w-100 py-2">
                                <Col>
                                  <h6 className="mb-0 mx-0">Creating the repository</h6>
                                </Col>
                                <Col className="d-flex justify-content-end col-2">
                                  <Spinner variant="primary" animation="border" size="sm" />
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ) : (
                            <>
                              <ListGroup.Item className="d-flex justify-content-between border">
                                <Row className="d-flex justify-content-between w-100 py-2">
                                  <Col className="col-10">
                                    <h6 className="mb-0 mx-0">Creating the repository</h6>
                                  </Col>
                                  <Col className="d-flex justify-content-end col-2">
                                    <img
                                      className="position-absolute"
                                      src={require("../../assets/img/svgs/check_mark.svg").default}
                                      alt="integrationStatus"
                                    />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                              {frontendDeployProgress?.map((action: DeployActionsProps) => (
                                <ListGroup.Item className="d-flex justify-content-between border" key={action.name}>
                                  <Row className="d-flex justify-content-between w-100 py-2">
                                    <Col className="col-10">
                                      <h6 className="mb-0 mx-0">{action.name}</h6>
                                    </Col>
                                    <Col className="d-flex justify-content-end col-2">
                                      {action.status === "completed" ? (
                                        <img
                                          className="position-absolute"
                                          src={
                                            action.conclusion === "success"
                                              ? require("../../assets/img/svgs/check_mark.svg").default
                                              : require("../../assets/img/svgs/wrong_mark.svg").default
                                          }
                                          alt="integrationStatus"
                                        />
                                      ) : action.status === "in_progress" ? (
                                        <Spinner variant="primary" animation="border" size="sm" />
                                      ) : (
                                        <div
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                            border: "2px solid #6F42C1",
                                            borderRadius: "200px",
                                          }}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              ))}
                            </>
                          )}
                        </ListGroup>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default ProjectDeployDetails;
