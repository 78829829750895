import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { getCodeSnippets, getCodeSnippetsOptions } from "../../../views/Integrations/utils";
import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark, docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { copyToClipboard } from "../../../utils";
import { getDatabaseConnectionURL } from "../../../network/ApiAxios";

type DatabaseConnectModalProps = {
  show: boolean;
  onHide: () => void;
  databaseId?: string;
  databaseName?: string;
  databaseType?: string;
};

const DatabaseConnectModal: React.FC<DatabaseConnectModalProps> = ({
  show,
  onHide,
  databaseId,
  databaseName,
  databaseType,
}) => {
  const [activeLanguage, setActiveLanguage] = useState(getCodeSnippetsOptions("neonGenezioIntegration")[0]);
  const [buttonCopyState, setButtonCopyState] = useState<string>("");
  const [showSensitiveData, setShowSensitiveData] = useState<boolean>(false);
  const [connectionUrl, setConnectionUrl] = useState<string>("");

  useEffect(() => {
    async function getDbUrl() {
      const res = await getDatabaseConnectionURL(databaseId || "");

      setConnectionUrl(res?.data?.connectionUrl || "You don't have permission to view this URL");
    }
    getDbUrl();
  }, []);

  const darkMode = localStorage.getItem("darkMode") === "true";

  const handleClose = () => {
    onHide();
  };

  let integrationType: string;
  switch (databaseType) {
    case "postgres-neon":
      integrationType = "neonGenezioIntegration";
      break;
    case "mongo-atlas":
      integrationType = "mongoGenezioIntegration";
      break;
    default:
      integrationType = "neonGenezioIntegration";
      break;
  }

  return (
    <Modal show={show} onHide={() => handleClose()} style={{ borderRadius: "50px" }} centered>
      <Modal.Body style={{ padding: "30px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Connect to the Database {databaseName}
        </h3>
        <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />

        <div className="w-100">
          <p className="text-muted mb-3" style={{ fontWeight: "500" }}>
            Use this code after you complete all the steps.
          </p>
          <ButtonGroup className="border rounded">
            {getCodeSnippetsOptions("neonGenezioIntegration").map((option: string) => (
              <Button
                key={option}
                onClick={() => {
                  setActiveLanguage(option);
                  setShowSensitiveData(false);
                }}
                variant={activeLanguage === option ? "primary" : ""}
              >
                {option}
              </Button>
            ))}
          </ButtonGroup>

          {getCodeSnippets(integrationType, activeLanguage, [
            {
              value: connectionUrl || "",
            },
            {
              value: `process.env["${databaseName?.replaceAll("-", "_").toUpperCase()}_DATABASE_URL"]` || "",
            },
          ])?.map((snippet: any, index: any) => (
            <React.Fragment key={index}>
              <p className="text-muted mt-3 my-2" style={{ fontWeight: "500" }}>
                {index + 1}. {snippet.title}
              </p>

              <div className="position-relative">
                <SyntaxHighlighter
                  customStyle={{
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  className="p-4 rounded"
                  language={snippet.language}
                  style={darkMode ? atomOneDark : docco}
                >
                  {showSensitiveData ? snippet.copySnippet : snippet.codeSnippet}
                </SyntaxHighlighter>

                {/* Display show button only if integration is active  */}
                {snippet.revealCredentialButton && (
                  <div
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "40px",
                      top: "10px",
                      width: "100%",
                      maxWidth: "20px",
                    }}
                    onClick={() => setShowSensitiveData(!showSensitiveData)}
                  >
                    {showSensitiveData ? (
                      <i style={{ fontSize: "20px" }} className="fa fa-eye-slash" aria-hidden="true"></i>
                    ) : (
                      <i style={{ fontSize: "20px" }} className="fa fa-eye" aria-hidden="true"></i>
                    )}
                  </div>
                )}
                <div
                  onClick={() =>
                    copyToClipboard(snippet.copySnippet, "codeSnippet" + index, buttonCopyState, setButtonCopyState)
                  }
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    width: "100%",
                    maxWidth: "20px",
                  }}
                >
                  {buttonCopyState === "codeSnippet" + index ? (
                    <i style={{ fontSize: "20px" }} className="fa fa-check text-success" aria-hidden="true" />
                  ) : (
                    <img
                      src={require("./../../../assets/img/svgs/copy_icon.svg").default}
                      alt="Copy"
                      style={{ width: "120%", maxWidth: "120%" }}
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>

        <hr style={{ borderColor: "#E5E5E5" }} />
        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleClose()}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DatabaseConnectModal;
