import { Col, Row } from "react-bootstrap";
import { Provider } from "../../models/AuthModels";
import { capitalizeFirstLetter } from "../../utils";

const editIcon = (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4709 2.06118C16.5701 2.16076 16.6259 2.29563 16.6259 2.43624C16.6259 2.57685 16.5701 2.71172 16.4709 2.8113L15.3627 3.92055L13.2377 1.79555L14.3459 0.686305C14.4455 0.586711 14.5806 0.530762 14.7215 0.530762C14.8623 0.530762 14.9974 0.586711 15.0971 0.686305L16.4709 2.06012V2.06118ZM14.6115 4.67068L12.4865 2.54568L5.24769 9.78555C5.18921 9.84402 5.14519 9.91533 5.11912 9.9938L4.26381 12.5587C4.2483 12.6054 4.2461 12.6556 4.25745 12.7035C4.2688 12.7515 4.29327 12.7953 4.3281 12.8301C4.36294 12.865 4.40677 12.8894 4.45471 12.9008C4.50265 12.9121 4.5528 12.9099 4.59956 12.8944L7.16444 12.0391C7.24281 12.0134 7.31411 11.9697 7.37269 11.9116L14.6115 4.67068Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.0625 14.3437C1.0625 14.7664 1.23041 15.1717 1.5293 15.4706C1.82818 15.7695 2.23356 15.9374 2.65625 15.9374H14.3438C14.7664 15.9374 15.1718 15.7695 15.4707 15.4706C15.7696 15.1717 15.9375 14.7664 15.9375 14.3437V7.96868C15.9375 7.82778 15.8815 7.69266 15.7819 7.59303C15.6823 7.4934 15.5471 7.43743 15.4062 7.43743C15.2654 7.43743 15.1302 7.4934 15.0306 7.59303C14.931 7.69266 14.875 7.82778 14.875 7.96868V14.3437C14.875 14.4846 14.819 14.6197 14.7194 14.7193C14.6198 14.819 14.4846 14.8749 14.3438 14.8749H2.65625C2.51535 14.8749 2.38023 14.819 2.2806 14.7193C2.18097 14.6197 2.125 14.4846 2.125 14.3437V2.65618C2.125 2.51528 2.18097 2.38016 2.2806 2.28053C2.38023 2.1809 2.51535 2.12493 2.65625 2.12493H9.5625C9.7034 2.12493 9.83852 2.06896 9.93815 1.96933C10.0378 1.8697 10.0938 1.73458 10.0938 1.59368C10.0938 1.45278 10.0378 1.31766 9.93815 1.21803C9.83852 1.1184 9.7034 1.06243 9.5625 1.06243H2.65625C2.23356 1.06243 1.82818 1.23034 1.5293 1.52923C1.23041 1.82811 1.0625 2.23349 1.0625 2.65618V14.3437Z"
      fill="#333333"
    />
  </svg>
);

const MethodsTab: React.FC<{ authProviders: Provider[]; handleSelectProvider: (provider: any) => void }> = ({
  authProviders,
  handleSelectProvider,
}) => {
  return (
    <div className="px-2">
      {/* Header */}
      <Row className="mx-1 pb-2" style={{ borderBottom: "1px solid #ccc" }}>
        <Col lg={6}>
          <p style={{ fontSize: "14px" }} className="mb-0 fw-bold">
            Provider
          </p>
        </Col>
        <Col lg={3}>
          <p style={{ fontSize: "14px" }} className="mb-0 fw-bold">
            Status
          </p>
        </Col>
        <Col lg={3}>
          <p style={{ fontSize: "14px" }} className="mb-0 fw-bold">
            Edit
          </p>
        </Col>
      </Row>

      {authProviders?.map((provider: Provider) => (
        <Row key={provider.id} className="mx-1 pb-2 mt-2 pt-1">
          <Col lg={6}>
            <p className="mb-0">{capitalizeFirstLetter(provider.name)}</p>
          </Col>
          <Col lg={3}>
            <p className="mb-0 " style={{ color: provider.enabled ? "#28A745" : "#999" }}>
              {provider.enabled ? "Enabled" : "Disabled"}
            </p>
          </Col>
          <Col lg={3}>
            <p style={{ cursor: "pointer" }} onClick={() => handleSelectProvider(provider)} className="mb-0">
              {editIcon}
            </p>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default MethodsTab;
