import { OverlayTrigger, Tooltip } from "react-bootstrap";

// TODO: move this to a new file
interface InfoTooltipProps {
  children: React.ReactNode;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ children }) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 50, hide: 1000 }}
      overlay={
        <Tooltip placement="top" className="in custom-tooltip" id="tooltip-top">
          <div>{children}</div>
        </Tooltip>
      }
    >
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mx-1"
      >
        <circle cx="12" cy="12" r="10" stroke="#9393b5" stroke-width="1.5" />
        <path d="M12 17V11" stroke="#9393b5" stroke-width="1.5" stroke-linecap="round" />
        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#9393b5" />
      </svg>
    </OverlayTrigger>
  );
};

export default InfoTooltip;
