import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Col, Row, Alert } from "react-bootstrap";
import GenezioSpinner from "../../components/Utilities/Spinner/GenezioSpinner";
import { getCheckoutLink, getUserInformation } from "../../network/ApiAxios";
import { getGoogleClickId, getUTMSignupSource } from "../../utils";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const GithubRedirect = () => {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = React.useState("");

  // get code from query params
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const errorGit = urlParams.get("error");
  const collaborationInvitationId: string | undefined = urlParams.get("invitationId")?.toString();
  console.log("code", code);
  console.log("error", errorGit);

  React.useEffect(() => {
    if (errorGit) {
      navigate("/auth/login");
      return;
    }

    axios
      .post(
        API_BASE_URL + "/users/github-auth",
        { code, signupSource: getUTMSignupSource(urlParams), googleClickId: getGoogleClickId(urlParams) },
        {},
      )
      .then(async (res) => {
        const { status, token, user, error } = res.data;
        if (status === "ok") {
          localStorage.setItem("apiToken", token);
          localStorage.setItem("user", JSON.stringify(user));
          const userId: string = (await getUserInformation()).data?.user?.id || "";
          // @ts-ignore
          window.dataLayer = window.dataLayer || [];
          // @ts-ignore
          window.dataLayer.push({
            userId: userId,
            event: "userIdSet",
          });

          // check if redirect == cli from query params
          const redirect: string = urlParams.get("redirect")?.toString() || "";
          const repository: string = urlParams.get("repository")?.toString() || "";
          const s3URL: string = urlParams.get("s3url")?.toString() || "";
          if (repository || s3URL) {
            navigate(`/start/deploy?${window.location.search.substring(1)}`, { replace: true });
          } else if (redirect === "cli") {
            navigate("/clilogin", { replace: true });
          } else if (redirect === "upgrade-now-pro") {
            window.location.href = (await getCheckoutLink("Pro")).data.checkoutUrl;
          } else if (redirect === "express-getting-started") {
            navigate("/express-getting-started", { replace: true });
          } else if (redirect === "html-getting-started") {
            navigate("/html-getting-started", { replace: true });
          } else if (redirect === "angular-getting-started") {
            navigate("/angular-getting-started", { replace: true });
          } else if (redirect === "react-getting-started") {
            navigate("/react-getting-started", { replace: true });
          } else if (redirect === "fastapi-getting-started") {
            navigate("/fastapi-getting-started", { replace: true });
          } else if (redirect === "faas-getting-started") {
            navigate("/faas-getting-started", { replace: true });
          } else if (redirect === "vue-getting-started") {
            navigate("/vue-getting-started", { replace: true });
          } else if (redirect === "fastify-getting-started") {
            navigate("/fastify-getting-started", { replace: true });
          } else if (redirect === "svelte-getting-started") {
            navigate("/svelte-getting-started", { replace: true });
          } else if (redirect === "nuxt-getting-started") {
            navigate("/nuxt-getting-started", { replace: true });
          } else if (redirect === "express-fullstack-getting-started") {
            navigate("/express-fullstack-getting-started", { replace: true });
          } else if (redirect === "express-mongo-getting-started") {
            navigate("/express-mongo-getting-started", { replace: true });
          } else if (redirect === "express-typescript-getting-started") {
            navigate("/express-typescript-getting-started", { replace: true });
          } else if (redirect === "express-mongo-auth-starter") {
            navigate("/express-mongo-auth-starter", { replace: true });
          } else if (redirect === "faas-python-getting-started") {
            navigate("/faas-python-getting-started", { replace: true });
          } else if (redirect === "serverless-containers-starter") {
            navigate("/serverless-containers-starter", { replace: true });
          } else if (redirect === "django-getting-started") {
            navigate("/django-getting-started", { replace: true });
          } else if (redirect === "flask-getting-started") {
            navigate("/flask-getting-started", { replace: true });
          } else if (redirect === "express-postgresql-getting-started") {
            navigate("/express-postgresql-getting-started", { replace: true });
          } else if (
            collaborationInvitationId !== null &&
            collaborationInvitationId !== undefined &&
            collaborationInvitationId !== "" &&
            collaborationInvitationId !== "null" &&
            collaborationInvitationId !== "undefined"
          ) {
            navigate(`/collaboration/invitation/${collaborationInvitationId}`, { replace: true });
          } else {
            navigate("/dashboard", { replace: true });
          }
        } else {
          setErrorMsg(error.message);
        }
      })
      .catch(function (error) {
        console.error(error);
        setErrorMsg("There was an error logging in. Please try again.");
      });
  }, []);
  return (
    <React.Fragment>
      <div className="page bg-primary">
        <div className="page-single">
          <div className="container" style={{ marginTop: "89px" }}>
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin">
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="">
                        <div className="main-signup-header">
                          <h2 className="text-center mb-4">Github Login in progress</h2>
                          {errorMsg ? <Alert variant="danger">{errorMsg}</Alert> : null}
                          {/* <Spinner
                            variant="primary"
                            animation="border"
                            className="d-flex mx-auto my-3"
                          /> */}
                          <GenezioSpinner />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GithubRedirect;
