import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Badge, Spinner } from "react-bootstrap";
import moment from "moment";

interface DeploymentCardProps {
  projectId?: any;
  envId: any;
  deployment: any;
  disableLink?: any;
  currentDeploy?: any;
  linkedRepo?: any;
}

const DeploymentCard: React.FC<DeploymentCardProps> = ({
  projectId,
  envId,
  deployment,
  disableLink,
  currentDeploy,
  linkedRepo,
}) => {
  const duration = moment.duration(moment(deployment?.updatedAt).diff(moment(deployment?.createdAt)));
  return (
    <div key={deployment?.id}>
      <div className="border rounded p-2 mb-2 shadow-sm">
        <div>
          <Row className="w-100">
            <Col md={4} className={currentDeploy ? "d-flex flex-column" : "d-flex align-items-center"}>
              <NavLink
                style={{
                  cursor: disableLink ? "default" : "pointer",
                }}
                to={`/${projectId}/${envId}/deployments/${deployment?.id}/`}
              >
                <p className="mb-0" style={{ fontWeight: "500" }}>
                  Build ID: #{deployment?.id?.split("-")[0]}
                </p>
                {currentDeploy && (
                  <div className="d-flex align-items-center mt-1">
                    <Badge bg="info">Current</Badge>
                  </div>
                )}
              </NavLink>
            </Col>
            <Col
              md={3}
              className={
                deployment?.status !== "SUCCEEDED" || deployment?.status !== "FAILED"
                  ? "d-flex flex-column  justify-content-center"
                  : "d-flex align-items-start justify-content-start"
              }
            >
              <div className="d-flex  mb-1">
                {deployment?.status === "SUCCEEDED" ? (
                  <div className="d-flex align-items-center">
                    <img src={require("../../../assets/img/svgs/check_mark.svg").default} alt="integrationStatus" />
                    <p style={{ fontWeight: "500" }} className="mb-0 mx-2">
                      Ready
                    </p>
                  </div>
                ) : deployment?.status === "FAILED" ? (
                  <div className="d-flex align-items-center">
                    <img src={require("../../../assets/img/svgs/wrong_mark.svg").default} alt="integrationStatus" />
                    <p style={{ fontWeight: "500" }} className="mb-0 mx-2">
                      Failed
                    </p>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <Spinner variant="primary" animation="border" size="sm" />
                    <p style={{ fontWeight: "500" }} className="mb-0 mx-2">
                      Deploying
                    </p>
                  </div>
                )}
              </div>
              {deployment?.status !== "SUCCEEDED" ||
                (deployment?.status !== "FAILED" && (
                  <p className="m-0 text-muted fw-normal">
                    {`${duration.minutes() !== 0 ? duration.minutes() + ` ${duration.minutes() === 1 ? "minute" : "minutes"} ` : ""}  ${duration.seconds()} seconds`}
                  </p>
                ))}
            </Col>
            <Col md={3}>
              {deployment?.branch && (
                <div className="d-flex align-items-center mb-1">
                  <svg data-testid="geist-icon" height="16" stroke-linejoin="round" viewBox="0 0 16 16" width="16">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.75 1.75V1H3.25V1.75V9.09451C1.95608 9.42754 1 10.6021 1 12C1 13.6569 2.34315 15 4 15C5.42051 15 6.61042 14.0127 6.921 12.6869C8.37102 12.4872 9.7262 11.8197 10.773 10.773C11.8197 9.7262 12.4872 8.37102 12.6869 6.921C14.0127 6.61042 15 5.42051 15 4C15 2.34315 13.6569 1 12 1C10.3431 1 9 2.34315 9 4C9 5.37069 9.91924 6.52667 11.1749 6.8851C10.9929 7.94904 10.4857 8.9389 9.71231 9.71231C8.9389 10.4857 7.94904 10.9929 6.8851 11.1749C6.59439 10.1565 5.77903 9.35937 4.75 9.09451V1.75ZM13.5 4C13.5 4.82843 12.8284 5.5 12 5.5C11.1716 5.5 10.5 4.82843 10.5 4C10.5 3.17157 11.1716 2.5 12 2.5C12.8284 2.5 13.5 3.17157 13.5 4ZM4 13.5C4.82843 13.5 5.5 12.8284 5.5 12C5.5 11.1716 4.82843 10.5 4 10.5C3.17157 10.5 2.5 11.1716 2.5 12C2.5 12.8284 3.17157 13.5 4 13.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <a
                    target="_blank"
                    href={`https://github.com/${linkedRepo?.length > 0 && linkedRepo[0]?.owner}/${linkedRepo?.length > 0 && linkedRepo[0]?.name}/activity?ref=${deployment?.branch}`}
                    rel="noreferrer"
                  >
                    <p className="mb-0 mx-1">{deployment?.branch}</p>
                  </a>
                </div>
              )}

              {deployment?.commitSHA && (
                <div className="d-flex align-items-center">
                  <svg data-testid="geist-icon" height="16" stroke-linejoin="round" viewBox="0 0 16 16" width="16">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5ZM8 12C9.95279 12 11.5787 10.6006 11.9298 8.75H15.25H16V7.25H15.25H11.9298C11.5787 5.39935 9.95279 4 8 4C6.04721 4 4.42125 5.39935 4.0702 7.25H0.75H0V8.75H0.75H4.0702C4.42125 10.6006 6.04721 12 8 12Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <a
                    href={`https://github.com/${linkedRepo?.length > 0 && linkedRepo[0]?.owner}/${linkedRepo?.length > 0 && linkedRepo[0]?.name}/commit/${deployment?.commitSHA}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mb-0 mx-1">{deployment?.commitSHA.slice(-7)}</p>
                  </a>
                </div>
              )}
            </Col>
            <Col md={2} className="d-flex align-items-center">
              <p className="m-0 text-muted fw-normal">{moment(deployment.createdAt).fromNow()}</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DeploymentCard;
