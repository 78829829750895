import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Breadcrumb,
  InputGroup,
  Form,
  Spinner,
  Badge,
  Tooltip,
  OverlayTrigger,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import GenezioSpinner from "../../components/Utilities/Spinner/GenezioSpinner";
import {
  deleteDatabase,
  getAllDatabases,
  getDatabaseConnectionURL,
  unlinkDatabaseFromProject,
  resetDatabasePassword,
  linkDatabaseToProject,
  getLinkedDatabases,
  getCurrentCollaboratorDetails,
} from "../../network/ApiAxios";
import DeleteDatabase from "../../components/AdvancedUI/Modals/DeleteDatabase";
import CreateDatabase from "../../components/AdvancedUI/Modals/CreateDatabase";
import ResetPasswordDatabase from "../../components/AdvancedUI/Modals/ResetPasswordDatabase";
import DatabaseConnectModal from "../../components/AdvancedUI/Modals/ConnectDatabase";
import DeleteDatabaseLink from "../../components/AdvancedUI/Modals/DeleteDatabaseLink";
import DatabaseInfo from "../../components/AdvancedUI/Modals/DatabaseInfo";
import { CollaboratorDetails } from "../../models/CollaborationModels";

export default function Database() {
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const navigation = useNavigate();
  const [linkedDatabases, setLinkedDatabases] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [elemToDelete, setElemToDelete] = useState<any>(null);
  const [modalDeleteDatabase, setModalDeleteDatabase] = useState(false);
  const [modalDeleteLink, setModalDeleteLink] = useState(false);
  const [modalDatabaseInfo, setModalDatabaseInfo] = useState(false);
  const [modalCreateDatabase, setModalCreateDatabase] = useState(false);
  const [resetPasswordElem, setResetPasswordElem] = useState<any>(null);
  const [isLinking, setIsLinking] = useState(false);
  const [selectedLinkDatabase, setSelectedLinkDatabase] = useState<string>("");
  const [modalConnectDatabase, setModalConnectDatabase] = useState(false);

  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const [elemToConnect, setElemToConnect] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);

  const [copyBtnDbId, setCopyBtnDbId] = useState<string>("");

  const messageCallback = (event: any) => {
    if (event.data === "ready") {
      event.source?.postMessage({ type: "AUTH_TOKEN", token: localStorage.getItem("apiToken") }, event.origin);
      window.removeEventListener("message", messageCallback);
    }
  };

  useEffect(() => {
    document.title = "Genezio | Databases";
    window.addEventListener("message", messageCallback);
  }, []);

  const fetchLinkedDatabases = async () => {
    setIsLoading(true);
    const res = await getLinkedDatabases(projectId || "", envId || "").catch((err) => {
      console.log(err);
      return null;
    });

    setIsLoading(false);

    if (!res) {
      toast.error("Failed to fetch databases. Please try again later.");
      return;
    }

    setLinkedDatabases(res.data.databases || []);
  };

  const fetchDatabases = async () => {
    setIsLoading(true);
    const res = await getAllDatabases().catch((err) => {
      console.log(err);
      return null;
    });

    if (res?.data?.databases === undefined) {
      alert("Something went wrong");
    }

    setIsLoading(false);

    if (!res) {
      toast.error("Failed to fetch databases. Please try again later.");
      return;
    }

    setDatabases(res?.data?.databases || []);
    return res?.data?.databases;
  };

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  useEffect(() => {
    fetchDatabases();
  }, []);

  useEffect(() => {
    fetchLinkedDatabases();
  }, []);

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  async function handleDeleteDatabase(id: string) {
    const res: any = await deleteDatabase(id);

    if (res.response && res.response.data.error) {
      toast.error("Failed to delete database. Please try again later.");
      setModalDeleteDatabase(false);
      setElemToDelete(null);
      return;
    }

    setModalDeleteDatabase(false);
    setElemToDelete(null);

    const newDatabases = databases.filter((db: any) => db.id !== id);
    setDatabases(newDatabases);
    toast.success("Database deleted successfully!");
  }

  async function handleResetPasswordCallback() {
    const res: any = await resetDatabasePassword(resetPasswordElem.id);

    if (res.response && res.response.data.error) {
      toast.error("Failed to reset database password. Please try again later.");
      setResetPasswordElem(null);
      return;
    }

    setResetPasswordElem(null);
    toast.success("Password reset successfully!");
  }

  async function handleDeleteDatabaseLink(id: string) {
    const res: any = await unlinkDatabaseFromProject(projectId || "", envId || "", id);

    if (res.response && res.response.data.error) {
      toast.error("Failed to unlink database. Please try again later.");
      return;
    }

    await fetchLinkedDatabases();
    await fetchDatabases();
    setElemToDelete(null);
    setModalDeleteDatabase(false);
    toast.success("Database unlinked successfully!");
  }

  async function handleLinkDatabase() {
    setIsLinking(true);
    const res: any = await linkDatabaseToProject(projectId || "", envId || "", selectedLinkDatabase);
    setIsLinking(false);

    if (res.response && res.response.data.error) {
      toast.error("Failed to link database. Please try again later.");
      return;
    }

    await fetchLinkedDatabases();
    setSelectedLinkDatabase("");

    toast.success("Database linked successfully!");
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {/* <!-- Modal Delete --> */}
      <DeleteDatabase
        databaseName={elemToDelete?.name}
        deleteDatabaseCallback={() => handleDeleteDatabase(elemToDelete?.id)}
        show={modalDeleteDatabase}
        onHide={() => {
          setElemToDelete(null);
          setModalDeleteDatabase(false);
        }}
      />
      {/* <!-- /Modal Delete --> */}

      {/* <!-- Modal Delete Link --> */}
      <DeleteDatabaseLink
        databaseName={elemToDelete?.name}
        deleteDatabaseLinkCallback={() => handleDeleteDatabaseLink(elemToDelete?.id)}
        show={modalDeleteLink}
        onHide={() => {
          setElemToDelete(null);
          setModalDeleteLink(false);
        }}
      />
      {/* <!-- /Modal Delete Link --> */}

      {/* Database Info Modal */}
      <DatabaseInfo show={modalDatabaseInfo} onHide={() => setModalDatabaseInfo(false)} />
      {/* Database Info Modal */}

      {/* <!-- Modal Connect To Db --> */}
      {modalConnectDatabase && (
        <DatabaseConnectModal
          show={modalConnectDatabase}
          onHide={() => {
            setModalConnectDatabase(false);
            setElemToConnect(null);
          }}
          databaseId={elemToConnect?.id}
          databaseName={elemToConnect?.name}
          databaseType={elemToConnect?.type}
        />
      )}
      {/* <!-- /Modal Connect To Db --> */}

      {/* <!-- Modal Change Passsword --> */}
      <ResetPasswordDatabase
        databaseName={resetPasswordElem?.name}
        resetPasswordCallback={() => handleResetPasswordCallback()}
        show={resetPasswordElem !== null}
        onHide={() => {
          setResetPasswordElem(null);
        }}
      />
      {/* <!-- /Modal Delete --> */}

      {/* <!-- Modal Create --> */}
      <CreateDatabase
        createDatabaseCallback={async (dbName) => {
          const localDbs = await fetchDatabases();
          const newCreatedDb = localDbs.find((db: any) => db.name === dbName)?.id || "";

          setIsLinking(true);
          const res: any = await linkDatabaseToProject(projectId || "", envId || "", newCreatedDb);
          setIsLinking(false);

          setModalDatabaseInfo(true);

          await fetchLinkedDatabases();
        }}
        show={modalCreateDatabase}
        onHide={() => {
          setModalCreateDatabase(false);
        }}
      />
      {/* <!-- /Modal Create --> */}

      {/* <!-- row  --> */}
      <Row className="mt-3">
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h4 className="card-title">Databases</h4>
            </Card.Header>

            {databases.length === 0 || isLoading ? (
              <Card.Body className="pt-0 d-flex align-items-center justify-content-center" style={{ height: "70vh" }}>
                {isLoading ? (
                  <GenezioSpinner />
                ) : databases.length === 0 ? (
                  <div>
                    <Row className="pb-2">
                      <Col sm={12} className="col-12 text-center p-5 text-dark">
                        <h4>You don't have any databases!</h4>
                        <p className="mb-3 ">
                          Information about the databases will be shown here after creating them.{" "}
                        </p>
                        <Button className="btn btn-primary p-1 px-3" onClick={() => setModalCreateDatabase(true)}>
                          Create Database
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </Card.Body>
            ) : (
              <Card.Body className="pt-0 d-flex align-items-center justify-content-center">
                <div className="table-responsive">
                  <>
                    {currentCollaboratorDetails.role === "owner" ? (
                      <>
                        <Form.Group className="mb-3 text-dark">
                          <Form.Label className="text-dark">Link a Database</Form.Label>
                          <Form.Select
                            size="sm"
                            onChange={(e) => {
                              if (e.target.value === "GNZ_CREATE_NEW_DB") {
                                setModalCreateDatabase(true);
                                return;
                              }
                              setSelectedLinkDatabase(e.target.value);
                            }}
                            value={selectedLinkDatabase}
                          >
                            {/* options databases - linked databases */}
                            <option value="" disabled>
                              Choose Database
                            </option>
                            {databases
                              .filter((db: any) => !linkedDatabases.find((ldb: any) => ldb.id === db.id))
                              .map((db: any, index: number) => {
                                return (
                                  <option key={index} value={db.id}>
                                    {db.name} {db.region}
                                  </option>
                                );
                              })}
                            <option value="GNZ_CREATE_NEW_DB">Create New Database</option>
                          </Form.Select>
                        </Form.Group>
                        <Button
                          className="btn btn-primary p-1 px-3"
                          onClick={() => handleLinkDatabase()}
                          disabled={selectedLinkDatabase === "" || isLinking}
                        >
                          Link Database {isLinking && <Spinner animation="border" size="sm" />}
                        </Button>
                      </>
                    ) : (
                      ""
                    )}

                    <h5 className="my-3">Linked Databases</h5>

                    <table className="table text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="tabletitle">#</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">Database Name</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">Database Region</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">Database Type</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">URL</span>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {linkedDatabases?.map((db: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="text-center">{db.name}</td>
                              <td className="text-center">{db.region}</td>
                              <td className="text-center">{db.type}</td>
                              <td className="text-center d-flex justify-content-center">
                                <Button
                                  className="btn btn-primary p-1 px-3 mt-2"
                                  onClick={() => {
                                    setElemToConnect(db);
                                    setModalConnectDatabase(true);
                                  }}
                                >
                                  Connect
                                </Button>
                              </td>
                              <td className="text-center">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    right: "10px",
                                    top: "10px",
                                    width: "100%",
                                    maxWidth: "20px",
                                    padding: "10px",
                                  }}
                                >
                                  {elemToDelete?.id === db.id || resetPasswordElem ? (
                                    <Spinner animation="border" variant="primary" size="sm" />
                                  ) : (
                                    <>
                                      <div>
                                        <DropdownButton
                                          size="sm"
                                          id="dropdown-basic-button"
                                          title="Actions"
                                          variant="light"
                                          autoClose={true}
                                        >
                                          {db.type === "postgres-neon" ? (
                                            <>
                                              <Dropdown.Item
                                                as="button"
                                                onClick={() => {
                                                  window.open(
                                                    process.env.REACT_APP_POSTGRES_SCHEMA_EDITOR_URL + `/edit/${db.id}`,
                                                    "_blank",
                                                  );
                                                }}
                                              >
                                                Edit Schema
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as="button"
                                                onClick={() => {
                                                  window.open(
                                                    process.env.REACT_APP_POSTGRES_SCHEMA_EDITOR_URL + `/data/${db.id}`,
                                                    "_blank",
                                                  );
                                                }}
                                              >
                                                View Data
                                              </Dropdown.Item>
                                            </>
                                          ) : (
                                            <Dropdown.Item
                                              as="button"
                                              onClick={() => {
                                                window.open(
                                                  process.env.REACT_APP_MONGO_EDITOR_URL + `/${db.id}`,
                                                  "_blank",
                                                );
                                              }}
                                            >
                                              View Data
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() => {
                                              setResetPasswordElem(db);
                                            }}
                                          >
                                            Reset Password
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            className="text-danger"
                                            onClick={() => {
                                              setElemToDelete(db);
                                              setModalDeleteDatabase(true);
                                            }}
                                          >
                                            Delete Database
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            className="text-danger"
                                            onClick={() => {
                                              setElemToDelete(db);
                                              setModalDeleteLink(true);
                                            }}
                                          >
                                            Remove from current project
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
