import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Spinner, Breadcrumb, FormGroup, Form, Modal } from "react-bootstrap";
import { getAccessTokens, deleteAccessToken, createAccessToken } from "../../network/ApiAxios";
import { useNavigate } from "react-router-dom";
import { sendToast } from "../Integrations/utils";
import moment from "moment";
import "./Settings.css";
import DeleteToken from "../../components/AdvancedUI/Modals/DeleteToken";

interface accessTokenResponse {
  data: {
    accessTokens: string;
  };
  response?: {
    data: {
      error: {
        code: number;
        message: string;
      };
    };
  };
}

export default function AccessTokens() {
  const [accessTokens, setAccessTokens] = useState<any>([]);
  const [buttonCopyState, setButtonCopyState] = useState<string>("");

  const [error, setError] = useState<any>(null);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [isCreatingToken, setIsCreatingToken] = useState(false);

  const [newTokenName, setNewTokenName] = useState("");
  const [newToken, setNewToken] = useState("");

  const [newTokenModal, setNewTokenModal] = useState(false);

  const [isGeneratingToken, setIsGeneratingToken] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  const handleChaneModalState = () => {
    setNewTokenModal(!newTokenModal);
    setNewTokenName("");
  };

  React.useEffect(() => {
    document.title = "Genezio | Access Tokens";
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      const resp: accessTokenResponse = await getAccessTokens();
      setAccessTokens(resp?.data?.accessTokens);

      setIsLoading(false);
      if (resp?.response?.data?.error.code && resp.response.data.error.message) {
        sendToast(resp.response.data.error.message, "error");
        return;
      }
    };

    runAsync();
  }, []);

  const handleDeleteModal = (tokenId: any) => {
    if (tokenId) {
      setSelectedToken(tokenId);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
      setSelectedToken(null);
    }
  };

  const handleDeleteToken = async () => {
    setIsLoading(true);
    if (selectedToken !== null) {
      await deleteAccessToken(selectedToken);
      const resp = await getAccessTokens();
      setAccessTokens(resp.data.accessTokens);
      setIsLoading(false);
    }
  };

  async function handleCreateToken(e: any) {
    e.preventDefault();
    const isValid = /^[A-Za-z]/;

    if (!isValid.test(newTokenName) && newTokenName !== "") {
      setError("Access token name must start with a letter");
      return;
    }

    setError(null);
    setIsGeneratingToken(true);
    const resp: any = await createAccessToken(newTokenName);
    setIsGeneratingToken(false);
    // setNewTokenName("");

    if (resp.response && resp.response.data.error) {
      setError(resp.response.data.error.message);
      return;
    }

    setNewToken(resp.data.accessToken);

    setIsCreatingToken(false);
    setNewTokenModal(true);

    const respTokens = await getAccessTokens();
    setAccessTokens(respTokens.data.accessTokens);
  }

  function copyToClipboard(textToCopy: string, btn: string) {
    if (buttonCopyState) {
      return;
    }
    setButtonCopyState(btn);
    navigator.clipboard.writeText(textToCopy);
    setTimeout(() => {
      setButtonCopyState("");
    }, 500);
  }

  const darkMode = localStorage.getItem("darkMode") === "true";

  return (
    <>
      <Modal
        show={newTokenModal}
        onHide={handleChaneModalState}
        backdrop="static"
        className="modal-border-radius"
        keyboard={false}
        centered
      >
        <Modal.Body className="p-4">
          <h3 className="mb-5">Token {newTokenName} was generated with success</h3>

          <h6 className="mb-3">Make sure to copy your personal access token now. You won't be able to see it again!</h6>

          <Row className="pl-1">
            <Col md={11} className="pl-2">
              <Col
                style={{
                  backgroundColor: darkMode ? "#090918" : "#f4f4f4",
                  borderRadius: "10px",
                  fontWeight: "300",
                  letterSpacing: "2px",
                  overflowWrap: "break-word",
                }}
                className=" p-3"
              >
                {newToken}
              </Col>
            </Col>
            <Col
              md={1}
              style={{
                padding: "0 0 0 12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => copyToClipboard(newToken, "token")}
                style={{ cursor: "pointer", backgroundColor: darkMode ? "#090918" : "#f4f4f4" }}
                className="text-muted p-3 step_copy_code_element"
              >
                {buttonCopyState === "token" ? (
                  <i className="fa fa-check text-success" aria-hidden="true" />
                ) : (
                  <img
                    src={require("./../../assets/img/svgs/copy_icon.svg").default}
                    alt="Copy"
                    style={{
                      width: "120%",
                      maxWidth: "120%",
                      filter: darkMode
                        ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                        : "none",
                    }}
                  />
                )}
              </div>
            </Col>

            <h6 className="mb-3 mt-4">Login command</h6>
            <Col md={11} className="pl-2 mb-2">
              <Col
                style={{
                  backgroundColor: darkMode ? "#090918" : "#f4f4f4",
                  borderRadius: "10px",
                  fontWeight: "300",
                  letterSpacing: "2px",
                  overflowWrap: "break-word",
                }}
                className="p-3"
              >
                <p>{`genezio login ${newToken.slice(0, 53)}...`}</p>
              </Col>
            </Col>
            <Col
              md={1}
              style={{
                padding: "0 0 0 12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => copyToClipboard(`genezio login ${newToken}`, "genezio_login")}
                className="text-muted p-3 step_copy_code_element mb-2"
                style={{ cursor: "pointer", backgroundColor: darkMode ? "#090918" : "#f4f4f4" }}
              >
                {buttonCopyState === "genezio_login" ? (
                  <i className="fa fa-check text-success" aria-hidden="true" />
                ) : (
                  <img
                    src={require("./../../assets/img/svgs/copy_icon.svg").default}
                    alt="Copy"
                    style={{
                      width: "120%",
                      maxWidth: "120%",
                      filter: darkMode
                        ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                        : "none",
                    }}
                  />
                )}
              </div>
            </Col>
            <Row className="d-flex justify-content-end w-100 m-1 mt-4">
              <Button variant="primary" onClick={handleChaneModalState}>
                Confirm, I copied it!
              </Button>
            </Row>
          </Row>
        </Modal.Body>
      </Modal>

      <React.Fragment>
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item
                className="breadcrumb-item"
                onClick={() => navigate("/")}
                aria-current="page"
                style={{ paddingTop: "1px" }} // hack don;t change it
              >
                All Projects
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item " active aria-current="page">
                Personal Access Tokens
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {/* <!-- /breadcrumb --> */}

        {/* <!-- row  --> */}
        <Row>
          <Col sm={12} className="col-12">
            <Card>
              <Card.Header>
                <h4 className="card-title">Personal access tokens</h4>
                {accessTokens.length === 0 && !isLoading ? (
                  <>
                    <div className="mt-2">There are no Personal Access Tokens created yet. Create your first one!</div>
                  </>
                ) : null}
              </Card.Header>
              <Card.Body className="pt-0 example1-table">
                {!isCreatingToken ? (
                  <Button variant="primary" onClick={(e) => setIsCreatingToken(true)}>
                    Create New Token
                  </Button>
                ) : (
                  <Form onSubmit={handleCreateToken}>
                    <Row>
                      <Col sm={6}>
                        {error ? (
                          <div className="alert alert-danger" role="alert">
                            {error}
                          </div>
                        ) : null}
                        <FormGroup>
                          <Form.Label className="form-label text-dark">Access Token Name</Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="What's this token for?"
                            value={newTokenName}
                            onChange={(e) => {
                              setNewTokenName(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="mt-3">
                        <Button disabled={isGeneratingToken} variant="primary" onClick={(e) => handleCreateToken(e)}>
                          Generate New Token{" "}
                          {isGeneratingToken ? <Spinner variant="light" animation="border" size="sm" /> : null}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}

                {isCreatingToken ? null : (
                  <div className="table-responsive mt-4">
                    <table className="table text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="tabletitle">#</span>
                          </th>
                          <th>
                            <span className="tabletitle">Token Name</span>
                          </th>
                          <th>
                            <span className="tabletitle">Creation Date</span>
                          </th>
                          <th>
                            <span className="tabletitle">Last used</span>
                          </th>
                          <th>
                            <span className="tabletitle">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading ? (
                          <>
                            {accessTokens.map((elem: any, index: number) => {
                              return (
                                <tr key={elem.id}>
                                  {/* Delete modal */}
                                  {deleteModal && selectedToken === elem.id && (
                                    <DeleteToken
                                      tokenName={elem.name}
                                      onHide={() => handleDeleteModal(0)}
                                      onDelete={handleDeleteToken}
                                    />
                                  )}

                                  {/* Token --> */}
                                  <td>{index + 1}</td>

                                  <td>{elem.name}</td>

                                  <td>{moment.unix(elem.createdAt).format("MMM D, YYYY [at] h:mm A")}</td>
                                  <td>{moment.unix(elem.lastUsedAt).fromNow()}</td>
                                  <td>
                                    <Button
                                      variant="outline-danger"
                                      onClick={() => handleDeleteModal(elem.id)}
                                      size="sm"
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : null}
                      </tbody>
                    </table>

                    {isLoading && (
                      <>
                        <Spinner variant="primary" animation="border" className="d-flex mx-auto my-3" />
                      </>
                    )}
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
}
