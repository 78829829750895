import { Col } from "react-bootstrap";
import { handleLinkOpen } from "../../NewProject/utils";
const ConnectToGithubForkProject = ({
  handleNoGithub,
  isConnectedToGithub,
  handleHaveGithub,
  state,
}: {
  handleNoGithub: () => void;
  isConnectedToGithub: boolean;
  handleHaveGithub: () => void;
  // state fork default
  state: "fork" | "s3-import";
}) => {
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  return (
    <Col lg={12} className="m-0 w-100 p-0 rounded">
      <div className="m-auto d-flex flex-column p-0">
        <h4 className="fw-bold text-start">Deploy now</h4>
        <p className="mt-3 text-start">
          Connect your GitHub account to enjoy automatic CI/CD whenever you update the code and push it to "main"
          branch.
        </p>
        <>
          <div className="row w-100 d-flex justify-content-evenly m-auto">
            {isConnectedToGithub ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleHaveGithub();
                }}
                className={`btn ${isDarkMode ? "github-new-project-btn-light" : "github-new-project-btn"} mt-3`}
                id="github-fork-flow-btn-deploy-on-github"
              >
                <i className="fab fa-github"></i>
                <span>Deploy on GitHub</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  localStorage.setItem("locationSearch", window.location.search.substring(1));
                  handleLinkOpen(
                    `https://github.com/apps/${process.env.REACT_APP_GITHUB_APP_NAME}/installations/new?state=${state}`,
                  );
                }}
                className={`btn ${isDarkMode ? "github-new-project-btn-light" : "github-new-project-btn"} mt-3`}
                id="github-fork-flow-btn"
              >
                <i className="fab fa-github"></i>
                <span>Connect with GitHub</span>
              </button>
            )}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleNoGithub();
              }}
              className={`btn ${isDarkMode ? "github-new-project-btn-light" : "github-new-project-btn"}  mt-3`}
              id="without-github-fork-flow-btn"
            >
              <span>Deploy without GitHub</span>
            </button>
          </div>
        </>
      </div>
    </Col>
  );
};
export default ConnectToGithubForkProject;
