import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { deactivateIntegration } from "../../../network/ApiAxios";
import { DeleteIntegrationModalProps } from "../../../models/IntegrationModels";

const DeleteIntegrationModal: React.FC<DeleteIntegrationModalProps> = ({
  show,
  projectId,
  envId,
  integrationName,
  onDeleteIntegrationClose,
  setActiveStep,
  resetSelectedDatabase,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    await deactivateIntegration(projectId ?? "", envId ?? "", integrationName);
    onDeleteIntegrationClose();
    setActiveStep();
    setLoading(false);
  };

  const displayName =
    integrationName?.split("-")[0].toLowerCase().charAt(0).toUpperCase() +
    integrationName?.split("-")[0].slice(1).toLowerCase();

  return (
    <Modal onHide={() => onDeleteIntegrationClose()} show={show} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "54px 51px 54px 51px" }}>
        <div className="d-flex flex-column align-items-center tx-center">
          <h3 className="font-weight-normal mb-4" style={{ fontSize: "18px", lineHeight: "22px" }}>
            Delete {displayName} Integration
          </h3>
          <h4 className="text-danger" style={{ fontWeight: "600", fontSize: "12px", lineHeight: "16px" }}>
            This action will permanently delete your {displayName} integration!
          </h4>
          <p className="mb-0 mt-1 mx-auto text-center" style={{ maxWidth: "420px", fontSize: "12px" }}>
            Are you sure you want to delete {displayName} integration? You will not be able to use it anymore.
          </p>
          <Button
            variant="outline-danger"
            className="p-0 mt-4"
            style={{
              height: "30px",
              maxWidth: "120px",
              margin: "0 auto",
              width: "100%",
            }}
            disabled={loading}
            onClick={() => {
              resetSelectedDatabase();
              handleDelete();
            }}
          >
            Delete {loading && <Spinner animation="border" className="ms-2" size="sm" />}
          </Button>{" "}
          <p
            onClick={() => onDeleteIntegrationClose()}
            className="p-0 mt-3"
            style={{
              fontSize: "12px",
              color: "#999999",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            Cancel
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteIntegrationModal;
