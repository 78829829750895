import React from "react";
import { getCheckoutLink } from "../../../network/ApiAxios";
import { Spinner } from "react-bootstrap";

export interface UpgradeSubscriptionButtonProps {
  subscriptionPlan: string;
}

const UpgradeSubscriptionButton: React.FC<UpgradeSubscriptionButtonProps> = ({ subscriptionPlan }) => {
  const [isLoadingCheckout, setIsLoadingCheckout] = React.useState<string | null>(null);

  async function getLemonCheckout(plan: string) {
    setIsLoadingCheckout(plan);
    const resp: any = await getCheckoutLink(plan);
    setIsLoadingCheckout(null);

    if (resp.response && resp.response.data.error) {
      alert(resp.response.data.error.message);
      return;
    }

    if (resp.data && resp.data.error) {
      alert(resp.data.error.message);
      return;
    }

    if (resp.data && resp.data.checkoutUrl) {
      window.location.href = resp.data.checkoutUrl;
      return;
    }
  }
  const hobbyPlan = "Hobby";
  const proPlan = "Pro";
  return subscriptionPlan === hobbyPlan ? (
    <button onClick={async () => getLemonCheckout(proPlan)} className="btn btn-outline-primary">
      Upgrade to Pro
      {isLoadingCheckout === proPlan ? <Spinner animation="border" size="sm" /> : null}
    </button>
  ) : subscriptionPlan === proPlan ? (
    <button
      onClick={() => {
        window.location.href = "mailto:contact@genez.io";
      }}
      className="btn btn-outline-primary"
    >
      Upgrade to Enterprise
    </button>
  ) : (
    <></>
  );
};

export default UpgradeSubscriptionButton;
