import { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { DisableAuthModalProps } from "../../../models/AuthModels";
import { activateAuth } from "../../../network/ApiAxios";
import { sendToast } from "../../../views/Integrations/utils";

const DisableAuthModal: React.FC<DisableAuthModalProps> = ({ show, hide, envId, onDisableSuccess }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDisableAuth = async () => {
    setLoading(true);
    if (envId) {
      const res: any = await activateAuth(envId, { enabled: false });
      if (res.status === 200) {
        onDisableSuccess();
      } else {
        sendToast(res.response.data.error.message, "error");
      }
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={hide} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "32px" }}>
        <div className="d-flex flex-column">
          <h5 className=" text-center" style={{ fontWeight: "600" }}>
            Disable Authentication
          </h5>

          <p className="text-center mt-3">
            Are you sure you want to disable authentication for this project? This will remove the authentication system
            from your app and the configuration will be lost
          </p>

          <div className="d-flex justify-content-center mt-3">
            <Button onClick={hide} className="btn border mx-2 px-2" size="sm" variant="">
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={() => handleDisableAuth()}
              size="sm"
              variant="outline-secondary"
              className="px-3 mx-2"
            >
              Disable
              {loading && <Spinner animation="border" size="sm" className="mx-1" />}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DisableAuthModal;
