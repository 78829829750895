import { useState, useEffect } from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import { EnableAuthProviderModalProps } from "../../../models/AuthModels";
import { enableAuthProvider } from "../../../network/ApiAxios";
import { capitalizeFirstLetter } from "../../../utils";
import { sendToast } from "../../../views/Integrations/utils";

const EnableAuthProviderModal: React.FC<EnableAuthProviderModalProps> = ({
  show,
  providerData,
  handleCancel,
  fetchAuthProviders,
  envId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [googleId, setGoogleId] = useState<string>("");
  const [googleSecret, setGoogleSecret] = useState<string>("");

  const handleAuthProviderSave = async () => {
    setLoading(true);

    const res: any = await enableAuthProvider(envId, {
      authProviders:
        providerData?.name === "email" || providerData?.name === "web3" // Email or web3 case
          ? [
              {
                id: providerData.id,
                name: providerData?.name,
                enabled: enabled,
              },
            ]
          : // google
            [
              {
                id: providerData.id,
                name: "google",
                enabled: enabled,
                config: {
                  GNZ_AUTH_GOOGLE_ID: googleId,
                  GNZ_AUTH_GOOGLE_SECRET: googleSecret,
                },
              },
            ],
    });

    if (res.status === 200 && res.data.status === "ok") {
      await fetchAuthProviders();
      if (providerData?.name === "google" && !res?.data?.authProviders[1].enabled) {
        setGoogleId("");
        setGoogleSecret("");
      }
      handleCancel();
    } else {
      sendToast(res.response.data.error.message, "error");
    }

    setLoading(false);
  };

  useEffect(() => {
    setEnabled(providerData.enabled);
    if (providerData.name === "google") {
      setGoogleId(providerData.config.GNZ_AUTH_GOOGLE_ID);
      setGoogleSecret(providerData.config.GNZ_AUTH_GOOGLE_SECRET);
    }
  }, [providerData]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleCancel();
        setEnabled(providerData.enabled);
        if (!providerData.enabled) {
          setGoogleId("");
          setGoogleSecret("");
        }
      }}
      className="smaller-modal-width"
      centered
    >
      <Modal.Body style={{ padding: "32px" }}>
        <div className="d-flex flex-column">
          <h5 className="" style={{ fontWeight: "600" }}>
            {capitalizeFirstLetter(providerData?.name)} Auth Settings
          </h5>

          <p className="text-muted" style={{ fontWeight: "500", fontSize: "14px" }}>
            To activate {capitalizeFirstLetter(providerData?.name)} authentication in your application, fill in this
            form. For more info you can visit the{" "}
            <a
              rel="noreferrer"
              href={`${process.env.REACT_APP_GENEZIO_DOCUMENTATION}${
                providerData?.name === "google"
                  ? "features/authentication#enable-google-oauth-20-authentication"
                  : providerData?.name === "web3"
                    ? "features/authentication/#set-up-web3-authentication"
                    : "features/authentication#enable-email-and-password-authentication"
              }`}
              target="_blank"
            >
              docs
            </a>
            .
          </p>

          <div>
            <Form.Check
              className="mx-2 my-0"
              type="switch"
              label={`${enabled ? "Enabled" : "Disabled"}`}
              id="enable-switch"
              name="enabled"
              checked={enabled}
              onChange={(e) => setEnabled(e.target.checked)}
            />
          </div>

          {providerData.name === "google" && (
            <>
              <Form.Label className=" mb-2 mt-2">Google Id</Form.Label>
              <Form.Control
                onChange={(e) => setGoogleId(e.target.value)}
                placeholder="Google Id"
                name="GNZ_AUTH_GOOGLE_ID"
                value={googleId}
              />
              <Form.Label className=" mb-2 mt-2">Google Secret</Form.Label>
              <Form.Control
                onChange={(e) => setGoogleSecret(e.target.value)}
                placeholder="Google Secret"
                name="GNZ_AUTH_GOOGLE_SECRET"
                value={googleSecret}
              />
            </>
          )}

          <div className="d-flex justify-content-end mt-3">
            <Button
              onClick={() => {
                handleCancel();
                setEnabled(providerData.enabled);
                if (!providerData.enabled) {
                  setGoogleId("");
                  setGoogleSecret("");
                }
              }}
              className="btn border mx-2 px-2"
              size="sm"
              variant=""
            >
              Cancel
            </Button>
            <Button
              disabled={loading || (providerData.name === "google" && (!googleId || !googleSecret)) ? true : false}
              onClick={() => handleAuthProviderSave()}
              size="sm"
              variant="primary"
              className="px-3 mx-2"
            >
              Save
              {loading && <Spinner animation="border" size="sm" className="mx-1" />}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EnableAuthProviderModal;
