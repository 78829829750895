import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";

interface CancelEnvProps {
  show: boolean;
  projectId: string;
  envId: string;
  hide: () => void;
}

const CancelEnv: React.FC<CancelEnvProps> = ({ show, hide, projectId, envId }) => {
  return (
    <Modal show={show} onHide={() => hide()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "54px 51px 54px 51px" }}>
        <div className="d-flex flex-column align-items-center tx-center">
          <h3 className="font-weight-normal mb-4" style={{ fontSize: "18px", lineHeight: "22px" }}>
            Exit
          </h3>
          <p className="mb-0 mt-1 mx-auto text-center" style={{ maxWidth: "420px", fontSize: "12px" }}>
            If you leave this page, all content that is unsaved will be lost. Are you sure you want to leave?
          </p>
          <NavLink
            to={`/project/${projectId}/${envId}/backend`}
            className="btn btn-outline-primary px-4 mt-3"
            style={{ padding: "5px 8px 5px 8px", fontSize: "11.5px" }}
          >
            Yes
          </NavLink>
          <p
            onClick={() => hide()}
            className="p-0 mt-3"
            style={{
              fontSize: "12px",
              color: "#999999",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            Cancel
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelEnv;
