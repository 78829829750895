import { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const IntegrationCard: React.FC<{
  integration: any;
  projectId?: string;
  envId?: string;
  isInstalled?: boolean;
  envName?: string;
  collaboratorRole?: string;
}> = ({ integration, projectId, isInstalled, envId, envName, collaboratorRole }) => {
  const { name } = integration;
  const [integrationImage, setIntegrationImage] = useState("");
  const lowercaseName = (name as string).toLowerCase();

  const disabledIntegrationLink = envName === "prod" && collaboratorRole === "collaborator" ? true : false;

  useEffect(() => {
    try {
      setIntegrationImage(require(`./../../assets/img/integrations/${lowercaseName}.png`));
    } catch (error) {}
  }, [lowercaseName]);

  return (
    <>
      <Col className={"mb-2 radius border p-4 d-flex align-items-end justify-content-between position-relative"} lg={3}>
        <img
          src={integrationImage}
          alt={`${name}-icon`}
          style={{
            borderRadius: "10px",
            height: "64px",
            width: "64px",
            objectFit: "contain",
          }}
        />

        {isInstalled && (
          <img
            style={{
              top: "22px",
              right: "22px",
            }}
            className="position-absolute"
            src={require("./../../assets/img/svgs/check_mark.svg").default}
            alt="integrationStatus"
          />
        )}

        <div className="text-center">
          <p>{name}</p>
          <NavLink
            to={disabledIntegrationLink ? "#" : `/integrations/${projectId}/${envId}/${lowercaseName}`}
            className={disabledIntegrationLink ? "btn border" : "btn btn-outline-primary"}
            style={{
              padding: "5px 8px 5px 8px",
              fontSize: "11.5px",
              cursor: disabledIntegrationLink ? "default" : "pointer",
            }}
          >
            {isInstalled ? "Manage" : "Install"}
          </NavLink>
        </div>

        <a
          href={
            process.env.REACT_APP_GENEZIO_DOCUMENTATION +
            (lowercaseName === "email-service" ? `features/${lowercaseName}` : `integrations/${lowercaseName}`)
          }
          target="_blank"
          rel="noreferrer"
          className="mb-0 "
        >
          Read More
        </a>
      </Col>
      <Col lg={0.5} />
    </>
  );
};

export default IntegrationCard;
