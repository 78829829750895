import { useState } from "react";
import { Button, Modal, Spinner, Alert } from "react-bootstrap";
import Select, { InputActionMeta } from "react-select";
import CreatableSelect from "react-select/creatable";
import { AddCollaboratorModalProps, Role, Option } from "../../../models/CollaborationModels";
import { addCollaborators } from "../../../network/ApiAxios";
import {
  CreatableSelectConfig,
  createOption,
  formatCollaboratorsValues,
  validateEmail,
  findDuplicateCollaborators,
} from "../../../views/Collaboration/utils";
import { sendToast } from "../../../views/Integrations/utils";

const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    color: "white", // Set placeholder color to white
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918",
    border: "none",
    color: "white",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
};

const AddCollaboratorModal: React.FC<AddCollaboratorModalProps> = ({
  open,
  handleCollaboratorModal,
  project,
  roles,
  fetchCollaborators,
  projectCollaborators,
}) => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  const [inputValue, setInputValue] = useState("");
  const [collaborators, setCollaborators] = useState<readonly Option[]>([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const [showEmailValidationError, setShowEmailValidationError] = useState<boolean>(false);
  const [emailValidationErrorMessage, setEmailValidationErrorMessage] = useState<string>("");

  const handleCancel = () => {
    handleCollaboratorModal(false);

    setSelectedRole("");
    setCollaborators([]);
  };

  const isUniqueValue = (value: string) => {
    const duplicateValue = collaborators.find((element) => element.value === value);
    if (duplicateValue) {
      return false;
    }
    return true;
  };

  const handleKeyDown: any = (event: any) => {
    if (!inputValue) return;

    if (event.key === "Enter" || event.key === "Tab" || event.key === " " || event.key === undefined) {
      event.preventDefault();
      setShowEmailValidationError(true);
      if (!emailValidationErrorMessage) {
        setShowEmailValidationError(false);
        if (isUniqueValue(inputValue)) {
          setCollaborators((prev) => [...prev, createOption(inputValue)]);
          setInputValue("");
        }
      }
    }
  };

  const handleInputChange = (newValue: string, action: InputActionMeta) => {
    if (
      (action.action === "menu-close" || action.action === "input-blur") &&
      !newValue &&
      (!validateEmail(action.prevInputValue) || !isUniqueValue(action.prevInputValue))
    ) {
      setInputValue(action.prevInputValue);
      if (!isUniqueValue(action.prevInputValue)) {
        setEmailValidationErrorMessage("Please use a different email address");
      }
      if (!validateEmail(action.prevInputValue)) {
        setEmailValidationErrorMessage("Please use a valid email address");
      }
    } else {
      if (showEmailValidationError) {
        setShowEmailValidationError(false);
      }
      const isValidEmail = validateEmail(newValue);
      const isUniqueEmail = isUniqueValue(newValue);
      if (!isUniqueEmail) {
        setEmailValidationErrorMessage("Please use a different email address");
      }
      if (!isValidEmail) {
        setEmailValidationErrorMessage("Please use a valid email address");
      }
      if (isValidEmail && isUniqueEmail) {
        setEmailValidationErrorMessage("");
      }
      setInputValue(newValue);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res: any = await addCollaborators(project.id, formatCollaboratorsValues(collaborators, selectedRole));
    if (res.status === 200 && res.data.status === "ok") {
      handleCancel();
      fetchCollaborators();
      setLoading(false);
      return;
    }

    sendToast(res.response.data.error.message, "error");
    setLoading(false);
  };

  return (
    <Modal show={open} onHide={() => handleCancel()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "30px" }}>
        <h6 className="mb-3">Add a collaborator to {project.name}</h6>

        {/* Valid email error */}
        {showEmailValidationError && emailValidationErrorMessage && (
          <Alert variant="danger">{emailValidationErrorMessage}</Alert>
        )}

        {/* Duplicate collaborator error  */}
        {findDuplicateCollaborators(projectCollaborators, collaborators).length ? (
          <Alert variant="danger">You can't invite collaborators who are already in the project</Alert>
        ) : (
          ""
        )}

        <CreatableSelect
          className="mb-3"
          placeholder="Email"
          components={CreatableSelectConfig}
          inputValue={inputValue}
          styles={darkMode ? customStyles : undefined}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={(newValue) => {
            setCollaborators(newValue);
          }}
          onInputChange={(newValue, action) => {
            handleInputChange(newValue, action);
          }}
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          onBlur={(event) => {
            handleKeyDown(event);
          }}
          value={collaborators}
        />

        <Select
          options={[
            ...roles.map((role: Role) => ({
              label: role.name,
              value: role.id,
            })),
          ]}
          placeholder="Role"
          isSearchable={false}
          onChange={(role: any) => setSelectedRole(role.value)}
          className={`my-3 ${darkMode && "darkModeSelector"}`}
        />

        <Button
          disabled={
            findDuplicateCollaborators(projectCollaborators, collaborators).length ||
            !collaborators.length ||
            !selectedRole ||
            loading
          }
          onClick={() => handleSubmit()}
          className="w-100"
        >
          {collaborators.length > 1 ? "Add collaborators" : "Add collaborator"}

          {loading && <Spinner className="ms-2" animation="border" size="sm" />}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddCollaboratorModal;
