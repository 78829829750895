import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { activateAuth, getDatabaseConnectionURL } from "../../network/ApiAxios";
import { GeneralSettingsProps } from "../../models/AuthModels";
import { sendToast } from "../Integrations/utils";
import Select from "react-select";

const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    color: "white", // Set placeholder color to white
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918",
    border: "none",
    color: "white",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918", // Set background color of the options parent
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4a4a4a" : "#090918",
    color: "white", // Set text color
    ":hover": {
      backgroundColor: "#4a4a4a", // Change background color on hover
    },
  }),
};

const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  generalSettingsData,
  envId,
  handleDisableAuth,
  databases,
  toggleDatabaseModal,
  GeneralSettingsSelectedDbValue,
  setGeneralSettingsSelectedDbValue,
  fetchAuth,
}) => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    enabled: generalSettingsData.enabled,
    databaseUrl: generalSettingsData.databaseUrl,
    databaseType: generalSettingsData.databaseType,
  });
  const [showDbSelector, setShowDbSelector] = useState(false);
  const [blurDatabaseUri, setBlurDatabaseUri] = useState<boolean>(true);
  const [copyData, setCopyData] = useState<any>({
    enabled: generalSettingsData?.enabled,
    databaseUrl: generalSettingsData?.databaseUrl,
    databaseType: generalSettingsData?.databaseType,
  });

  const handleUpdateSettings = async () => {
    setLoading(true);
    const res: any = await activateAuth(envId, data);
    if (res.status !== 200) {
      sendToast(res.response.data.error.message, "error");
    }
    setCopyData({
      enabled: res?.data?.enabled,
      databaseUrl: res?.data?.databaseUrl,
      databaseType: res?.data?.databaseType,
    });

    fetchAuth();
    setLoading(false);
  };

  const handleDbSelector = async (e: any) => {
    setGeneralSettingsSelectedDbValue(e);
    setData((prevData: any) => ({ ...prevData, databaseUrl: "" }));

    if (e.value === "Create New Database") {
      toggleDatabaseModal();
    } else if (e.value === "Enter your own URI") {
      setBlurDatabaseUri(false);
    }
  };

  const fetchDbConnectionURL = async () => {
    const res: any = await getDatabaseConnectionURL(GeneralSettingsSelectedDbValue?.value);
    if (res.status === 200 && res.data.status === "ok" && res.data.connectionUrl) {
      setData((prevData: any) => ({ ...prevData, databaseUrl: res.data.connectionUrl }));
    }
  };

  useEffect(() => {
    if (
      GeneralSettingsSelectedDbValue &&
      GeneralSettingsSelectedDbValue.value &&
      GeneralSettingsSelectedDbValue?.value !== "Enter your own URI" &&
      GeneralSettingsSelectedDbValue?.value !== "Create New Database"
    ) {
      fetchDbConnectionURL();
    }
  }, [GeneralSettingsSelectedDbValue]);

  return (
    <div>
      <p className={`fw-medium text-muted mt-3`}>
        Set the database type and database URI for the authentication service. Proceed with caution, changes here might
        affect your users.
      </p>
      <Form.Label className={`${darkMode ? "text-white" : "text-black"}  mb-2 mt-0`}>Disable Auth</Form.Label>
      <Button onClick={() => handleDisableAuth()} className="danger mb-3" variant="outline-danger" size="sm">
        Disable
      </Button>

      <Form.Label className={`${darkMode ? "text-white" : "text-black"} mb-2 mt-0`}>Database Type</Form.Label>
      <div key="inline-radio" className="mb-3">
        <Form.Check
          inline
          label="PostgreSQL"
          name="databaseType"
          type="radio"
          id="inline-database-2"
          checked={data.databaseType === "postgresql"}
          onChange={(e) => {
            setGeneralSettingsSelectedDbValue(null);
            setData((prevData: any) => ({
              ...prevData,
              [e.target.name]: e.target.value,
              databaseUrl: "",
            }));
          }}
          value="postgresql"
        />
        <Form.Check
          inline
          label="MongoDB"
          name="databaseType"
          type="radio"
          id="inline-database-1"
          checked={data.databaseType === "mongodb"}
          onChange={(e) => {
            setShowDbSelector(false);
            setGeneralSettingsSelectedDbValue("Enter your own URI");
            setBlurDatabaseUri(false);
            setData((prevData: any) => ({
              ...prevData,
              [e.target.name]: e.target.value,
              databaseUrl: "",
            }));
          }}
          value="mongodb"
        />
      </div>

      <Form.Label className={`${darkMode ? "text-white" : "text-black"} mb-2 mt-0`}>Database URI</Form.Label>

      {showDbSelector && GeneralSettingsSelectedDbValue?.value !== "Enter your own URI" ? (
        <Select
          styles={darkMode ? customStyles : undefined}
          placeholder="Select Database"
          value={GeneralSettingsSelectedDbValue}
          isDisabled={!data.databaseType}
          options={[
            ...(data.databaseType !== "mongodb"
              ? databases.map((obj: any) => ({
                  value: obj.id,
                  label: `${obj.name} - ${obj.region}`,
                }))
              : []),
            { label: "Create New Database", value: "Create New Database" },
            { label: "Enter your own URI", value: "Enter your own URI" },
          ]}
          onChange={(e: any) => handleDbSelector(e)}
        />
      ) : (
        <div style={{ position: "relative" }}>
          <Form.Control
            onChange={(e) =>
              setData((prevData: any) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
            name="databaseUrl"
            placeholder="Add your own URI"
            disabled={blurDatabaseUri ? true : false}
            value={blurDatabaseUri ? "*".repeat(data.databaseUrl.length) : data.databaseUrl}
          />

          {data?.databaseType !== "mongodb" && (
            <svg
              onClick={() => {
                setData((prevData: any) => ({
                  ...prevData,
                  databaseUrl: "",
                }));
                setShowDbSelector(true);
                setGeneralSettingsSelectedDbValue("Enter your own URI");
              }}
              style={{
                position: "absolute",
                right: "25px",
                top: "10px",
                cursor: "pointer",
                width: "20px",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"
                fill="red"
              />
            </svg>
          )}

          {blurDatabaseUri ? (
            <i
              onClick={() => setBlurDatabaseUri(false)}
              style={{ fontSize: "20px", cursor: "pointer", position: "absolute", right: "-30px", top: "10px" }}
              className="fa fa-eye-slash"
              aria-hidden="true"
            ></i>
          ) : (
            <i
              onClick={() => setBlurDatabaseUri(true)}
              style={{ fontSize: "20px", cursor: "pointer", position: "absolute", right: "-30px", top: "10px" }}
              className="fa fa-eye"
              aria-hidden="true"
            ></i>
          )}
        </div>
      )}

      <div className="d-flex justify-content-end mt-5 pt-5">
        <Button onClick={() => setData(copyData)} className="btn border mx-2 mt-5 px-2" size="sm" variant="">
          Cancel
        </Button>
        <Button
          onClick={() => handleUpdateSettings()}
          disabled={loading || !data.databaseUrl}
          size="sm"
          variant="primary"
          className="mt-5 px-3"
        >
          Save {loading && <Spinner animation="border" size="sm" className="mx-1" />}
        </Button>
      </div>
    </div>
  );
};

export default GeneralSettings;
