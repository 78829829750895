import { Button, Modal } from "react-bootstrap";
import { DeactivateIntegrationModalProps } from "../../../models/IntegrationModels";

const DeactivateIntegrationModal: React.FC<DeactivateIntegrationModalProps> = ({
  show,
  onDeleteIntegrationClose,
  handleDisconnect,
  integrationName,
}) => {
  return (
    <Modal onHide={() => onDeleteIntegrationClose()} show={show} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "54px 51px 54px 51px" }}>
        <div className="d-flex flex-column align-items-center tx-center">
          <h3 className="font-weight-normal mb-4" style={{ fontSize: "18px", lineHeight: "22px" }}>
            Disconnect {integrationName} Integration
          </h3>
          <h4 className="text-danger" style={{ fontWeight: "600", fontSize: "12px", lineHeight: "16px" }}>
            This action will disconnect you from {integrationName} and permanently delete your {integrationName}{" "}
            integration!
          </h4>
          <p className="mb-0 mt-1 mx-auto text-center" style={{ maxWidth: "420px", fontSize: "12px" }}>
            Are you sure you want to delete {integrationName} integration? You will not be able to use it anymore.
          </p>
          <Button
            variant="outline-danger"
            className="p-0 mt-4"
            style={{
              height: "30px",
              maxWidth: "120px",
              margin: "0 auto",
              width: "100%",
            }}
            onClick={() => {
              handleDisconnect();
              onDeleteIntegrationClose();
            }}
          >
            Delete
          </Button>{" "}
          <p
            onClick={() => onDeleteIntegrationClose()}
            className="p-0 mt-3"
            style={{
              fontSize: "12px",
              color: "#999999",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            Cancel
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeactivateIntegrationModal;
