import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { getAllDeployments, getLinkedRepositories } from "../../network/ApiAxios";
import Deploymentcard from "./components/DeploymentCard";
import { ToastContainer } from "react-toastify";
import { sendToast } from "../../views/Integrations/utils";

const DeploymentsHistory = () => {
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const [loading, setLoading] = useState(true);
  const [deployments, setDeployments] = useState([]);
  const [firstRender, setFirstRender] = useState(true); // Tracks first render
  const [linkedRepos, setLinkedRepos] = useState([]);

  useEffect(() => {
    document.title = "Genezio | Deployments History";
  }, []);

  useEffect(() => {
    fetchAllDeployments();
    const intervalId = setInterval(fetchAllDeployments, 3000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchLinkedRepos();
  }, []);

  const fetchLinkedRepos = async () => {
    const res: any = await getLinkedRepositories(projectId);

    if (res.status === 200 && res.data.status === "ok") {
      setLinkedRepos(res.data.repositories);
      return;
    }
    if (res?.response?.data?.error.code !== 3) {
      sendToast(res?.response?.data?.error?.message || "Unable to fetch linked repositories", "error");
    }
  };

  const fetchAllDeployments = async () => {
    if (envId) {
      if (firstRender) {
        setLoading(true);
      }
      const res: any = await getAllDeployments(envId);

      if (res.status === 200 && res.data.status === "ok") {
        setDeployments(res.data.deployments);

        if (firstRender) {
          setLoading(false);
          setFirstRender(false);
        }
        return;
      }

      sendToast(res?.response?.data?.error?.message || "", "error");

      setLoading(false);
      setFirstRender(false);
    }
  };

  return (
    <Row className="mt-3">
      <ToastContainer />
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header>
            <h4 className="card-title">deployments history</h4>
          </Card.Header>
          <Card.Body className="pt-0 example1-table">
            {loading && firstRender ? (
              <div className="d-flex align-items-center justify-content-center p-3">
                <Spinner variant="primary" animation="border" />
              </div>
            ) : (
              <>
                {deployments
                  ?.sort((a: any, b: any) => {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                  })
                  ?.map((deployment: any, index) => {
                    const lastSucceededDeployment: any = deployments
                      ?.slice() // Create a shallow copy of the array
                      ?.find((deployment: any) => deployment.status === "SUCCEEDED");
                    const isLastSucceeded = lastSucceededDeployment?.id === deployment.id;

                    return (
                      <Deploymentcard
                        projectId={projectId}
                        envId={envId}
                        deployment={deployment}
                        key={deployment.id}
                        currentDeploy={isLastSucceeded}
                        linkedRepo={linkedRepos}
                      />
                    );
                  })}
              </>
            )}

            {firstRender === false && deployments?.length === 0 && (
              <p className="text-center fw-bold py-2">There are no deployments to show.</p>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default DeploymentsHistory;
