import { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

type DeleteProps = {
  modal: boolean;
  onHide: () => void;
  handleImport: (envs: any) => void;
  storedEnvs: any;
};

const Delete: React.FC<DeleteProps> = ({ onHide, modal, handleImport, storedEnvs }) => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  const [fileContent, setFileContent] = useState<any | null>(null);
  const closeModal = () => {
    onHide();
    setFileContent(null);
  };

  // TODO: replace with best practice code
  const handleImportSubmit = async () => {
    const nonEmptyStoredEnvs = storedEnvs.filter((obj: any) => Object.keys(obj).length !== 0);

    fileContent.forEach((fileObj: any) => {
      const matchingIndex = nonEmptyStoredEnvs.findIndex((storedObj: any) => storedObj.name === fileObj.name);

      if (matchingIndex !== -1) {
        nonEmptyStoredEnvs[matchingIndex].value = fileObj.value;
      }
    });

    const filteredFileContent = fileContent.filter((fileObj: any) => {
      const matchingIndex = nonEmptyStoredEnvs.findIndex((storedObj: any) => storedObj.name === fileObj.name);
      return matchingIndex === -1; // Exclude if a match is found in nonEmptyStoredEnvs
    });

    handleImport([...nonEmptyStoredEnvs, ...filteredFileContent, {}]);
    closeModal();
  };

  // TODO: remove duplicate code => get this function from EnvironmentVariable
  const handleInputChange = (idx: number, field: string, value: string) => {
    const updatedFileContent = [...fileContent];
    updatedFileContent[idx][field] = value;
    setFileContent(updatedFileContent);
  };

  // TODO: remove duplicate code => get this function from EnvironmentVariable
  const handleRemoveObject = (idx: number) => {
    const updatedFileContent = [...fileContent];
    updatedFileContent.splice(idx, 1);
    setFileContent(updatedFileContent);
  };

  const handleFileUpload = (acceptedFiles: File[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        setFileContent(parseEnvFile(reader?.result));
      }
    };
    reader.readAsText(acceptedFiles[0]);
  };

  function parseEnvFile(src: string) {
    const LINE =
      /(?:^|^)\s*(?:export\s+)?([\w.-]+)(?:\s*=\s*?|:\s+?)(\s*'(?:\\'|[^'])*'|\s*"(?:\\"|[^"])*"|\s*`(?:\\`|[^`])*`|[^#\r\n]+)?\s*(?:#.*)?(?:$|$)/gm;
    const arr: any = [];
    // Convert buffer to string
    let lines = src.toString();
    // Convert line breaks to same format
    lines = lines.replace(/\r\n?/gm, "\n");
    let match;
    while ((match = LINE.exec(lines)) != null) {
      const key = match[1];
      // Default undefined or null to empty string
      let value = match[2] || "";
      // Remove whitespace
      value = value.trim();
      // Check if double quoted
      const maybeQuote = value[0];
      // Remove surrounding quotes
      value = value.replace(/^(['"`])([\s\S]*)\1$/gm, "$2");
      // Expand newlines if double quoted
      if (maybeQuote === '"') {
        value = value.replace(/\\n/g, "\n");
        value = value.replace(/\\r/g, "\r");
      }
      // Push to array
      arr.push({ name: key, value: value });
    }

    return arr;
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
  });

  return (
    <Modal show={modal} onHide={() => closeModal()} className="env-import-modal" centered>
      <Modal.Body>
        <h4 className="card-title mb-4 mt-1 fw-bold">IMPORT VARIABLES</h4>
        <input {...getInputProps()} />
        {fileContent === null ? (
          <div {...getRootProps()}>
            <div
              style={{
                backgroundColor: darkMode ? "#090918" : "#e5e5e5",
                height: "136px",
                maxWidth: "96%",
              }}
              className="border d-flex flex-column justify-content-center align-items-center rounded mx-auto mb-3"
            >
              <img
                style={{
                  filter: darkMode
                    ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                    : "none",
                }}
                src={require("../../../assets/img/svgicons/add-file.svg").default}
                alt="addFile"
              />
              <p style={{ fontSize: "12px", maxWidth: "177px" }} className="mt-2 text-center ">
                {isDragActive ? "Drop the file here..." : `Click to import your .env file or drag and drop it`}
              </p>
            </div>
          </div>
        ) : (
          <div style={{ padding: "0px 19px 4px 20px" }}>
            <Row className="d-flex mb-3">
              <Col lg={4}>
                <p className="font-weight-bold">Key</p>
              </Col>
              <Col lg={7}>
                <p className="font-weight-bold">Value</p>
              </Col>
            </Row>
            {fileContent?.map((env: any, idx: number) => (
              <Row className="mb-3 align-items-start" key={idx}>
                <Col lg={4}>
                  <Form.Control
                    style={{ height: "35px" }}
                    className="form-control w-100"
                    onChange={(e) => handleInputChange(idx, "name", e.target.value)}
                    value={env?.name}
                  />
                </Col>
                <Col lg={7}>
                  <Form.Control
                    as="textarea"
                    style={{ height: "28px" }}
                    className="form-control overflow-hidden"
                    onChange={(e) => handleInputChange(idx, "value", e.target.value)}
                    value={env?.value}
                  />
                </Col>

                <Col lg={1}>
                  <Button
                    variant="outline-danger"
                    className="p-0"
                    style={{
                      height: "30px",
                      maxWidth: "33px",
                      width: "100%",
                    }}
                    onClick={() => handleRemoveObject(idx)}
                  >
                    <i className="far fa-trash-alt" />
                  </Button>
                </Col>
              </Row>
            ))}

            <Row className="mt-4 m-1 d-flex justify-content-end" style={{ paddingRight: "15px" }}>
              <Col lg={3} className="d-flex justify-content-end">
                <Button
                  className="border rounded env-vars-btn "
                  onClick={() => closeModal()}
                  style={{ marginRight: "20px" }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-outline-primary bg-transparent py-1 px-3"
                  onClick={() => handleImportSubmit()}
                >
                  Import
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Delete;
