import { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import {
  connectUserToGithub,
  checkIntegrationUser,
  getRepositoryDetailsByUrl,
  getUserRepositories,
  deployNewProjectGithub,
} from "../../network/ApiAxios";
import ProjectConfiguration from "./Components/ProjectConfiguration";
import DeployProgress from "./Components/DeployProgress";
import Sidebar from "./Components/Sidebar";
import ConnectToGithubForkProject from "./Components/ConnectToGithubForkProject";
import axios from "axios";

const NewProjectFlow = () => {
  const [isConnectedToGithub, setIsConnectedToGithub] = useState<boolean>(false);
  const [githubUserDetails, setGithubUserDetails] = useState<any>({});
  const [repoName, setRepoName] = useState<string>("");
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const [repoBranch, setRepoBranch] = useState<string>("");
  const [projectConfiguration, setProjectConfiguration] = useState<any>(null);
  const [repoId, setRepoId] = useState<number>(0);
  const [githubUserRepos, setGithubUserRepos] = useState<any>([]);
  const [skipGithubConnect, setSkipGithubConnect] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  // get repository url from the url
  const urlParams = new URLSearchParams(window.location.search);
  const repoUrl = urlParams.get("repository") || "";
  const installationId = urlParams.get("code") || "";
  const basePath = urlParams.get("base_path") || ".";
  // get env_vars from the url. there are only the keys with ,
  const envVarsKeysUrl =
    (urlParams.get("env_vars") || "").length > 0 ? (urlParams.get("env_vars") || "").split(",") : [];
  const [envVarsKeys, setEnvVarsKeys] = useState<string[]>(envVarsKeysUrl);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [showProjectConfiguration, setShowProjectConfiguration] = useState<boolean>(false);

  const handleGithubConnect = async () => {
    if (!isConnectedToGithub) {
      if (installationId) {
        const res = await connectUserToGithub(installationId).catch((err) => {
          console.log(err);
          return null;
        });
        if (!res || res.status !== 200) {
          return;
        }
      }
      const res: any = await checkIntegrationUser("github");
      if (res?.status === 200 && res?.data?.connection_status) {
        setGithubUserDetails(res.data?.connected_account);
        setIsConnectedToGithub(true);
      }
      setFinishedLoading(true);
    }
  };
  const handleDeployProject = async (
    githubRepositoryId: any,
    path: any,
    projectConfigurationInput: any,
    env: any,
    repositoryNameLocal?: string,
    privateRepo?: boolean,
  ) => {
    const envs: { [key: string]: string } = {};
    env.forEach((e: any) => {
      envs[e.name] = e.value;
    });
    const projectConfigLocal = {
      ...projectConfiguration,
      name: projectConfigurationInput.name,
      region: projectConfigurationInput.region,
    };
    setProjectConfiguration(projectConfigLocal);
    const token = localStorage.getItem("apiToken");
    if (skipGithubConnect) {
      const data = JSON.stringify({
        token: token || "",
        type: "git",
        args: {
          githubRepository: repoUrl,
          projectName: projectConfigLocal.name,
          region: projectConfigLocal.region,
          stage: "",
          stack: [],
          basePath: basePath,
          isNewProject: true,
          envVars: envs,
        },
      });
      let config = {
        method: "post" as const,
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BUILD_API_BASE_URL + "/deploy",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios
        .request(config)
        .then(async (response) => {
          if (!response.data.jobID) {
            alert(response.data.message);
            return;
          }
          setJobId(response.data.jobID);
          setIsDeploying(true);
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } else {
      const res: any = await deployNewProjectGithub(
        projectConfigLocal.name,
        projectConfigLocal.region,
        repoUrl,
        privateRepo || false,
        path,
        envs,
        repositoryNameLocal || "genezio-project-repo",
      );
      if (res.status === 200 && res.data.status === "ok") {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setRepoId(res.data.repositoryId);
        setIsDeploying(true);
      } else {
        console.log(res);
        alert(res.response.data.error.message);
      }
    }
  };
  useEffect(() => {
    handleGithubConnect();
  }, [installationId]);
  const fetchUserRepos = async () => {
    if (isConnectedToGithub) {
      const res: any = await getUserRepositories();
      if (res.status === 200 && res.data.status === "ok") {
        setGithubUserRepos(res.data.repositories);
      } else {
        setGithubUserRepos([]);
        setIsConnectedToGithub(false);
        setGithubUserDetails({});
      }
    }
  };
  useEffect(() => {
    fetchUserRepos();
  }, [isConnectedToGithub]);
  useEffect(() => {
    const runAsync = async () => {
      // check if repo url is valid
      if (!repoUrl || !repoUrl.includes("github.com") || /github\.com\/([^/]+)\/([^/]+)/.test(repoUrl) === false) {
        window.location.href = "/new-project";
      }
      const repoDetails: any = await getRepositoryDetailsByUrl(repoUrl, basePath);
      if (!repoDetails?.data && isConnectedToGithub) {
        alert(repoDetails.response.data.error.message);
        return;
      }
      if (repoDetails === undefined) {
        alert("Something went wrong");
      }
      setRepoName(`${repoDetails?.data?.repository?.owner}/${repoDetails?.data?.repository?.name}`);
      setRepoBranch(repoDetails?.data?.repository?.branch);
      const localEnvs = envVarsKeysUrl;
      if (repoDetails?.data?.envKeys && repoDetails?.data?.envKeys.length > 0) {
        localEnvs.push(...repoDetails?.data?.envKeys);
      }
      setEnvVarsKeys(localEnvs);
      const projectConfig = repoDetails?.data?.projectConfiguration || {};
      projectConfig.name = projectConfig.name || repoDetails?.data?.repository?.name;
      projectConfig.region = projectConfig.region || "us-east-1";
      projectConfig.configured = repoDetails?.data?.projectConfiguration;
      setProjectConfiguration(projectConfig || {});
    };
    if (repoUrl) {
      runAsync();
    }
  }, []);
  if (!repoUrl) {
    return <Spinner animation="border" variant="primary" />;
  }
  return (
    <Row>
      <a href="/new-project" className="mt-4 mb-3 text-decoration-underline">
        {"<< "}Back to New Project
      </a>
      <Col sm={12} className="col-12">
        <Card>
          <Card.Body
            style={{ minHeight: "80vh", paddingTop: "20px" }}
            className="d-flex align-items-start justify-content-center w-100"
          >
            <>
              {!skipGithubConnect && !showProjectConfiguration ? (
                <>
                  <Row className="mt-4 w-100 d-flex justify-content-center" style={{ paddingTop: "6rem" }}>
                    <Col className="border rounded p-4" lg={8}>
                      {finishedLoading && (
                        <ConnectToGithubForkProject
                          handleNoGithub={() => setSkipGithubConnect(true)}
                          isConnectedToGithub={isConnectedToGithub}
                          handleHaveGithub={() => setShowProjectConfiguration(true)}
                          state={"fork"}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {projectConfiguration ? (
                    <>
                      {isDeploying ? (
                        // Deploy Progress
                        <div className="w-100" style={{ minHeight: "80vh" }}>
                          <Row className="mt-4 w-100">
                            <Sidebar repoName={repoName} branch={repoBranch} activeStep={2} basePath={basePath} />
                            <DeployProgress
                              githubRepositoryId={repoId}
                              jobID={jobId}
                              projectName={projectConfiguration.name}
                            />
                          </Row>
                        </div>
                      ) : (
                        // Project configuration and deploy
                        <Row className="mt-4 w-100">
                          <Sidebar repoName={repoName} branch={repoBranch} activeStep={1} basePath={basePath} />
                          <ProjectConfiguration
                            githubRepositoryId={0}
                            handleDeployProject={handleDeployProject}
                            projectConfiguration={projectConfiguration}
                            repoName={repoName.split("/")[1]}
                            frameworkFrontendProps={""}
                            frameworkBackendProps={""}
                            isForkFlow={!skipGithubConnect}
                            githubAccountName={githubUserDetails.name}
                            githubUserRepos={githubUserRepos}
                            basePath={basePath}
                            envVarsKeys={envVarsKeys}
                            isGenezioDeployBtn={true}
                          />
                        </Row>
                      )}
                    </>
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </>
              )}
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default NewProjectFlow;
