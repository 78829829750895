import { useEffect, useState, useRef } from "react";
import { Row, Col, Button, Card, Spinner } from "react-bootstrap";
import { getProjectDetailsByName, getProjectSubdomains, getFrontendByProjectId } from "../../network/ApiAxios";
import { handleLinkOpen } from "./utils";
import { useParams } from "react-router-dom";

const Congrats = () => {
  const [projectDetails, setProjectDetails] = useState<any>({});
  const [projectSubdomains, setProjectSubdomains] = useState<any>({});
  const [projectDetailsLoading, setProjectDetailsLoading] = useState<boolean>(false);
  const [projectSubdomainsLoading, setProjectSubdomainsLoading] = useState<boolean>(false);
  const [projectFrontend, setProjectFrontend] = useState<any>();
  const [iframeRatio, setIframeRatio] = useState<number>(0.35);

  const iframeWrapperRef = useRef<any>(null);

  const { region, projectName, backendName, backendOwner, frontendName, frontendOwner } = useParams();

  const fetchProjectDetails = async () => {
    setProjectDetailsLoading(true);
    const res: any = await getProjectDetailsByName(projectName!, region!);
    if (res.status === 200 && res.data.status === "ok") {
      setProjectDetails(res.data.project);
    }
    setProjectDetailsLoading(false);
  };

  const fetchProjectSubdomains = async () => {
    setProjectSubdomainsLoading(true);
    if (projectDetails.id) {
      const res: any = await getProjectSubdomains(projectDetails.id, projectDetails.projectEnvs[0].id);
      if (res.status === 200 && res.data.status === "ok") {
        setProjectSubdomains(res.data.list);
      }
    }
    setProjectSubdomainsLoading(false);
  };

  const fetchFrontend = async () => {
    const res: any = await getFrontendByProjectId(projectDetails.id, projectDetails.projectEnvs[0].id);
    if (res.data && res.data.status === "ok") {
      if (res.data.totalCount === 0) {
        setProjectFrontend(null);
        return;
      }

      setProjectFrontend(res.data.list[0]);
    }
  };

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    fetchProjectSubdomains();
  }, [projectDetails.id]);

  useEffect(() => {
    fetchFrontend();
  }, [projectDetails]);

  useEffect(() => {
    if (iframeWrapperRef.current) {
      const wrapperHeight = iframeWrapperRef.current.offsetHeight;
      const wrapperWidth = iframeWrapperRef.current.offsetWidth;
      setIframeRatio(wrapperHeight / wrapperWidth);
    }
  }, [iframeWrapperRef.current]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (iframeWrapperRef.current) {
        const wrapperHeight = iframeWrapperRef.current.offsetHeight;
        const wrapperWidth = iframeWrapperRef.current.offsetWidth;
        setIframeRatio(wrapperWidth / wrapperHeight);
      }
    });

    if (iframeWrapperRef.current) {
      observer.observe(iframeWrapperRef.current);

      // return () => {
      //   observer.unobserve(iframeWrapperRef.current);
      // }
    }
  }, [iframeWrapperRef.current]);

  useEffect(() => {
    document.title = "Genezio | New Project Congrats";
  }, []);

  return (
    <Row className="justify-content-center p-3">
      <Col sm={12} className="col-12 p-0">
        <Card className="align-items-center">
          <Card.Body className="d-flex align-items-center justify-content-center col-lg-8">
            <Row className="py-3 w-100">
              <Col className="d-flex justify-content-center flex-column" sm={12} lg={7}>
                <h2 className="card-header card-title px-0 pb-0 mb-2 bg-transparent">Congrats!</h2>
                <p>Your project is deployed!</p>
              </Col>
              <Col className="d-flex align-items-center justify-content-lg-end justify-content-sm-start" sm={12} lg={5}>
                <Button
                  onClick={() => handleLinkOpen(`/project/${projectDetails.id}/${projectDetails.projectEnvs[0].id}`)}
                  className="env-vars-btn"
                >
                  Go to Project
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col className="p-0 justify-content-center" sm={8}>
        <Card className="">
          <Card.Body>
            {projectDetailsLoading || projectSubdomainsLoading ? (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <Spinner variant="primary" animation="border" />
                </Col>
              </Row>
            ) : (
              <Row className="d-flex algin-items-center justify-content-between mx-1" style={{ alignItems: "center" }}>
                <Col
                  style={{ height: "18em" }}
                  className="rounded d-flex align-items-center justify-content-center p-0"
                  lg={5}
                  ref={iframeWrapperRef}
                >
                  {!projectFrontend ? (
                    <h3 className="mb-0">No Frontend</h3>
                  ) : (
                    <div
                      className="p-0 m-0 wrapper-iframe"
                      style={{
                        transform: "scale(0.22)",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          `https://${projectSubdomains.length && projectSubdomains[0].genezioDomain}.${
                            process.env.REACT_APP_BASE_URL_FRONTEND_DOMAIN
                          }`,
                          "_blank",
                        )
                      }
                    >
                      <iframe
                        title="frontend"
                        style={{
                          height: "81em",
                          borderRadius: "25px",
                          aspectRatio: iframeRatio.toString(),
                          pointerEvents: "none",
                        }}
                        src={`https://${projectSubdomains.length && projectSubdomains[0].genezioDomain}.${
                          process.env.REACT_APP_BASE_URL_FRONTEND_DOMAIN
                        }`}
                      />

                      <div className="overlay">
                        <div className="text-iframe-wrapper">Open Frontend</div>
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg={6} className="p-0 mt-sm-3 mt-lg-0">
                  <p style={{ fontSize: "16px", fontWeight: "500" }} className="mb-2">
                    Next Steps
                  </p>
                  <p className="mb-2">Go to {backendName} GitHub Repository</p>
                  <Button
                    onClick={() => handleLinkOpen(`https://github.com/${backendOwner}/${backendName}`)}
                    className="w-100 mb-3 env-vars-btn"
                  >
                    Github
                  </Button>

                  {frontendName && (
                    <>
                      <p className="mb-2">Go to {frontendName} GitHub Repository</p>
                      <Button
                        onClick={() => handleLinkOpen(`https://github.com/${frontendOwner}/${frontendName}`)}
                        className="w-100 mb-3 env-vars-btn"
                      >
                        Github
                      </Button>
                    </>
                  )}

                  {backendName && frontendName ? (
                    ""
                  ) : (
                    <>
                      <p className="mb-2">Test Your newly deployed backend</p>
                      <Button
                        onClick={() =>
                          handleLinkOpen(`/test-interface/${projectDetails.id}/${projectDetails.projectEnvs[0].id}`)
                        }
                        className="w-100 py-1"
                      >
                        Test Interface
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Congrats;
