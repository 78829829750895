import { useState, useEffect } from "react";
import { Row, Col, Card, Spinner, Button, Alert } from "react-bootstrap";
import { getInvitationDetails, acceptCollaborationInvitation, getProjectsForUser } from "../../network/ApiAxios";
import { InvitationDetails } from "../../models/CollaborationModels";
import { useParams, useNavigate } from "react-router-dom";

const CollaborationInvitation = () => {
  const [invitationDetails, setInvitationDetails] = useState<InvitationDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [declineLoading, setDeclineLoading] = useState<boolean>(false);
  const [duplicateProjectName, setDuplicateProjectName] = useState<boolean>(false);
  const [duplicateProjectId, setDuplicateProjectId] = useState<string>("");
  const [duplicateProjectEnvId, setDuplicateProjectEnvId] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [acceptInvitationError, setAcceptInvitationError] = useState("");

  const { invitationId } = useParams<{ invitationId: any }>();

  const navigate = useNavigate();

  const fetchInvitationDetails = async () => {
    setLoading(true);
    const resProjects: any = await getProjectsForUser(0, Number.MAX_SAFE_INTEGER);
    const res: any = await getInvitationDetails(invitationId);
    if (res.status === 200 && res.data.status === "ok") {
      const projects = resProjects.data?.projects;
      if (projects) {
        for (let i = 0; i < projects.length; i++) {
          if (projects[i].name === res.data.details.project) {
            setDuplicateProjectId(projects[i].id);
            setDuplicateProjectEnvId(projects[i].projectEnvs[0].id);
            setDuplicateProjectName(true);
            break;
          }
        }
      }
      setInvitationDetails(res.data.details);
      setLoading(false);
      return;
    }
    setError(true);
    setLoading(false);
  };

  const handleAccept = async (type: boolean) => {
    type ? setAcceptLoading(true) : setDeclineLoading(true);
    const res: any = await acceptCollaborationInvitation(invitationId, type);
    if (res.status === 200 && res.data.status === "ok") {
      type
        ? navigate(`/project/${invitationDetails?.projectId}/${invitationDetails?.environmentId}/backend`)
        : navigate("/dashboard");
      setAcceptLoading(false);
      return;
    }

    setAcceptInvitationError(res.response.data.error.message);
    type ? setAcceptLoading(false) : setDeclineLoading(false);
  };

  const signOut = () => {
    localStorage.clear();
    navigate(`/auth/login?invitationId=${invitationId}`);
  };

  useEffect(() => {
    fetchInvitationDetails();
  }, []);

  return (
    <Row>
      <Col sm={12}>
        <Card>
          {acceptInvitationError && (
            <Card.Header>
              <Alert variant="danger" className="w-100">
                {acceptInvitationError}
              </Alert>
            </Card.Header>
          )}
          <Card.Body
            className="pt-0 d-flex align-items-center justify-content-center flex-column"
            style={{ height: "85vh" }}
          >
            {loading ? (
              <Spinner variant="primary" animation="border" />
            ) : error ? (
              <div className="d-flex flex-column text-center">
                <i className="icon ion-ios-alert tx-40 tx-warning lh-1"></i>
                <h4>Invitation not found</h4>
                <p className="text-muted">We are not able to find this invitation.</p>
                <div onClick={() => signOut()} className="text-primary " style={{ cursor: "pointer" }}>
                  <i className="far fa-arrow-alt-circle-left"></i> Switch account
                </div>
              </div>
            ) : duplicateProjectName ? (
              <div className="d-flex flex-column text-center">
                <i className="icon ion-ios-alert tx-40 tx-warning lh-1"></i>
                <h4 className="mt-3">You already have a project named {invitationDetails?.project}!</h4>
                <div className="mt-3 mb-3" style={{ fontSize: "20px" }}>
                  To be able to accept this invitation, please rename or delete your existing project.
                  <br />
                  Once you've renamed your project, refresh this page or use the invitation link sent to your email to
                  accept it.
                </div>
                <p className="text-muted">Project names are unique throughout collaborators.</p>
                <a href={`/project/${duplicateProjectId}/${duplicateProjectEnvId}`} target="_blank" rel="noreferrer">
                  <div className="text-primary " style={{ cursor: "pointer" }}>
                    <i className="far fa-arrow-alt-circle-left"></i> Manage projects
                  </div>
                </a>
              </div>
            ) : (
              <div className="d-flex flex-column">
                <h4>
                  You have been invited by {invitationDetails?.owner} to collaborate on {invitationDetails?.project}!
                </h4>
                <p className="text-center">Your role will be: {invitationDetails?.role}</p>
                <p className="text-center">
                  To see the project, please accept the invite. In case this is a <br /> mistake, feel free to decline
                  it.
                </p>

                <div className="d-flex justify-content-center align-items-center">
                  <Button disabled={acceptLoading} onClick={() => handleAccept(true)} className="mx-3 px-4">
                    Accept
                    {acceptLoading && <Spinner className="ms-2" animation="border" size="sm" />}
                  </Button>

                  <Button
                    disabled={declineLoading}
                    onClick={() => handleAccept(false)}
                    className="border mx-3 px-4"
                    variant=""
                  >
                    Decline
                    {declineLoading && <Spinner className="ms-2" animation="border" size="sm" />}
                  </Button>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CollaborationInvitation;
