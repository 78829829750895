import axios from "axios";
import { Alert, Card, Col, Container, Form, Row, Spinner, Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { getUserInformation } from "../network/ApiAxios";
import { ToastContainer } from "react-toastify";
import { getGoogleClickId, getUTMSignupSource } from "../utils";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function DeployRepoOnGenezio() {
  const urlParams = new URLSearchParams(window.location.search);
  const repository = urlParams.get("repository") || "";
  const projectName = urlParams.get("name") || "";
  const s3URL = urlParams.get("s3url") || "";

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorLogin, setErrorLogin] = React.useState("");
  const [invalidRepoModal, setInvalidRepoModal] = React.useState(false);

  useEffect(() => {
    checkRepo();
  }, []);

  const handleLoginGoogle = (googleData: CredentialResponse) => {
    const options = {};

    setIsLoading(true);
    axios
      .post(
        API_BASE_URL + "/users/google-auth",
        {
          googleToken: googleData.credential,
          signupSource: getUTMSignupSource(urlParams),
          googleClickId: getGoogleClickId(urlParams),
        },
        options,
      )
      .then(async (res) => {
        const { status, token, user } = res.data;
        if (status === "ok") {
          localStorage.setItem("apiToken", token);
          localStorage.setItem("user", JSON.stringify(user));

          const userId: string = (await getUserInformation()).data?.user?.id || "";
          // @ts-ignore
          window.dataLayer = window.dataLayer || [];
          // @ts-ignore
          window.dataLayer.push({
            userId: userId,
            event: "userIdSet",
          });
          if (s3URL) {
            window.location.href = `/import-external-project?${window.location.search.substring(1)}`;
          } else {
            // get all url search params and redirect to /fork?...
            window.location.href = `/fork?${window.location.search.substring(1)}`;
          }
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.error(error);
        setErrorLogin("There was an error logging in. Please try again.");
      });
  };

  const checkRepo = async () => {
    if (s3URL) {
      if (!projectName) {
        alert("Project name is missing");
      }
    } else {
      const repoPattern = /github\.com\/([^/]+)\/([^/]+)/;
      const match = repository !== null && repository.match(repoPattern);

      if (!match) {
        setInvalidRepoModal(true);
        return;
      }

      try {
        const response = await axios.get(`https://api.github.com/repos/${match[1]}/${match[2]}`);
        if (response.status === 200) {
          setInvalidRepoModal(false);
        }
      } catch (error) {
        setInvalidRepoModal(true);
      }
    }
  };

  const apiToken = localStorage.getItem("apiToken");
  console.log("s3URL", s3URL);
  if (apiToken) {
    if (s3URL) {
      window.location.href = `/import-external-project?${window.location.search.substring(1)}`;
    } else {
      // get all url search params and redirect to /fork?...
      window.location.href = `/fork?${window.location.search.substring(1)}`;
    }
    return null;
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {isLoading && (
        <div className="modal-spinner-wrapper">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body text-center">
                <Spinner animation="grow" variant="light" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Invalid github modal start */}
      <Modal show={invalidRepoModal} className="smaller-modal-width" centered>
        <Modal.Body style={{ padding: "40px" }}>
          <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
            Invalid GitHub URL
          </h3>
          <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />
          <Alert variant="danger">
            This GitHub URL is invalid. Please enter a valid repository URL to proceed with deployment using Genezio.
          </Alert>
          <hr style={{ borderColor: "#E5E5E5" }} />
        </Modal.Body>
      </Modal>
      {/* Invalid github modal end */}

      <Container fluid className="text-light deploy-repo-container" style={{ minHeight: "100vh" }}>
        <Row className="justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
          <Col md={7} className="text-center">
            <h1 className="mb-2 mt-sm-5 mt-0 text-center">
              Welcome to{" "}
              <img
                src={require("../assets/img/brand/logo-white.svg").default}
                className="ms-2 me-2"
                alt="logo"
                width={200}
              />
              !
            </h1>
            <p className="lead mb-5 grey-light ms-34px deploy-repo-subtitle">
              Deploy your app in less than 2 minutes for free.
            </p>
            <Card className="text-dark mt-5 p-5">
              <Card.Body>
                <Row className="justify-content-center">
                  <Col md={8} className="p-4" style={{ border: "1px solid #7C7C91", borderRadius: "10px" }}>
                    <div className="main-signup-header">
                      <h3 className="text-center mb-5 pb-2 text-light" style={{ fontSize: 30 }}>
                        Connect your Account
                      </h3>
                      <div className="panel panel-primary mt-5">
                        <div className=" tab-menu-heading mb-2 border-bottom-0">
                          <div className="tabs-menu1">
                            {errorLogin && <Alert variant="danger">{errorLogin}</Alert>}
                            <Form>
                              <div
                                className="row"
                                style={{
                                  justifyContent: "center",
                                }}
                              >
                                <div className="d-flex justify-content-center col-lg-6" id="google_login_wrapper_btn">
                                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                                    <GoogleLogin
                                      shape="pill"
                                      size="medium"
                                      text="signup_with"
                                      // width expressed in px
                                      // do not change, otherwise it won't match GitHub button width
                                      width={217}
                                      onSuccess={(credentialResponse: CredentialResponse) => {
                                        handleLoginGoogle(credentialResponse);
                                      }}
                                      onError={() => {
                                        console.log("Login Failed");
                                      }}
                                    />
                                  </GoogleOAuthProvider>
                                </div>
                                <div className="d-flex justify-content-center col-lg-12 my-2"></div>
                                {/* login github button */}
                                <div className="d-flex justify-content-center col-lg-7 p-0">
                                  <a
                                    href={`https://github.com/login/oauth/authorize?client_id=${
                                      process.env.REACT_APP_GITHUB_CLIENT_ID
                                    }&scope=user&redirect_uri=${encodeURIComponent(
                                      `${
                                        process.env.REACT_APP_BASE_URL
                                      }/auth/github-redirect?${window.location.search.substring(1)}`,
                                    )}`}
                                    className="btn login-btn-github bg-light"
                                    type="button"
                                  >
                                    <i className="fab fa-github"></i>

                                    <span>Sign up with GitHub</span>
                                  </a>
                                </div>
                              </div>
                              <div
                                className="mt-4 text-center justify-content-center mb-2"
                                style={{
                                  color: "white",
                                  fontSize: 12,
                                }}
                              >
                                By signing up you agree with our{" "}
                                <a
                                  style={{
                                    color: "#036DB5",
                                  }}
                                  href="https://genezio.com/eula/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  Terms & Conditions.
                                </a>{" "}
                                <br></br>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
