export const PROJECT_TABLE_COLUMNS: {
  header: string;
  accessorKey: string;
}[] = [
  {
    header: "ID",
    accessorKey: "PurchaseDate",
  },
  {
    header: "Project Name",
    accessorKey: "name",
  },
  {
    header: "Last Edit Date",
    accessorKey: "ProductID",
  },
  {
    header: "Created Date",
    accessorKey: "Product",
  },
  {
    header: " ",
    accessorKey: " ",
  },
];
