export const CreatableSelectConfig = {
  DropdownIndicator: null,
};

export const createOption = (label: string) => ({
  label,
  value: label,
});

// TODO: replace any
export const formatCollaboratorsValues = (collaborators: any, role: any) => {
  const values = [];

  for (var i = 0; i < collaborators.length; i++) {
    const collaborator = collaborators[i];
    values.push({
      email: collaborator.value,
      role: role,
    });
  }

  return {
    collaborators: values,
  };
};

export const capitalizeWord = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const validateEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};

export const findDuplicateCollaborators = (projectCollaborators: any, collaborators: any) => {
  const duplicates: any = [];
  collaborators.forEach((collaborator: any) => {
    const isDuplicate = projectCollaborators.some((projCollab: any) => projCollab.email === collaborator.value);
    if (isDuplicate) {
      duplicates?.push(collaborator.value);
    }
  });
  return duplicates;
};
