import { useEffect, useState } from "react";
import { Card, Row, Col, Button, Spinner, FormGroup, Alert } from "react-bootstrap";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import IntegrationBreadcrumbs from "./IntegrationBreadcrumbs";
import AddNeonProjectModal from "../../components/AdvancedUI/Modals/AddNeonProjectModal";
import DeactivateIntegrationModal from "../../components/AdvancedUI/Modals/DeactivateIntegrationModal";
import DeleteIntegrationModal from "../../components/AdvancedUI/Modals/DeleteIntegrationModal";
import DatabaseConnect from "./DatabaseConnect";
import useProjectDetails from "../../hooks/useProjectDetails";
import useCurrentIntegration from "../../hooks/useCurrentIntegration";
import {
  checkIntegrationUser,
  getNeonProjects,
  getNeonProjectDetails,
  addProjectIntegration,
  deactivateIntegration,
  getProjectIntegrations,
  getIntegrationEnvironmentVariablesList,
  disconnectIntegration,
  connectExternalOAuth,
  getCurrentCollaboratorDetails,
} from "../../network/ApiAxios";
import { CollaboratorDetails } from "../../models/CollaborationModels";
import { getCodeSnippetsOptions, NeonOauthConnectionUrl } from "./utils";

const neonEnum = { projectId: "", envId: "" };

const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    color: "white", // Set placeholder color to white
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918",
    border: "none",
    color: "white",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918", // Set background color of the options parent
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4a4a4a" : "#090918",
    color: "white", // Set text color
    ":hover": {
      backgroundColor: "#4a4a4a", // Change background color on hover
    },
  }),
};
const NeonIntegration = () => {
  const darkMode = localStorage.getItem("darkMode") === "true";

  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  const { project, activeEnv } = useProjectDetails(projectId || "", envId || "");
  neonEnum.projectId = projectId || neonEnum.projectId;
  neonEnum.envId = envId || neonEnum.envId;
  const { loading, integration } = useCurrentIntegration("NEON-POSTGRES");
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addProjectModal, setAddProjectModal] = useState<boolean>(false);
  const [neonProjects, setNeonProjects] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>("");
  const [disconnectModal, setDisconnectModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editProject, setEditProject] = useState<boolean>(false);
  const [editProjectLoading, setEditProjectLoading] = useState<boolean>(false);
  const [projectIntegrations, setProjectIntegrations] = useState<any>([]);
  const [projectDetails, setProjectDetails] = useState<any>({});
  const [projectDetailsLoading, setProjectDetailsLoading] = useState<boolean>(false);
  const [integrationEnv, setIntegrationEnv] = useState<any>([]);
  const [data, setData] = useState<any>({
    selectedBranch: "",
    selectedDatabase: "",
    selectedRole: "",
  });
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const isInstalled = projectIntegrations.includes(integration?.name) ? true : false;

  const navigate = useNavigate();
  if (currentCollaboratorDetails.role === "collaborator" && activeEnv.name === "prod") {
    navigate(`/project/${projectId}/${envId}/integrations`);
  }

  const checkIfUserIsConnected = async () => {
    const res = await checkIntegrationUser("neon");
    if (res?.data?.connection_status) {
      setIsConnected(true);
      await fetchProjects();

      if (activeStep < 2) {
        setActiveStep(2);
      }
    } else {
      // reset all states on user disconnect
      setActiveStep(1);
      setIsConnected(false);
      resetInputsStates();
    }

    const response: any = await getProjectIntegrations(projectId || "", envId || "");
    // if integration is active
    if (isConnected && response.data.integrations.includes(integration?.name) && !editProject) {
      setActiveStep(4);
    }
  };

  // resets inputs values
  const resetInputsStates = () => {
    setSelectedProject("");
    setData({
      selectedBranch: "",
      selectedDatabase: "",
      selectedRole: "",
    });
  };

  // Select project handler
  const handleProjectSelect = (e: any) => {
    const { addProject } = e;
    setActiveStep(2);

    // Add Project case
    if (addProject) {
      setAddProjectModal(true);
      setSelectedProject("");
      return;
    }

    setSelectedProject(e.value);
  };

  // handle user integration disconnect
  const handleDisconnect = async () => {
    setIsLoading(true);
    setActiveStep(1);
    setIsConnected(false);
    setEditProject(false);
    await disconnectIntegration("neon");
    if (isInstalled) {
      await deactivateIntegration(projectId ?? "", envId ?? "", "NEON-POSTGRES");
    }
    await fetchActiveIntegrations();
    setIsLoading(false);
  };

  const fetchProjects = async () => {
    const res = await getNeonProjects();

    setProjectsLoading(true);
    if (res.data?.status === "ok" && res.data.projects) {
      setNeonProjects(res.data.projects);
    }
    setProjectsLoading(false);
  };

  const fetchProjectDetails = async () => {
    setProjectDetailsLoading(true);
    const res: any = await getNeonProjectDetails(selectedProject?.id);
    setProjectDetailsLoading(false);

    if (res.status === 200 && res.data?.status === "ok") {
      setActiveStep(3);
      setProjectDetails(res.data.neon_project_details);
    }
  };

  const toggleIntegration = async () => {
    // Delete case
    if (isInstalled) {
      setDeleteModal(true);
      return;
    }

    // Install case
    setIsLoading(true);
    const { selectedBranch, selectedRole, selectedDatabase } = data;
    const { project_id, details } = projectDetails;

    await addProjectIntegration(neonEnum.projectId, neonEnum.envId, {
      integrationName: "NEON-POSTGRES",
      envVars: [
        project_id,
        selectedBranch.id,
        details.endpoints.endpoints[0].host,
        selectedDatabase.name,
        selectedRole.name,
      ],
    });

    toast.success("Neon Integration Added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsLoading(false);

    await fetchActiveIntegrations();
  };

  const deleteIntegrationModalClose = async () => {
    setDeleteModal(false);
    resetInputsStates();
    await fetchActiveIntegrations();
  };

  const fetchActiveIntegrations = async () => {
    const res: any = await getProjectIntegrations(projectId || "", envId || "");
    if (res?.status === 200) {
      setProjectIntegrations(res?.data?.integrations);
    }
  };

  // handle neon project edit case
  const handleProjectEdit = () => {
    resetInputsStates();
    setActiveStep(2);
    setEditProject(true);
  };

  const handleIntegrationEditSubmit = async () => {
    setEditProjectLoading(true);
    const { selectedBranch, selectedRole, selectedDatabase } = data;
    const { project_id, details } = projectDetails;
    await addProjectIntegration(neonEnum.projectId, neonEnum.envId, {
      integrationName: "NEON-POSTGRES",
      envVars: [
        project_id,
        selectedBranch.id,
        details.endpoints.endpoints[0].host,
        selectedDatabase.name,
        selectedRole.name,
      ],
    });
    setEditProjectLoading(false);

    setActiveStep(4);
    setEditProject(false);
  };

  // set project input default value on project create
  const handleProjectSelectDefault = (project: any) => {
    setSelectedProject(project);
  };

  const fetchIntegrationEnvs = async () => {
    const res: any = await getIntegrationEnvironmentVariablesList(projectId || "", envId || "", "neon");
    if (res?.status === 200) {
      setIntegrationEnv(res.data.environmentVariables);
    }
  };

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  // fetch neon projects & active integrations
  useEffect(() => {
    fetchProjects();
    fetchActiveIntegrations();
  }, []);

  // check if user is connected
  useEffect(() => {
    checkIfUserIsConnected();
  }, [isConnected, isInstalled, activeStep]);

  useEffect(() => {
    fetchIntegrationEnvs();
  }, [isInstalled]);

  useEffect(() => {
    if (!isConnected) {
      const intervalId = setInterval(checkIfUserIsConnected, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [activeStep, isConnected]);

  // Content for each step
  const steps = [
    {
      title: "Connect to Neon",
      content: () => {
        return (
          <>
            {isConnected ? (
              <>
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"12px"} fill={"#28A745"}>
                    <path d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z" />
                  </svg>
                  <p className="mb-0 mx-1">Connected successfully</p>
                </div>
                <Button
                  disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                  variant="outline-danger"
                  onClick={() => setDisconnectModal(true)}
                  className="my-3"
                  size="sm"
                >
                  Disconnect
                </Button>
              </>
            ) : (
              <Button
                disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                onClick={() => connectExternalOAuth(NeonOauthConnectionUrl)}
                className="mb-3"
                size="sm"
              >
                Connect
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "Select your Neon project",
      content: () => {
        return (
          <>
            {activeStep > 1 && (
              <>
                {isInstalled && !editProject ? (
                  <>
                    <div className="d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"12px"} fill={"#28A745"}>
                        <path d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z" />
                      </svg>
                      <p className="mb-0 mx-1">Your Project is successfully selected</p>
                    </div>
                    <Button
                      disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                      onClick={() => handleProjectEdit()}
                      className="my-3"
                      size="sm"
                    >
                      Edit
                    </Button>
                  </>
                ) : (
                  <>
                    <p style={{ fontWeight: "500" }} className="mb-1">
                      Projects
                    </p>
                    <Select
                      styles={darkMode ? customStyles : undefined}
                      isDisabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                      value={
                        selectedProject.name && {
                          label: selectedProject.name,
                          value: selectedProject.value,
                        }
                      }
                      options={[
                        // Default value to add new project
                        {
                          label: "+ Add New Project",
                          value: "addNewProject",
                          addProject: true,
                        },
                        // List stored databases
                        ...neonProjects.map((project: any) => ({
                          label: project.project.name,
                          value: project.project,
                        })),
                        // Show loading spinner if we are sending fetch databases
                        ...(projectsLoading
                          ? [
                              {
                                label: <Spinner variant="primary" animation="border" size="sm" />,
                                value: "loading",
                                isDisabled: true,
                              },
                            ]
                          : []),
                      ]}
                      onChange={handleProjectSelect}
                    />

                    <Button
                      onClick={() => fetchProjectDetails()}
                      disabled={!selectedProject ? true : false || projectDetailsLoading || (activeStep > 2 && true)}
                      className="my-3"
                      size="sm"
                    >
                      Continue
                      {projectDetailsLoading && <Spinner className="ms-2" animation="border" size="sm" />}
                    </Button>

                    {editProject && (
                      <Button
                        onClick={() => {
                          setActiveStep(4);
                          setEditProject(false);
                        }}
                        className="my-3 mx-2"
                        size="sm"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Set project details",
      content: () => {
        return (
          <>
            {!projectDetails && activeStep > 2 && (
              <Spinner className="ms-2" animation="border" variant="primary" size="sm" />
            )}

            {activeStep > 2 && (
              <>
                {isInstalled && !editProject ? (
                  <div className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"12px"} fill={"#28A745"}>
                      <path d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z" />
                    </svg>
                    <p className="mb-0 mx-1">Your Project is successfully selected</p>
                  </div>
                ) : (
                  <>
                    {projectDetails?.details && (
                      <>
                        <p style={{ fontWeight: "500" }} className="mb-1">
                          Branch
                        </p>
                        <FormGroup className="form-group">
                          <Select
                            isDisabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                            options={[
                              ...projectDetails?.details?.branches?.map((branch: any) => ({
                                label: branch.name,
                                value: branch,
                              })),
                            ]}
                            placeholder="Branch"
                            classNamePrefix="selectform"
                            onChange={(e) => {
                              setData((prevData: any) => ({
                                ...prevData,
                                selectedBranch: e.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </>
                    )}

                    {data?.selectedBranch?.databases?.databases && (
                      <>
                        <p style={{ fontWeight: "500" }} className="mb-1">
                          Database
                        </p>
                        <FormGroup className="form-group">
                          <Select
                            isDisabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                            options={[
                              ...data?.selectedBranch?.databases?.databases?.map((database: any) => ({
                                label: database.name,
                                value: database,
                              })),
                            ]}
                            placeholder="Database"
                            classNamePrefix="selectform"
                            onChange={(e) => {
                              setData((prevData: any) => ({
                                ...prevData,
                                selectedDatabase: e.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </>
                    )}
                    {data?.selectedBranch?.databases?.databases && (
                      <>
                        <p style={{ fontWeight: "500" }} className="mb-1">
                          Role
                        </p>
                        <FormGroup className="form-group">
                          <Select
                            isDisabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                            options={[
                              ...data?.selectedBranch?.roles?.roles?.map((role: any) => ({
                                label: role.name,
                                value: role,
                              })),
                            ]}
                            placeholder="Role"
                            classNamePrefix="selectform"
                            onChange={(e) => {
                              setData((prevData: any) => ({
                                ...prevData,
                                selectedRole: e.value,
                              }));
                            }}
                          />
                        </FormGroup>
                        <Button
                          disabled={
                            editProjectLoading ||
                            !data.selectedBranch ||
                            !data.selectedDatabase ||
                            !data.selectedRole ||
                            (activeStep > 3 && true)
                          }
                          onClick={() => (editProject ? handleIntegrationEditSubmit() : setActiveStep(4))}
                          className="mb-3"
                          size="sm"
                        >
                          {editProject ? "Save" : "Continue"}
                          {editProjectLoading && <Spinner className="ms-2" animation="border" size="sm" />}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Secrets",
      content: () => {
        return (
          <>
            {activeStep > 3 && (
              <>
                <p className="text-muted">
                  <span style={{ fontWeight: "500" }}>
                    {isInstalled && integrationEnv !== null
                      ? "The secret below is set as environment variable in this project:"
                      : "The secret below will be set as environment variable in this project:"}
                  </span>
                </p>
                <Row>
                  <p className="mb-1">NEON_POSTGRES_URL</p>
                </Row>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Breadcrumb navigation */}

      <ToastContainer />

      <DeactivateIntegrationModal
        integrationName="Neon"
        show={disconnectModal}
        onDeleteIntegrationClose={() => {
          setDisconnectModal(false);
        }}
        handleDisconnect={handleDisconnect}
      />

      <DeleteIntegrationModal
        projectId={projectId}
        envId={envId}
        show={deleteModal}
        integrationName={"NEON-POSTGRES"}
        setActiveStep={() => setActiveStep(2)}
        resetSelectedDatabase={() => setData({})}
        onDeleteIntegrationClose={() => deleteIntegrationModalClose()}
      />

      <AddNeonProjectModal
        show={addProjectModal}
        handleProjectSelectDefault={handleProjectSelectDefault}
        hideModal={() => {
          setAddProjectModal(false);
          fetchProjects();
        }}
      />

      {/* Integrations Variables Card Content */}
      <Row className="mt-3">
        <Col sm={12}>
          {currentCollaboratorDetails.role === "collaborator" ? (
            <Alert variant="warning text-muted">You are unable to edit integrations as a collaborator</Alert>
          ) : (
            ""
          )}
          <Card style={{ padding: "0 0 59px 14px", minHeight: "75vh" }}>
            <Card.Body className="pt-0">
              {loading || isLoading ? (
                <Row className="my-5">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner variant="primary" animation="border" />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <h4 className="card-header card-title px-0 pb-0">{`${
                      isInstalled ? "MANAGE" : "INSTALL"
                    } ${integration?.name} INTEGRATION`}</h4>
                    <p className="text-muted my-1" style={{ fontSize: "12px" }}>
                      {integration?.description}
                    </p>

                    <a
                      href={"https://console.neon.tech/app/projects"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary text-decoration-underline"
                    >
                      Neon Console
                    </a>

                    {steps.map((step, index) => (
                      <div key={step.title} className="d-flex align-items-center pos-relative">
                        <div>
                          <div className="d-flex align-items-center">
                            <div
                              className={`step-number my-2 ${
                                activeStep > index + 1 || activeStep === index + 1 ? "step-number-success" : ""
                              }`}
                            >
                              {index + 1}
                            </div>
                            <h6 className="mb-0 mx-3">{step.title}</h6>
                          </div>
                          <div className="w-100 mx-5">{step.content()}</div>
                        </div>

                        {/* Connectors */}
                        {index < steps.length - 1 && (
                          <div className="connector ">
                            <div className={`line ${activeStep > index + 1 ? "line-success" : ""}`} />
                          </div>
                        )}
                      </div>
                    ))}

                    {/* TODO: fix delete integration active step bug */}
                    {activeStep === 4 && (
                      <Button
                        className="mx-5"
                        size="sm"
                        variant={isInstalled ? "outline-danger" : "primary"}
                        onClick={() => toggleIntegration()}
                        disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                      >
                        {isInstalled ? "Delete" : "Save"}
                      </Button>
                    )}
                  </Col>

                  <Col md={7} sm={12} className="m-3">
                    {/* Code snippet for connecting to db component */}
                    <DatabaseConnect
                      connectionType={"database"}
                      secondaryText={"Use this code after you complete all the steps."}
                      integrationEnv={integrationEnv}
                      options={getCodeSnippetsOptions("neon")}
                      integrationName="neon"
                    />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NeonIntegration;
