import { Button, Card, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import "./GettingStarted.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../Tooltips/InfoTooltip";
import { getSmartTemplates } from "../../network/ApiAxios";
import capybara from "../../assets/img/getting-started/capybara.svg";

const getStartedItems = [
  // Templates
  {
    title: "Express.js App",
    description: "Cut deployment and server costs by up to 6x.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js Fullstack App",
    description: "Simple Express.js fullstack template to get started.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-fullstack-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js & Postgresql",
    description: "Simple Crud App template to get started.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-postgresql-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js & MongoDB",
    description: "Simple Crud App template to get started.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-mongo-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js & Auth Postgresql",
    description: "Simple Auth App template with Email&Password",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-postgres-auth-starter",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js & Auth MongoDB",
    description: "Simple Auth App template with Email&Password",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-mongo-auth-starter",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js & Typescript",
    description: "Simple Express.js Typescript template to get started.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-typescript-getting-started",
    soon: false,
    modalName: "",
  },

  // Getting Started
  {
    title: "Serverless Function",
    description: "4x faster responses, no cloud configurations needed.",
    image: require("../../assets/img/getting-started/lambda.png"),
    href: "/faas-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Fastify Getting Started",
    description: "Simple Fastify template to get started.",
    image: require("../../assets/img/getting-started/fastify.svg").default,
    href: "/fastify-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Next.js App",
    description: "Eliminate unpredictable costs and cold start times.",
    image: require("../../assets/img/getting-started/nextjs.png"),
    href: "/nextjs-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "NitroJS Getting Started",
    description: "Simple NitroJS template to get started.",
    image: require("../../assets/img/getting-started/nitrojs.svg").default,
    href: "/nitro-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Nuxt Getting Started",
    description: "Simple Nuxt template to get started.",
    image: require("../../assets/img/getting-started/nuxt.png"),
    href: "/nuxt-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Python",
    description: "Simple Python template to get started.",
    image: require("../../assets/img/getting-started/python.png"),
    href: "/faas-python-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Flask",
    description: "Simple Flask template to get started.",
    image: require("../../assets/img/getting-started/flask.png"),
    href: "/flask-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Django",
    description: "Simple Django template to get started.",
    image: require("../../assets/img/getting-started/django.png"),
    href: "/django-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "FastAPI",
    description: "Simple FastAPI template to get started.",
    image: require("../../assets/img/getting-started/fastapi.png"),
    href: "/fastapi-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "React App Template",
    description: "Simple React template to get started.",
    image: require("../../assets/img/getting-started/react.png"),
    href: "/react-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Angular Getting Started",
    description: "Simple Angular template to get started.",
    image: require("../../assets/img/getting-started/angular.png"),
    href: "/angular-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Svelte Getting Started",
    description: "Simple Svelte template to get started.",
    image: require("../../assets/img/getting-started/svelte.svg").default,
    href: "/svelte-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Vue Getting Started",
    description: "Simple Vue template to get started.",
    image: require("../../assets/img/getting-started/vue.png"),
    href: "/vue-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "HTML Starter",
    description: "Simple HTML template to get started.",
    image: require("../../assets/img/getting-started/html.png"),
    href: "/html-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Typesafe Project",
    description: "Starter template for full-stack TypeScript projects.",
    image: require("../../assets/img/brand/icon-black.svg").default,
    href: "/typesafe-getting-started",
    soon: false,
    modalName: "",
  },
];

const comingSoon = [
  {
    title: "Go",
    description: "Join the waitlist to be the first to try!",
    image: require("../../assets/img/getting-started/go.png"),
    href: "/go",
    soon: true,
    modalName: "go",
  },
  {
    title: "Serverless Containers",
    description: "Join the waitlist to be the first to try!",
    image: require("../../assets/img/getting-started/containers.png"),
    href: "/containers",
    soon: true,
    modalName: "containers",
  },
];

const getStartedTutorials = [
  {
    title: "Task automation through cron jobs",
    image: require("../../assets/img/getting-started/tutorials/cron.png"),
    href: "/cron-getting-started",
  },
  {
    title: "WhatsApp API Chatbot",
    image: require("../../assets/img/getting-started/tutorials/whatsapp.png"),
    href: "/whatsapp-getting-started",
  },
  {
    title: "Starter app on top of ChatGPT",
    image: require("../../assets/img/getting-started/tutorials/gpt.png"),
    href: "/chatgpt-getting-started",
  },
  {
    title: "Live data web scraping app",
    image: require("../../assets/img/getting-started/tutorials/scraping.png"),
    href: "/web-scraper-getting-started",
  },
];

const smartTemplatesDefault = [
  {
    title: "Serverless Function",
    description: "4x faster responses, no cloud configurations needed.",
    image: require("../../assets/img/getting-started/lambda.png"),
    href: "/faas-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js App",
    description: "Cut deployment and server costs by up to 6x.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Next.js App",
    description: "Eliminate unpredictable costs and cold start times.",
    image: require("../../assets/img/getting-started/nextjs.png"),
    href: "/nextjs-getting-started",
    soon: false,
    modalName: "",
  },
];
const GettingStarted = () => {
  const isDevFlag = process.env.REACT_APP_GITHUB_APP_NAME === "genezio-dev";

  const [modal, setModal] = useState("");
  const [showExploreContainer, setShowExploreContainer] = useState(false);
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [smartTemplates, setSmartTemplates] = useState(smartTemplatesDefault);
  const [prompt, setPrompt] = useState("");
  const [showSmartCards, setShowSmartCards] = useState(false);
  const [aiIsLoading, setAiIsLoading] = useState(false);

  useEffect(() => {
    const hasSeenExploreContainer = localStorage.getItem("hasSeenExploreContainer");

    if (!hasSeenExploreContainer || (hasSeenExploreContainer && hasSeenExploreContainer === "false")) {
      // 100% chances for dev environment
      // 10% chances for production environment
      const percentage = isDevFlag ? 1 : 0.1;
      const smartTemplateFlag = Math.random() <= percentage;
      setShowExploreContainer(smartTemplateFlag);
      setShowMainContainer(!smartTemplateFlag);

      if (smartTemplateFlag) {
        localStorage.setItem("hasSeenExploreContainer", "true");
      }
    } else {
      setShowExploreContainer(false);
      setShowMainContainer(true);
    }
  }, []);

  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";

    // Append the script to the body
    document.body.appendChild(script);

    // Function to create the form
    const createForm = () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: "eu1",
          // portalId: modal === "python" ? "144630728" : modal === "go" ? "144630728" : "144630728",
          portalId: "144630728",
          formId:
            modal === "python"
              ? "d90813c0-c889-4bde-88de-b94fc6f01a1f"
              : modal === "go"
                ? "a35151a7-a57b-49d6-a1f6-663158859764"
                : "200f5589-1b86-42d3-ae37-461349e47771",
          target: "#hubspotForm",
          onFormReady: (form: any) => {
            const firstInput = form.querySelector("input") as HTMLInputElement;
            const user: any = localStorage.getItem("user");
            if (firstInput && user) {
              firstInput.value = JSON.parse(user)?.email;
              firstInput.focus();
            }

            const submitButton = form.querySelector('input[type="submit"]');
            if (submitButton) {
              submitButton.style.float = "right";
              submitButton.style.marginLeft = "auto";
              form.style.padding = "20px 0 20px 0";
            }
          },
        });
      }
    };
    // Check if the script is already loaded
    if ((window as any).hbspt) {
      createForm();
    } else {
      script.onload = createForm;
    }
    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, [modal]);

  const handleSend = async () => {
    const allTemplates = [...getStartedItems];
    setAiIsLoading(true);
    await getSmartTemplates(prompt)
      .then((response) => {
        const updatedSelection = allTemplates.filter((item) => response.includes(item.title));

        if (updatedSelection.length !== 3) {
          setSmartTemplates(smartTemplatesDefault);
        } else {
          setSmartTemplates(updatedSelection);
        }
      })
      .catch(() => {
        setSmartTemplates(smartTemplatesDefault);
      });

    setShowSmartCards(true);
    setAiIsLoading(false);
  };

  const handleKeyDown = async (event: { key: string }) => {
    if (event.key === "Enter") {
      await handleSend();
    }
  };

  return (
    <>
      <Modal
        show={modal === "" ? false : true}
        onHide={() => setModal("")}
        className="smaller-modal-width py-3"
        centered
      >
        <Modal.Body style={{ padding: "30px" }}>
          <div className="d-flex justify-content-between">
            <h6 className="mb-3" style={{ fontWeight: "600" }}>
              Join {modal === "go" ? "Go" : modal === "python" ? "Python" : "Serverless Containers"} Waiting List
            </h6>
          </div>
          <div id="hubspotForm"></div>
        </Modal.Body>
      </Modal>

      {showExploreContainer && (
        <Container fluid className="d-flex flex-column align-items-center text-center mt-5">
          <img
            src={capybara}
            alt="Genezio"
            id="genezio-logo"
            className="mb-4"
            style={{ maxWidth: "100px", height: "auto" }}
          />

          <h1 className="mb-3" style={{ fontWeight: "400" }}>
            What would you like to deploy?
          </h1>
          <div style={{ display: "flex", alignItems: "center", maxWidth: "700px", width: "100%" }}>
            <input
              type="text"
              placeholder="Describe your project..."
              className="mb-3 form-control"
              value={prompt}
              style={{
                width: "100%",
              }}
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button
              onClick={() => handleSend()}
              style={{
                cursor: "pointer",
                marginLeft: "0.5rem",
                display: "flex",
                alignItems: "center",
                background: "none",
                border: "none",
                padding: 0,
                paddingBottom: "0.5rem",
              }}
              aria-label="Send"
            >
              <i className="fa fa-paper-plane" style={{ fontSize: "1.2rem", color: "#6c757d" }} />
            </button>
          </div>

          <Row className="w-100 justify-content-center">
            {!aiIsLoading && showSmartCards && (
              <Row className="mt-4 justify-content-center">
                {[...smartTemplates].map((item, index) => (
                  <Col key={index} sm={12} md={4} lg={3} className="d-flex p-2 my-2">
                    <a
                      href={item.href}
                      id={item.title}
                      className="w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        if (item.soon) {
                          setModal(item.modalName);
                        } else if (item.href !== "#") {
                          if (item.href.startsWith("http") || item.href.startsWith("https")) {
                            window.open(item.href, "_blank");
                          } else {
                            navigate(item.href);
                          }
                        }
                      }}
                    >
                      <div className="card-elem-wrapper-template mx-2 p-3 d-flex">
                        <Row className="w-100">
                          <Col className="col-3 d-flex justify-content-center align-items-center px-2">
                            <img src={item.image} alt="Genezio Logo" className="img-fluid" />
                          </Col>
                          <Col className="col-9 card-elem-text-wrapper ps-3 pe-0 d-flex flex-column justify-content-center">
                            <p className="mb-1 template-card-title">{item.title}</p>
                            <p className="mb-0 template-card-description">{item.description}</p>
                          </Col>
                        </Row>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
            )}
            <Col sm={12} className="d-flex justify-content-center align-items-center" style={{ maxWidth: "700px" }}>
              <span className="me-2">Or use an existing project</span>
              <button
                onClick={() => navigate("/import")}
                className={`btn ${
                  isDarkMode ? "github-new-project-btn-light" : "github-new-project-btn"
                } github-new-project-btn-sm`}
              >
                <i className="fab fa-github"></i>
                <span>Import from Github</span>
              </button>
            </Col>
            {aiIsLoading && (
              <Col sm={12} className="d-flex justify-content-center mt-3">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            )}
          </Row>

          <Button
            variant="primary"
            id={showSmartCards ? "view-all-templates-btn" : "just-exploring-btn"}
            onClick={() => {
              setShowExploreContainer(false);
              setShowMainContainer(true);
            }}
            className="mt-3"
          >
            {showSmartCards ? "View all templates" : "I'm just exploring"}
          </Button>
        </Container>
      )}

      {showMainContainer && (
        <Container fluid>
          {/* START GETTING STARTED */}
          <Card.Header className="ps-0 pb-0 d-flex">
            <h4 className="card-title">DEPLOY YOUR PROJECT</h4>{" "}
            <InfoTooltip>
              <a
                id="see-supported-languages-and-frameworks"
                href="https://genezio.com/docs/learn-more/supported-languages"
                target="_blank"
                className="text-black text-decoration-underline text-black-dark-theme"
                rel="noreferrer"
              >
                See supported languages and frameworks
              </a>
            </InfoTooltip>
          </Card.Header>
          <Row>
            <Col sm={12}>
              <button
                onClick={() => navigate("/import")}
                className={`btn ${
                  isDarkMode ? "github-new-project-btn-light" : "github-new-project-btn"
                }  github-new-project-btn-sm mt-3 mb-4`}
              >
                <i className="fab fa-github"></i>
                <span>Import from Github</span>
              </button>

              <h6 className="fw-bold text-dark">Or get started with a template...</h6>
              <p>Here are some popular templates to help you get started. What would you like to build?</p>
              <Row>
                <Col sm={12}></Col>
                {[...getStartedItems, ...comingSoon].map((item, index) => (
                  <Col key={index} sm={6} md={3} className="d-flex p-0 my-2">
                    <a
                      href={item.href}
                      id={item.title}
                      className="w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        if (item.soon) {
                          setModal(item.modalName);
                        } else if (item.href !== "#") {
                          if (item.href.startsWith("http") || item.href.startsWith("https")) {
                            window.open(item.href, "_blank");
                          } else {
                            navigate(item.href);
                          }
                        }
                      }}
                    >
                      <div
                        className="card-elem-wrapper-template mx-2 p-3 d-flex
                    "
                      >
                        <Row className="w-100">
                          <Col className="col-3 d-flex justify-content-center align-items-center align-content-center px-2">
                            <img src={item.image} alt="Genezio Logo" className="img-fluid" />
                          </Col>
                          <Col className="col-9 card-elem-text-wrapper ps-3 pe-0 d-flex flex-column justify-content-center">
                            <p className="mb-1 template-card-title">{item.title}</p>
                            <p className="mb-0 template-card-description">{item.description}</p>
                          </Col>
                        </Row>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          {/* END GETTING STARTED */}

          {/* START TUTORIALS */}
          <Card.Header className="ps-0 pb-0">
            <h4 className="card-title">TUTORIALS</h4>
          </Card.Header>
          <Row>
            <Col sm={10}>
              <Row>
                <Col sm={12}></Col>
                {getStartedTutorials.map((item, index) => (
                  <Col key={index} sm={6} md={6} className="d-flex p-0 my-2">
                    <a
                      id={item.title}
                      href={item.href}
                      className="w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        if (item.href !== "#") {
                          if (item.href.startsWith("http") || item.href.startsWith("https")) {
                            window.open(item.href, "_blank");
                          } else {
                            navigate(item.href);
                          }
                        }
                      }}
                    >
                      <div className="card-elem-wrapper-template mx-2 p-3 d-flex">
                        <Row className="w-100">
                          <Col
                            xl={2}
                            md={2}
                            className="col-3 d-flex justify-content-center align-items-center align-content-center px-2"
                          >
                            <img src={item.image} alt="Genezio Logo" className="img-fluid" />
                          </Col>
                          <Col className="col-9 card-elem-text-wrapper ps-4 d-flex flex-column justify-content-center">
                            <p className="mb-0 template-card-title">{item.title}</p>
                          </Col>
                        </Row>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="col-12">
              <a
                href="https://genezio.com/tags/tutorials/"
                className="d-flex justify-content-start mt-2"
                target="_blank"
                rel="noreferrer"
                id="see-more-tutorials"
              >
                See more tutorials <i className="fas fa-arrow-right ms-1 d-flex align-items-center"></i>
              </a>
            </Col>
          </Row>
          {/* END TUTORIALS */}
        </Container>
      )}
    </>
  );
};

export default GettingStarted;
