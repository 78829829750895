import { useParams } from "react-router-dom";
import { getProjectById, getLogs, getCurrentCollaboratorDetails, getFunctionLogs } from "../../network/ApiAxios";
import GenezioSpinner from "../../components/Utilities/Spinner/GenezioSpinner";
import {
  TestInterface,
  TestInterfaceProps,
  Parameters,
  StatusBar,
  LeftCard,
  environmentOptions,
  isJsonString,
  TestInterfaceModal,
} from "@genezio/test-interface-component";

const TestInterfaceWrapper = () => {
  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  const props: TestInterfaceProps = {
    axios: {
      getProjectById: getProjectById,
      getLogs: getLogs,
      getCurrentCollaboratorDetails: getCurrentCollaboratorDetails,
      getFunctionLogs: getFunctionLogs,
    },
    statusBar: StatusBar,
    genezioSpinner: GenezioSpinner,
    testInterfaceModal: TestInterfaceModal,
    environmentOptions: environmentOptions,
    isJsonString: isJsonString,
    leftCard: LeftCard,
    parameters: Parameters,
    envId: envId ? envId : undefined,
    projectId: projectId ? projectId : undefined,
  };
  return TestInterface(props);
};

export default TestInterfaceWrapper;
