import { Table } from "react-bootstrap";
import { Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { CollaborationListProps, Collaborator, Role } from "../../models/CollaborationModels";
import { capitalizeWord } from "./utils";

const CollaboratorsList: React.FC<CollaborationListProps> = ({
  collaborators,
  invitations,
  currentCollaboratorDetails,
  owner,
  setSelectedRemoveCollaborator,
  roles,
  handleRoleChangeModal,
  setSelectedChangeRole,
}) => {
  return (
    <Row>
      <Col md={5}>
        <Table borderless responsive="xl">
          <tbody>
            {[owner, ...collaborators, ...invitations].map((collaborator: Collaborator, index) => {
              const collaboratorStatus =
                index === 0
                  ? "Owner"
                  : collaborator.status === "pending"
                    ? "Pending"
                    : collaborator.status === "accepted"
                      ? "Accepted"
                      : "Invited";

              return (
                <tr key={index} className="rounded">
                  <td className="d-flex align-items-end">
                    <img
                      style={{ width: "25px", height: "25px" }}
                      src={
                        collaborator?.profileUrl && collaborator?.profileUrl !== "%!s(<nil>)"
                          ? collaborator.profileUrl
                          : require("./../../assets/iconfonts/fontawesome-free/svgs/regular/user.svg").default
                      }
                      className="mx-2 avatar border"
                      alt="collaboratorPhoto"
                    />
                    <p className="mb-0">{collaborator.name ? collaborator.name : collaborator.email}</p>
                  </td>

                  <td>
                    <p className="mb-0 text-muted">{collaboratorStatus}</p>
                  </td>
                  <td>
                    {index !== 0 && (
                      <DropdownButton
                        variant=""
                        id="role"
                        className={currentCollaboratorDetails.role === "collaborator" ? "hideDropdownArrow" : ""}
                        title={index === 0 ? "Owner" : capitalizeWord(collaborator.role)}
                        disabled={currentCollaboratorDetails.role === "collaborator" || index === 0 ? true : false}
                      >
                        {roles
                          .filter((role: Role) => role.name !== collaborator.role)
                          .map((role: Role) => (
                            <Dropdown.Item
                              key={role.name}
                              onClick={async () => {
                                setSelectedChangeRole(collaborator, role);
                                handleRoleChangeModal(true);
                              }}
                              eventKey="1"
                            >
                              {role.name}
                            </Dropdown.Item>
                          ))}
                      </DropdownButton>
                    )}
                  </td>
                  {index !== 0 &&
                  (currentCollaboratorDetails.role !== "collaborator" ||
                    currentCollaboratorDetails.email === collaborator.email) ? (
                    <td>
                      <p
                        onClick={() => setSelectedRemoveCollaborator(collaborator)}
                        style={{ cursor: "pointer " }}
                        className="mb-0 text-muted d-flex"
                      >
                        Remove
                      </p>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default CollaboratorsList;
