import { useEffect, useState } from "react";
import { Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  checkIntegrationUser,
  getAllIntegrations,
  getProjectIntegrations,
  deactivateIntegration,
  addProjectIntegration,
  getIntegrationEnvironmentVariablesList,
  connectExternalOAuth,
  disconnectIntegration,
  getCurrentCollaboratorDetails,
} from "../../network/ApiAxios";
import { CollaboratorDetails } from "../../models/CollaborationModels";
import DeleteIntegrationModal from "../../components/AdvancedUI/Modals/DeleteIntegrationModal";
import DeactivateIntegrationModal from "../../components/AdvancedUI/Modals/DeactivateIntegrationModal";
import DatabaseConnect from "./DatabaseConnect";
import useProjectDetails from "../../hooks/useProjectDetails";
import { getCodeSnippetsOptions, UpstashOauthConnectionUrl } from "./utils";

const QstashDetails = () => {
  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  const { project, activeEnv } = useProjectDetails(projectId || "", envId || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [projectIntegrations, setProjectIntegrations] = useState<any>([]);
  const [upstashQstashIntegration, setUpstashQstashIntegration] = useState<any>([]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [disconnectModal, setDisconnectModal] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const isInstalled = projectIntegrations.includes(upstashQstashIntegration?.name) ? true : false;
  const [integrationEnv, setIntegrationEnv] = useState<any>([]);
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const checkIfUserIsConnected = async () => {
    const res = await checkIntegrationUser("upstash");
    if (res?.data?.connection_status) {
      setIsConnected(true);
      if (activeStep < 2) {
        setActiveStep(2);
      }
    } else {
      setActiveStep(1);
      setIsConnected(false);
    }

    // Check if the integration is installed and if it's connected, set the active step to 3.
    if (isConnected && isInstalled) {
      setActiveStep(2);
    }
  };

  useEffect(() => {
    checkIfUserIsConnected();
  }, [isConnected, isInstalled]);

  // TODO: fix this
  // Run the function every 1 seconds
  useEffect(() => {
    if (!isConnected) {
      const intervalId = setInterval(checkIfUserIsConnected, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [activeStep, isConnected]);

  useEffect(() => {
    fetchUpstashQstashIntegration();
    fetchActiveIntegrations();
  }, []);

  useEffect(() => {
    fetchIntegrationEnvs();
  }, [isInstalled]);

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  const fetchIntegrationEnvs = async () => {
    const res: any = await getIntegrationEnvironmentVariablesList(projectId || "", envId || "", "upstash-qstash");
    if (res?.status === 200) {
      setIntegrationEnv(res.data.environmentVariables);
    }
  };

  const fetchActiveIntegrations = async () => {
    const res: any = await getProjectIntegrations(projectId || "", envId || "");

    if (res?.status === 200) {
      setProjectIntegrations(res?.data?.integrations);
    }
  };

  const fetchUpstashQstashIntegration = async () => {
    setLoading(true);
    const res: any = await getAllIntegrations();
    if (res?.status === 200) {
      setUpstashQstashIntegration(
        res?.data?.integrations.filter((integration: any) => integration.name === "UPSTASH-QSTASH")[0],
      );
    }
    setLoading(false);
  };

  const handleDisconnect = async () => {
    setLoading(true);
    setActiveStep(1);
    setIsConnected(false);
    await disconnectIntegration("upstash");

    if (isInstalled) {
      await deactivateIntegration(projectId ?? "", envId ?? "", "UPSTASH-QSTASH");
    }

    await fetchActiveIntegrations();
    setLoading(false);
  };

  const toggleIntegration = async () => {
    // Delete case
    if (isInstalled) {
      setDeleteModal(true);
      return;
    }

    // Install case
    setLoading(true);
    await addProjectIntegration(projectId || "", envId || "", {
      integrationName: "UPSTASH-QSTASH",
      envVars: [],
    });
    toast.success("Upstash Integration Added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setLoading(false);

    await fetchActiveIntegrations();
  };

  const deleteIntegrationModalClose = async () => {
    setDeleteModal(false);
    await fetchActiveIntegrations();
  };

  // Content for each step
  const steps = [
    {
      title: "Connect to Upstash Account",
      content: () => {
        return (
          <>
            {isConnected ? (
              <>
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"12px"} fill={"#28A745"}>
                    <path d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z" />
                  </svg>
                  <p className="mb-0 mx-1">Connected successfully</p>
                </div>
                <Button variant="outline-danger" onClick={() => setDisconnectModal(true)} className="my-3" size="sm">
                  Disconnect
                </Button>
              </>
            ) : (
              <Button onClick={() => connectExternalOAuth(UpstashOauthConnectionUrl)} className="mb-3" size="sm">
                Connect
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "Secrets",
      content: () => {
        return (
          <>
            {activeStep > 1 && (
              <>
                <p className="text-muted">
                  <span style={{ fontWeight: "500" }}>
                    {isInstalled && integrationEnv !== null
                      ? "The secrets below are set as environment variables in this project:"
                      : "The secrets below will be set as environment variables in this project:"}
                  </span>
                </p>
                <Row>
                  <p className="mb-1">
                    QSTASH_URL
                    <br />
                    QSTASH_TOKEN
                    <br />
                    QSTASH_CURRENT_SIGNING_KEY
                    <br />
                    QSTASH_NEXT_SIGNING_KEY
                  </p>
                </Row>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer />

      <DeactivateIntegrationModal
        integrationName="Upstash"
        show={disconnectModal}
        onDeleteIntegrationClose={() => {
          setDisconnectModal(false);
        }}
        handleDisconnect={handleDisconnect}
      />

      <DeleteIntegrationModal
        projectId={projectId}
        envId={envId}
        show={deleteModal}
        integrationName={upstashQstashIntegration?.name}
        setActiveStep={() => setActiveStep(2)}
        resetSelectedDatabase={() => {}}
        onDeleteIntegrationClose={() => deleteIntegrationModalClose()}
      />

      {/* Integrations Variables Card Content */}
      <Row className="mt-3">
        <Col sm={12}>
          <Card style={{ padding: "0 0 59px 14px", minHeight: "75vh" }}>
            <Card.Body className="pt-0">
              {loading ? (
                <Row className="my-5">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner variant="primary" animation="border" />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <h4 className="card-header card-title px-0 pb-0">{`${
                      isInstalled ? "MANAGE" : "INSTALL"
                    } ${upstashQstashIntegration?.name} INTEGRATION`}</h4>
                    <p className="text-muted my-1" style={{ fontSize: "12px" }}>
                      {upstashQstashIntegration?.description}
                    </p>

                    <a
                      href={"https://console.upstash.com/"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary text-decoration-underline"
                    >
                      Upstash Console
                    </a>

                    {steps.map((step, index) => (
                      <div key={step.title} className="d-flex align-items-center pos-relative">
                        <div>
                          <div className="d-flex align-items-center">
                            <div
                              className={`step-number my-2 ${
                                activeStep > index + 1 || activeStep === index + 1 ? "step-number-success" : ""
                              }`}
                            >
                              {index + 1}
                            </div>
                            <h6 className="mb-0 mx-3">{step.title}</h6>
                          </div>

                          <div className="w-100 mx-5">{step.content()}</div>
                        </div>

                        {/* Connectors */}
                        {index < steps.length - 1 && (
                          <div className="connector ">
                            <div className={`line ${activeStep > index + 1 ? "line-success" : ""}`} />
                          </div>
                        )}
                      </div>
                    ))}
                    {activeStep === 2 && (
                      <Button
                        className="mx-5"
                        size="sm"
                        variant={isInstalled ? "outline-danger" : "primary"}
                        onClick={() => toggleIntegration()}
                        disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                      >
                        {isInstalled ? "Delete" : "Save"}
                      </Button>
                    )}
                  </Col>

                  <Col
                    md={7}
                    sm={12}
                    className="m-3"
                    style={{
                      opacity: integrationEnv === null ? 0.5 : 1,
                    }}
                  >
                    {/* Code snippet for connecting to db component */}
                    <DatabaseConnect
                      connectionType={"qstash"}
                      secondaryText={"Copy the code snippet below and paste it in your application."}
                      integrationEnv={integrationEnv}
                      options={getCodeSnippetsOptions("upstashQstash")}
                      integrationName="upstash-qstash"
                    />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default QstashDetails;
