import { useState, useEffect } from "react";
import { getProjectById } from "../network/ApiAxios";
import { useNavigate } from "react-router-dom";

// Custom hook to return project and activeEnv
function useProjectDetails(projectId: string, envId: string) {
  const [project, setProject] = useState<any>({});
  const [activeEnv, setActiveEnv] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    const runAsync = async () => {
      const res: any = await getProjectById(projectId ?? "");
      if (res.data && res.data.status === "ok") {
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
        setActiveEnv(localActiveEnv);
        setProject(res.data.project);
      } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
        navigate("/dashboard");
      }
    };
    runAsync();
  }, [projectId, envId, navigate]);

  return { project, activeEnv };
}

export default useProjectDetails;
