import { useState } from "react";
import { Button, Modal, Spinner, Alert } from "react-bootstrap";
import { ChangeCollaboratorRoleModalProps } from "../../../models/CollaborationModels";
import { updateCollaboratorRole } from "../../../network/ApiAxios";

const ChangeCollaboratorRoleModal: React.FC<ChangeCollaboratorRoleModalProps> = ({
  open,
  handleRoleChangeModal,
  fetchCollaborators,
  fetchCollaboratorDetails,
  collaborator,
  selectedRole,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleCancel = () => {
    handleRoleChangeModal(false);
  };

  const handleRoleChange = async () => {
    setLoading(true);

    const res: any = await updateCollaboratorRole(collaborator.id, selectedRole.id);

    if (res.response?.data?.error?.message) {
      setError(res.response.data.error.message);
      setLoading(false);
      return;
    }

    if (res.status === 200 && res.data.status === "ok") {
      await fetchCollaborators();
      await fetchCollaboratorDetails();
    }

    handleCancel();
    setLoading(false);
  };

  return (
    <Modal show={open} onHide={() => handleCancel()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "30px" }}>
        <h6 className="mb-3">Change user role</h6>
        {error && <Alert variant="danger">{error}</Alert>}
        <p>
          Are you sure you want to update role for <b>{collaborator.name ? collaborator.name : collaborator.email}</b>{" "}
          from
          <b> {collaborator.role}</b> to <b> {selectedRole.name}</b>
        </p>

        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleCancel()}>
            Close
          </Button>

          <Button disabled={loading} className="px-4" size="sm" onClick={() => handleRoleChange()}>
            Change
            {loading && <Spinner className="ms-2" animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeCollaboratorRoleModal;
