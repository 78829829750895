import { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  connectUserToGithub,
  checkIntegrationUser,
  getUserRepositories,
  getRepositoryDetails,
  linkRepository,
} from "../../network/ApiAxios";
import ConnectToGithub from "./Components/ConnectToGithubImportProject";
import ProjectConfiguration from "./Components/ProjectConfiguration";
import DeployProgress from "./Components/DeployProgress";
import Sidebar from "./Components/Sidebar";

const loadingSteps = [
  "Analyzing repository...",
  "Checking dependencies...",
  "Checking environment variables...",
  "Checking project configuration...",
  "Checking deployment settings...",
];

const terminalStyle = {
  backgroundColor: "#1E1E1E",
  color: "#6f42c1",
  fontFamily: "monospace",
  fontSize: "18px",
  width: "600px",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  margin: "50px auto",
  height: "60vh",
};

const outputStyle = {
  whiteSpace: "pre-wrap",
  lineHeight: "1.5",
};

const cursorStyle = {
  display: "inline-block",
  width: "10px",
  backgroundColor: "#6f42c1",
};

const ImportProjectFlow = () => {
  const [isConnectedToGithub, setIsConnectedToGithub] = useState<boolean>(false);
  const [githubLoading, setGithubLoading] = useState<boolean>(false);
  const [githubReposLoading, setGithubReposLoading] = useState<boolean>(false);
  const [githubUserDetails, setGithubUserDetails] = useState<any>({});
  const [githubUserRepos, setGithubUserRepos] = useState<any>({});
  const [repoName, setRepoName] = useState<string>("");
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const [settingsUrl, setSettingsUrl] = useState<string>("");
  const [repoBranch, setRepoBranch] = useState<string>("");
  const [projectConfiguration, setProjectConfiguration] = useState<any>(null);
  const [frameworkBackend, setFrameworkBackend] = useState<string>("");
  const [frameworkFrontend, setFrameworkFrontend] = useState<string>("");
  const [envVarsKeys, setEnvVarsKeys] = useState<string[]>([]);

  const [loadingActiveStep, setLoadingActiveStep] = useState<number>(0);

  const urlParams = new URLSearchParams(window.location.search);
  const codeId = urlParams.get("code") || "";
  const state = urlParams.get("state") || "";
  const installationId = urlParams.get("installation_id") || "";

  const params = useParams();
  const repoId: number = parseInt(params.id ?? "");

  const handleGithubConnect = async () => {
    if (!isConnectedToGithub) {
      setGithubLoading(true);

      if (codeId) {
        const res = await connectUserToGithub(codeId, installationId).catch((err) => {
          console.log(err);
          return null;
        });
        if (!res || res.status !== 200) {
          return;
        }
        if (state === "fork") {
          window.location.href = `/fork?${localStorage.getItem("locationSearch") || ""}`;
          return;
        }
        if (state === "import") {
          window.location.href = `/import-external-project?${localStorage.getItem("locationSearch") || ""}`;
          return;
        }
        // remove code and state from the url
        window.history.replaceState({}, "", window.location.pathname);
      }

      const res: any = await checkIntegrationUser("github");
      if (res?.status === 200 && res?.data?.connection_status) {
        setGithubUserDetails(res.data?.connected_account);
        setIsConnectedToGithub(true);
      }
      setGithubLoading(false);
    }
  };

  const fetchUserRepos = async () => {
    if (isConnectedToGithub) {
      setGithubReposLoading(true);
      const res: any = await getUserRepositories(true);
      setGithubReposLoading(false);

      if (res.status === 200 && res.data.status === "ok") {
        setSettingsUrl(res.data.settingsUrl);
        setGithubUserRepos(res.data.repositories);
      } else {
        setGithubUserRepos([]);
        setIsConnectedToGithub(false);
        setGithubUserDetails({});
      }
    }
  };

  const handleDeployProject = async (githubRepositoryId: any, path: any, projectConfigurationInput: any, env: any) => {
    const envs: { [key: string]: string } = {};
    env.forEach((e: any) => {
      envs[e.name] = e.value;
    });
    setProjectConfiguration((prev: any) => ({ ...prev, ...projectConfigurationInput }));

    const res: any = await linkRepository(githubRepositoryId, projectConfigurationInput, envs);
    setProjectConfiguration(projectConfigurationInput);

    if (res.status === 200 && res.data.status === "ok") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsDeploying(true);
    } else {
      console.log(res);
      alert(res.response.data.error.message);
    }
  };

  useEffect(() => {
    handleGithubConnect();
  }, [codeId]);

  useEffect(() => {
    fetchUserRepos();
  }, [isConnectedToGithub]);

  useEffect(() => {
    let stepIndex = 0;

    // Interval to add each step to the list of completed steps
    const interval = setInterval(() => {
      if (stepIndex < loadingSteps.length) {
        stepIndex++;
        setLoadingActiveStep(stepIndex);
      } else {
        clearInterval(interval); // Stop adding steps once all are displayed
      }
    }, 2000); // Change step every 2 seconds

    const handleRepoInfo = async () => {
      const repoDetails: any = await getRepositoryDetails(repoId, ".");
      clearInterval(interval);

      if (repoDetails === undefined) {
        alert("Something went wrong");
      }

      if (!repoDetails?.data) {
        alert(repoDetails.response.data.error.message);
        // return;
      }
      setRepoName(`${repoDetails?.data?.repository?.owner}/${repoDetails?.data?.repository?.name}`);
      setRepoBranch(repoDetails?.data?.repository?.branch);
      setEnvVarsKeys(repoDetails?.data?.envKeys || []);
      const projectConfig = repoDetails?.data?.projectConfiguration || {};
      projectConfig.name = projectConfig.name || repoDetails?.data?.repository?.name;
      projectConfig.region = projectConfig.region || "us-east-1";
      setProjectConfiguration(projectConfig || {});
      const frontendFrameworks = [];
      if (repoDetails?.data?.frameworks?.frontend?.length) {
        frontendFrameworks.push(...repoDetails?.data?.frameworks?.frontend);
      }
      if (repoDetails?.data?.frameworks?.ssr?.length) {
        frontendFrameworks.push(...repoDetails?.data?.frameworks?.ssr);
      }
      setFrameworkFrontend(frontendFrameworks.length > 0 ? frontendFrameworks[0] : "other");
      setFrameworkBackend(repoDetails?.data?.frameworks?.backend[0] || "other");
    };

    if (repoId) {
      handleRepoInfo();
    }
  }, [repoId]);

  return (
    <Row>
      <a href="/new-project" className="mt-4 mb-3 text-decoration-underline">
        {"<< "}Back to New Project
      </a>

      <Col sm={12} className="col-12">
        <Card>
          <Card.Body
            style={{ minHeight: "80vh", paddingTop: "20px" }}
            className="d-flex align-items-start justify-content-center w-100"
          >
            {!repoId ? (
              <ConnectToGithub
                githubUserDetails={githubUserDetails}
                isConnectedToGithub={isConnectedToGithub}
                githubUserRepos={githubUserRepos}
                connectMoreAccounts={() => setIsConnectedToGithub(false)}
                githubReposLoading={githubReposLoading}
                settingsUrl={settingsUrl}
              />
            ) : (
              <>
                {projectConfiguration ? (
                  <>
                    {isDeploying ? (
                      // Deploy Progress
                      <div className="w-100" style={{ minHeight: "80vh" }}>
                        <Row className="mt-4 w-100">
                          <Sidebar repoName={repoName} branch={repoBranch} activeStep={2} basePath={"./"} />
                          <DeployProgress githubRepositoryId={repoId} projectName={projectConfiguration.name} />
                        </Row>
                      </div>
                    ) : (
                      // Project configuration and deploy
                      <Row className="mt-4 w-100">
                        <Sidebar repoName={repoName} branch={repoBranch} activeStep={1} basePath={"./"} />
                        <ProjectConfiguration
                          key={`project-config-${repoId}`}
                          githubRepositoryId={repoId}
                          handleDeployProject={handleDeployProject}
                          projectConfiguration={projectConfiguration}
                          repoName={repoName}
                          frameworkFrontendProps={frameworkFrontend}
                          frameworkBackendProps={frameworkBackend}
                          envVarsKeys={envVarsKeys}
                          isGenezioDeployBtn={false}
                        />
                      </Row>
                    )}
                  </>
                ) : (
                  <Col className="border rounded p-4" lg={8}>
                    <div className="p-0 w-100">
                      {/* Card header */}
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-2 fw-bold">Analyzing your project</h4>
                          <p className="m-0 text-muted">
                            Please wait while we analyze your project. It might take a few seconds.
                          </p>
                        </div>

                        {/* <div>
                         <img src={GithubIcon} alt="github_icon" style={{ width: "63px" }} className="invert-dark-theme" />
                       </div> */}
                      </div>

                      {/* Deploy progress */}
                      <div className="m-auto d-flex flex-column align-items-center border rounded p-3">
                        {loadingSteps.map((step, index) => (
                          <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                            <h6 className="m-0 fw-semibold">{step}</h6>

                            {loadingActiveStep < index || loadingSteps.length - 1 === index ? (
                              <Spinner variant="primary" animation="border" size="sm" />
                            ) : (
                              <img
                                src={require("../../assets/img/svgs/check_mark.svg").default}
                                alt="integrationStatus"
                              />
                            )}
                          </div>
                        ))}

                        {/* {deployProgress?.BuildStatus !== "SUCCEEDED" &&
                       deployProgress?.BuildStatus !== "FAILED" &&
                       deployProgress?.Transitions.length > 0 ? (
                         <Spinner variant="primary" animation="border" />
                       ) : (
                         ""
                       )} */}
                      </div>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ImportProjectFlow;
