import { Button, Modal, Spinner, Alert } from "react-bootstrap";

interface DeleteSubdomainProps {
  selectedSubdomain: string;
  show: boolean;
  isDeletingLoading: boolean;
  onHide: () => void;
  onDelete: () => void;
}

const DeleteSubdomainModal: React.FC<DeleteSubdomainProps> = ({
  selectedSubdomain,
  show,
  onHide,
  onDelete,
  isDeletingLoading,
}) => {
  return (
    <Modal show={show} onHide={() => onHide()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "40px" }}>
        <div>
          <h3 className="mb-3 text-dark" style={{ fontSize: "24px", lineHeight: "22px" }}>
            Delete Subdomain
          </h3>
          <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />
          <p className="mb-2">
            Are you sure you want to delete <b>{selectedSubdomain}</b>?
          </p>
          <Alert variant="danger" style={{ color: "#842029" }}>
            You will not be able to use it anymore.
          </Alert>
        </div>

        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => onHide()}>
            Close
          </Button>
          <Button variant="outline-danger" disabled={isDeletingLoading} onClick={() => onDelete()}>
            Delete {isDeletingLoading && <Spinner animation="border" className="ms-2" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteSubdomainModal;
