import { useState, useEffect } from "react";
import { Card, Row, Col, Alert, Breadcrumb, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProjectById,
  getAllIntegrations,
  getProjectIntegrations,
  getCurrentCollaboratorDetails,
} from "../network/ApiAxios";
import IntegrationCard from "./Integrations/IntegrationCard";
import GenezioSpinner from "../components/Utilities/Spinner/GenezioSpinner";
import { IntegrationsProps } from "../models/IntegrationModels";
import { CollaboratorDetails } from "../models/CollaborationModels";

const Integrations = () => {
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();

  const [activeEnv, setActiveEnv] = useState<any>({});

  const navigate = useNavigate();
  const [project, setProject] = useState<any>({});
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [projectIntegrations, setProjectIntegrations] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingActiveIntegrations, setLoadingActiveIntegrations] = useState(false);
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  // get project details by project id
  useEffect(() => {
    const runAsync = async () => {
      const res: any = await getProjectById(projectId ?? "");
      if (res.data && res.data.status === "ok") {
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
        setActiveEnv(localActiveEnv);
        setProject(res.data.project);
      } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
        navigate("/dashboard");
      }
    };
    runAsync();
  }, []);

  // get integrations (all integrations and active integrations)
  useEffect(() => {
    fetchAllIntegrations();
    fetchActiveIntegrations();
  }, []);

  const fetchAllIntegrations = async () => {
    setLoading(true);
    const res: any = await getAllIntegrations();
    if (res?.status === 200) {
      setAllIntegrations(res?.data?.integrations);
    }
    setLoading(false);
  };

  const fetchActiveIntegrations = async () => {
    setLoadingActiveIntegrations(true);
    const res: any = await getProjectIntegrations(projectId ?? "", envId ?? "");
    if (res?.status === 200) {
      setProjectIntegrations(res?.data?.integrations);
    }
    setLoadingActiveIntegrations(false);
  };

  useEffect(() => {
    document.title = "Genezio | Integrations";
  }, []);

  // Group integrations by description
  const groupIntegrations = () =>
    allIntegrations.reduce(
      (acc: any, obj: any) => ((acc[obj.description] = [...(acc[obj.description] || []), obj]), acc),
      {} as Record<string, any>,
    );

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  return (
    <>
      {/* Integrations Variables Card Content */}
      <Row className="mt-3">
        <Col sm={12}>
          <Card style={{ minHeight: "75vh" }}>
            <Card.Header className="pb-0">
              <h5 className="card-title" style={{ textTransform: "none" }}>
                Integrations
              </h5>
            </Card.Header>
            <Card.Body>
              {!loading &&
                !loadingActiveIntegrations &&
                !project?.projectEnvs?.filter((project: any) => project.id === envId)[0]?.classes?.length && (
                  <Alert variant="warning" className="text-muted">
                    Integrations can be enabled for backend classes. This project has no deployed backend classes yet,
                    so enabling an integration will have no effect now.
                  </Alert>
                )}

              {!loading && activeEnv.name === "prod" && currentCollaboratorDetails?.role === "collaborator" && (
                <Alert variant="warning" className="text-muted">
                  You are unable to view integration details as a collaborator
                </Alert>
              )}

              <Row className="m-0">
                {loading || loadingActiveIntegrations ? (
                  <Row className="d-flex align-items-center justify-content-center w-100">
                    <GenezioSpinner />
                  </Row>
                ) : (
                  <>
                    {Object.keys(groupIntegrations()).map((key, index) => {
                      const integrationsGroup = groupIntegrations()[key];

                      if (integrationsGroup.length && integrationsGroup[0]?.name === "EMAIL-SERVICE") {
                        return "";
                      }
                      return (
                        <div key={key} className="w-100">
                          <h6 className={`mb-3 ${index !== 0 && "mt-3"}`}>{key}</h6>
                          <Row className="m-0">
                            {(integrationsGroup as any[]).map((integration: any) => {
                              const { name } = integration;
                              const isInstalled = projectIntegrations.includes(name);

                              return (
                                <IntegrationCard
                                  key={name}
                                  integration={integration}
                                  projectId={projectId}
                                  envId={envId}
                                  envName={activeEnv.name}
                                  collaboratorRole={currentCollaboratorDetails?.role}
                                  isInstalled={isInstalled}
                                />
                              );
                            })}
                          </Row>
                        </div>
                      );
                    })}
                  </>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Integrations;
