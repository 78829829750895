import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";

type DeleteProps = {
  show: boolean;
  unlinkRepoLoading: boolean;
  linkedRepos: any;
  onHide: () => void;
  handleDeleteLinkedRepo: (repoId: any) => void;
};

const DeleteRepo: React.FC<DeleteProps> = ({
  show,
  linkedRepos,
  onHide,
  handleDeleteLinkedRepo,
  unlinkRepoLoading,
}) => {
  const [selectedRepo, setSelectedRepo] = useState<any>({});

  const handleClose = () => {
    setSelectedRepo({});
    onHide();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "40px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Unlink Repository
        </h3>
        <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />
        <p className="mb-4 pb-1">
          If you no longer want to automatically deploy when pushing to this repository, please select the name of the
          repository you want to remove in the input box below:
        </p>

        <Select
          options={linkedRepos?.map((repo: any) => ({
            label: repo?.name,
            value: repo,
          }))}
          onChange={(e: any) => setSelectedRepo(e?.value)}
        />

        <p className="mb-0 mt-3 text-muted">
          You can always undo this action by importing your repository from the New Project page. This will NOT delete
          your repository from you GitHub account{" "}
        </p>

        <div className="d-flex justify-content-end mt-4">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            variant="danger"
            className="px-4"
            size="sm"
            disabled={!selectedRepo?.id || unlinkRepoLoading ? true : false}
            onClick={() => {
              handleDeleteLinkedRepo(selectedRepo?.id);
            }}
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteRepo;
