const INIT_STATE: any = {
  projects: [],
};

export const projectsreducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case "UPDATE_PROJECTS":
      return {
        ...state,
        projects: [...action.payload],
      };
    default:
      return state;
  }
};
