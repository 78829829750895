import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Breadcrumb,
  InputGroup,
  Form,
  Spinner,
  Badge,
  Tooltip,
  OverlayTrigger,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import GenezioSpinner from "../components/Utilities/Spinner/GenezioSpinner";
import { deleteDatabase, getAllDatabases, getDatabaseConnectionURL, resetDatabasePassword } from "../network/ApiAxios";
import DeleteDatabase from "../components/AdvancedUI/Modals/DeleteDatabase";
import CreateDatabase from "../components/AdvancedUI/Modals/CreateDatabase";
import ResetPasswordDatabase from "../components/AdvancedUI/Modals/ResetPasswordDatabase";
import DatabaseConnectModal from "../components/AdvancedUI/Modals/ConnectDatabase";

export default function Databases() {
  const navigation = useNavigate();

  const [databases, setDatabases] = useState([]);
  const [elemToDelete, setElemToDelete] = useState<any>(null);
  const [modalDeleteDatabase, setModalDeleteDatabase] = useState(false);
  const [modalCreateDatabase, setModalCreateDatabase] = useState(false);
  const [resetPasswordElem, setResetPasswordElem] = useState<any>(null);

  const [modalConnectDatabase, setModalConnectDatabase] = useState(false);

  const [elemToConnect, setElemToConnect] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);

  const [copyBtnDbId, setCopyBtnDbId] = useState<string>("");

  const messageCallback = (event: any) => {
    if (event.data === "ready") {
      event.source?.postMessage({ type: "AUTH_TOKEN", token: localStorage.getItem("apiToken") }, event.origin);
      window.removeEventListener("message", messageCallback);
    }
  };

  useEffect(() => {
    document.title = "Genezio | Databases";
    window.addEventListener("message", messageCallback);
  }, []);

  const fetchDatabases = async () => {
    setIsLoading(true);
    const res = await getAllDatabases().catch((err) => {
      console.log(err);
      return null;
    });

    setIsLoading(false);

    if (!res) {
      toast.error("Failed to fetch databases. Please try again later.");
      return;
    }

    setDatabases(res.data.databases || []);
  };

  useEffect(() => {
    fetchDatabases();
  }, []);

  async function handleDeleteDatabase(id: string) {
    const res: any = await deleteDatabase(id);

    if (res.response && res.response.data.error) {
      toast.error("Failed to delete database. Please try again later.");
      setModalDeleteDatabase(false);
      setElemToDelete(null);
      return;
    }

    setModalDeleteDatabase(false);
    setElemToDelete(null);

    const newDatabases = databases.filter((db: any) => db.id !== id);
    setDatabases(newDatabases);
    toast.success("Database deleted successfully!");
  }

  async function handleResetPasswordCallback() {
    const res: any = await resetDatabasePassword(resetPasswordElem.id);

    if (res.response && res.response.data.error) {
      toast.error("Failed to reset database password. Please try again later.");
      setResetPasswordElem(null);
      return;
    }

    setResetPasswordElem(null);
    toast.success("Password reset successfully!");
  }

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="breadcrumb-item"
              onClick={() => navigation("/")}
              aria-current="page"
              style={{ paddingTop: "1px" }} // hack don;t change it
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item" aria-current="page" active>
              Databases
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <ToastContainer />

      {/* <!-- Modal Delete --> */}
      <DeleteDatabase
        databaseName={elemToDelete?.name}
        deleteDatabaseCallback={() => handleDeleteDatabase(elemToDelete?.id)}
        show={modalDeleteDatabase}
        onHide={() => {
          setElemToDelete(null);
          setModalDeleteDatabase(false);
        }}
      />
      {/* <!-- /Modal Delete --> */}

      {/* <!-- Modal Connect To Db --> */}
      {modalConnectDatabase && (
        <DatabaseConnectModal
          show={modalConnectDatabase}
          onHide={() => {
            setModalConnectDatabase(false);
            setElemToConnect(null);
          }}
          databaseId={elemToConnect?.id}
          databaseName={elemToConnect?.name}
          databaseType={elemToConnect?.type}
        />
      )}
      {/* <!-- /Modal Connect To Db --> */}

      {/* <!-- Modal Change Passsword --> */}
      <ResetPasswordDatabase
        databaseName={resetPasswordElem?.name}
        resetPasswordCallback={() => handleResetPasswordCallback()}
        show={resetPasswordElem !== null}
        onHide={() => {
          setResetPasswordElem(null);
        }}
      />
      {/* <!-- /Modal Delete --> */}

      {/* <!-- Modal Create --> */}
      <CreateDatabase
        createDatabaseCallback={() => fetchDatabases()}
        show={modalCreateDatabase}
        onHide={() => {
          setModalCreateDatabase(false);
        }}
      />
      {/* <!-- /Modal Create --> */}

      {/* <!-- row  --> */}
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h4 className="card-title">Databases</h4>
            </Card.Header>

            {databases.length === 0 || isLoading ? (
              <Card.Body className="pt-0 d-flex align-items-center justify-content-center" style={{ height: "70vh" }}>
                {isLoading ? (
                  <GenezioSpinner />
                ) : databases.length === 0 ? (
                  <div>
                    <Row className="pb-2">
                      <Col sm={12} className="col-12 text-center p-5 text-dark">
                        <h4>You don't have any databases!</h4>
                        <p className="mb-3 ">
                          Information about the databases will be shown here after creating them.{" "}
                        </p>
                        <Button className="btn btn-primary p-1 px-3" onClick={() => setModalCreateDatabase(true)}>
                          Create Database
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </Card.Body>
            ) : (
              <Card.Body className="pt-0 d-flex align-items-center justify-content-center">
                <div className="table-responsive">
                  <>
                    <div className="d-flex align-items-left mb-3">
                      <Button className="btn btn-primary p-1 px-3" onClick={() => setModalCreateDatabase(true)}>
                        Create Database
                      </Button>
                    </div>
                    <table className="table text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="tabletitle">#</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">Database Name</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">Database Region</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">Database Type</span>
                          </th>
                          <th className="text-center">
                            <span className="tabletitle">URL</span>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {databases.map((db: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="text-center">{db.name}</td>
                              <td className="text-center">{db.region}</td>
                              <td className="text-center">{db.type}</td>
                              <td className="text-center d-flex justify-content-center">
                                <Button
                                  className="btn btn-primary p-1 px-3"
                                  onClick={() => {
                                    setElemToConnect(db);
                                    setModalConnectDatabase(true);
                                  }}
                                >
                                  Connect
                                </Button>
                              </td>
                              <td className="text-center">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    right: "10px",
                                    top: "10px",
                                    width: "100%",
                                    maxWidth: "20px",
                                  }}
                                >
                                  {elemToDelete?.id === db.id || resetPasswordElem ? (
                                    <Spinner animation="border" variant="primary" size="sm" />
                                  ) : (
                                    <>
                                      <div>
                                        <DropdownButton
                                          size="sm"
                                          id="dropdown-basic-button"
                                          title="Actions"
                                          variant=""
                                          autoClose={true}
                                        >
                                          {db.type === "postgres-neon" ? (
                                            <>
                                              <Dropdown.Item
                                                as="button"
                                                onClick={() => {
                                                  window.open(
                                                    process.env.REACT_APP_POSTGRES_SCHEMA_EDITOR_URL + `/edit/${db.id}`,
                                                    "_blank",
                                                  );
                                                }}
                                              >
                                                Edit Schema
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as="button"
                                                onClick={() => {
                                                  window.open(
                                                    process.env.REACT_APP_POSTGRES_SCHEMA_EDITOR_URL + `/data/${db.id}`,
                                                    "_blank",
                                                  );
                                                }}
                                              >
                                                View Data
                                              </Dropdown.Item>
                                            </>
                                          ) : (
                                            <Dropdown.Item
                                              as="button"
                                              onClick={() => {
                                                window.open(
                                                  process.env.REACT_APP_MONGO_EDITOR_URL + `/${db.id}`,
                                                  "_blank",
                                                );
                                              }}
                                            >
                                              View Data
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() => {
                                              setResetPasswordElem(db);
                                            }}
                                          >
                                            Reset Password
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            className="text-danger"
                                            onClick={() => {
                                              setElemToDelete(db);
                                              setModalDeleteDatabase(true);
                                            }}
                                          >
                                            Delete Database
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
