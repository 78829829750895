import React, { useState } from "react";
import { ButtonGroup, Button, Alert } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco, atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { getCodeSnippets } from "./utils";
import { DatabaseConnectProps } from "../../models/IntegrationModels";
import { copyToClipboard } from "../../utils";

const DatabaseConnect: React.FC<DatabaseConnectProps> = ({
  options,
  integrationName,
  integrationEnv,
  connectionType,
  secondaryText,
}) => {
  const [activeLanguage, setActiveLanguage] = useState(options[0]);
  const [buttonCopyState, setButtonCopyState] = useState<string>("");
  const [showSensitiveData, setShowSensitiveData] = useState<boolean>(false);

  const darkMode = localStorage.getItem("darkMode") === "true";

  return (
    <div className="w-100 mt-5">
      <h6 className="mb-1" style={{ fontWeight: "600" }}>
        Connect to your {connectionType}
      </h6>
      {secondaryText ? (
        <p className="text-muted mb-3" style={{ fontWeight: "500" }}>
          {secondaryText}
        </p>
      ) : null}
      <ButtonGroup className="border rounded">
        {options.map((option: string) => (
          <Button
            key={option}
            onClick={() => {
              setActiveLanguage(option);
              setShowSensitiveData(false);
            }}
            variant={activeLanguage === option ? "primary" : ""}
          >
            {option}
          </Button>
        ))}
      </ButtonGroup>

      {getCodeSnippets(integrationName, activeLanguage, integrationEnv)?.map((snippet: any, index: any) => (
        <React.Fragment key={index}>
          {snippet.warning && (
            <Alert variant="warning" className="text-muted mt-3">
              {snippet.warning}
            </Alert>
          )}

          <p className="text-muted mt-3 my-2" style={{ fontWeight: "500" }}>
            {snippet.title}
          </p>

          <div className="position-relative">
            <SyntaxHighlighter
              customStyle={{
                fontSize: "15px",
                fontWeight: "500",
              }}
              className="p-4 rounded"
              language={snippet.language}
              style={darkMode ? atomOneDark : docco}
            >
              {showSensitiveData ? snippet.copySnippet : snippet.codeSnippet}
            </SyntaxHighlighter>

            {/* Display show button only if integration is active  */}
            {integrationEnv && snippet.revealCredentialButton && (
              <div
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "40px",
                  top: "10px",
                  width: "100%",
                  maxWidth: "20px",
                }}
                onClick={() => setShowSensitiveData(!showSensitiveData)}
              >
                {showSensitiveData ? (
                  <i style={{ fontSize: "20px" }} className="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i style={{ fontSize: "20px" }} className="fa fa-eye" aria-hidden="true"></i>
                )}
              </div>
            )}
            <div
              onClick={() =>
                copyToClipboard(snippet.copySnippet, "codeSnippet" + index, buttonCopyState, setButtonCopyState)
              }
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
                width: "100%",
                maxWidth: "20px",
              }}
            >
              {buttonCopyState === "codeSnippet" + index ? (
                <i style={{ fontSize: "20px" }} className="fa fa-check text-success" aria-hidden="true" />
              ) : (
                <img
                  src={require("./../../assets/img/svgs/copy_icon.svg").default}
                  alt="Copy"
                  style={{ width: "120%", maxWidth: "120%" }}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default DatabaseConnect;
