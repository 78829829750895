import { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import {
  connectUserToGithub,
  checkIntegrationUser,
  getUserRepositories,
  deployUsingExternalS3,
} from "../../network/ApiAxios";
import ProjectConfiguration from "./Components/ProjectConfiguration";
import DeployProgress from "./Components/DeployProgress";
import Sidebar from "./Components/Sidebar";
import ConnectToGithubForkProject from "./Components/ConnectToGithubForkProject";

const NewProjectS3UrlFlow = () => {
  const [isConnectedToGithub, setIsConnectedToGithub] = useState<boolean>(false);
  const [githubUserDetails, setGithubUserDetails] = useState<any>({});

  const [isDeploying, setIsDeploying] = useState<boolean>(false);

  const [skipGithubConnect, setSkipGithubConnect] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const urlParams = new URLSearchParams(window.location.search);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [showProjectConfiguration, setShowProjectConfiguration] = useState<boolean>(false);
  const [githubUserRepos, setGithubUserRepos] = useState<any>([]);

  const installationId = urlParams.get("code") || "";
  const projectName = urlParams.get("name") || "";
  const s3URL = urlParams.get("s3url") || "";

  const [projectConfiguration, setProjectConfiguration] = useState<any>({
    name: projectName,
    region: "us-east-1",
  });

  const fetchUserRepos = async () => {
    if (isConnectedToGithub) {
      const res: any = await getUserRepositories();
      if (res.status === 200 && res.data.status === "ok") {
        setGithubUserRepos(res.data.repositories);
      } else {
        setGithubUserRepos([]);
        setIsConnectedToGithub(false);
        setGithubUserDetails({});
      }
    }
  };

  const handleGithubConnect = async () => {
    if (!isConnectedToGithub) {
      if (installationId) {
        const res = await connectUserToGithub(installationId).catch((err) => {
          console.log(err);
          return null;
        });
        if (!res || res.status !== 200) {
          return;
        }
      }
      const res: any = await checkIntegrationUser("github");
      if (res?.status === 200 && res?.data?.connection_status) {
        setGithubUserDetails(res.data?.connected_account);
        setIsConnectedToGithub(true);
      }
      setFinishedLoading(true);
    }
  };
  useEffect(() => {
    fetchUserRepos();
  }, [isConnectedToGithub]);

  useEffect(() => {
    handleGithubConnect();
  }, [installationId]);

  const handleDeployProject = async (
    githubRepositoryId: any,
    path: any,
    projectConfigurationInput: any,
    env: any,
    repositoryNameLocal?: string,
    privateRepo?: boolean,
  ) => {
    const envs: { [key: string]: string } = {};
    env.forEach((e: any) => {
      envs[e.name] = e.value;
    });
    const projectConfigLocal = {
      ...projectConfiguration,
      name: projectConfigurationInput.name,
      region: projectConfigurationInput.region,
    };
    setProjectConfiguration(projectConfigLocal);

    const reqObject: any = {
      projectName: projectConfigurationInput.name,
      region: projectConfigurationInput.region,
      s3URL: s3URL,
    };

    if (!skipGithubConnect) {
      reqObject.github = {
        repositoryName: repositoryNameLocal,
        private: privateRepo,
      };
    }

    const res: any = await deployUsingExternalS3(reqObject);

    if (res?.status === 200) {
      setJobId(res.data.jobID);
      setIsDeploying(true);
    }

    if (res.status === 200 && res.data.status === "ok") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setJobId(res.data.jobID);
      setIsDeploying(true);
    } else {
      console.log(res);
      alert(res.response.data.error.message);
    }
  };

  return (
    <Row>
      <Col sm={12} className="col-12">
        <Card>
          <Card.Body
            style={{ minHeight: "80vh", paddingTop: "20px" }}
            className="d-flex align-items-start justify-content-center w-100"
          >
            <>
              {!skipGithubConnect && !showProjectConfiguration ? (
                <>
                  <Row className="mt-4 w-100 d-flex justify-content-center" style={{ paddingTop: "6rem" }}>
                    <Col className="border rounded p-4" lg={8}>
                      {finishedLoading && (
                        <ConnectToGithubForkProject
                          handleNoGithub={() => setSkipGithubConnect(true)}
                          isConnectedToGithub={isConnectedToGithub}
                          handleHaveGithub={() => setShowProjectConfiguration(true)}
                          state={"s3-import"}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {projectConfiguration ? (
                    <>
                      {isDeploying ? (
                        // Deploy Progress
                        <div className="w-100" style={{ minHeight: "80vh" }}>
                          <Row className="mt-4 w-100">
                            <Sidebar repoName={projectName} branch={"main"} activeStep={2} basePath={"."} />
                            <DeployProgress jobID={jobId} projectName={projectConfiguration.name} />
                          </Row>
                        </div>
                      ) : (
                        // Project configuration and deploy
                        <Row className="mt-4 w-100">
                          <Sidebar repoName={projectName} branch={"main"} activeStep={1} basePath={"."} />
                          <ProjectConfiguration
                            githubRepositoryId={0}
                            handleDeployProject={handleDeployProject}
                            projectConfiguration={projectConfiguration}
                            repoName={projectName}
                            frameworkFrontendProps={""}
                            frameworkBackendProps={""}
                            isForkFlow={!skipGithubConnect}
                            githubAccountName={githubUserDetails.name}
                            githubUserRepos={githubUserRepos}
                            isGenezioDeployBtn={true}
                          />
                        </Row>
                      )}
                    </>
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </>
              )}
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default NewProjectS3UrlFlow;
