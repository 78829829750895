import { useState, useEffect } from "react";
import {
  getAllIntegrations,
  getProjectIntegrations,
  deactivateIntegration,
  addProjectIntegration,
  getCurrentCollaboratorDetails,
} from "../../network/ApiAxios";
import { Card, Row, Col, Button, Spinner, Form, Alert } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import IntegrationBreadcrumbs from "./IntegrationBreadcrumbs";
import useProjectDetails from "../../hooks/useProjectDetails";
import { CollaboratorDetails } from "../../models/CollaborationModels";

const SentryDetails = () => {
  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  const { project, activeEnv } = useProjectDetails(projectId || "", envId || "");
  const [sentryIntegration, setSentryIntegration] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [projectIntegrations, setProjectIntegrations] = useState<any>([]);
  const [sentryDSN, setSentryDSN] = useState("");
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const navigate = useNavigate();
  if (currentCollaboratorDetails.role === "collaborator" && activeEnv.name === "prod") {
    navigate(`/project/${projectId}/${envId}/integrations`);
  }

  useEffect(() => {
    fetchSentryIntegration();
    fetchActiveIntegrations();
  }, []);

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  const fetchSentryIntegration = async () => {
    setLoading(true);
    const res: any = await getAllIntegrations();
    if (res?.status === 200) {
      setSentryIntegration(res?.data?.integrations.filter((integration: any) => integration.name === "SENTRY")[0]);
    }
    setLoading(false);
  };

  const fetchActiveIntegrations = async () => {
    const res: any = await getProjectIntegrations(projectId || "", envId || "");
    if (res?.status === 200) {
      setProjectIntegrations(res?.data?.integrations);
    }
  };

  const toggleIntegration = async (isInstalled: boolean, edit?: boolean) => {
    setSentryDSN("");
    setLoading(true);
    isInstalled && !edit
      ? await deactivateIntegration(projectId ?? "", envId ?? "", sentryIntegration?.name)
      : await addProjectIntegration(projectId ?? "", envId ?? "", {
          integrationName: sentryIntegration?.name,
          envVars: [sentryDSN, "1.0"],
        });
    await fetchActiveIntegrations();
    setLoading(false);
  };

  const isInstalled = projectIntegrations.includes(sentryIntegration?.name) ? true : false;

  return (
    <>
      {/* Integrations Variables Card Content */}
      <Row className="mt-3">
        <Col sm={12}>
          {currentCollaboratorDetails.role === "collaborator" ? (
            <Alert variant="warning text-muted">You are unable to edit integrations as a collaborator</Alert>
          ) : (
            ""
          )}
          <Card style={{ padding: "0 0 59px 14px", minHeight: "75vh" }}>
            <Card.Body className="pt-0">
              {!loading && sentryIntegration && (
                <>
                  <h4 className="card-header card-title px-0 pb-0">{`${
                    isInstalled ? "MANAGE" : "INSTALL"
                  } ${sentryIntegration?.name} INTEGRATION`}</h4>
                  <p className="text-muted my-1" style={{ fontSize: "12px" }}>
                    {sentryIntegration?.description}
                  </p>
                </>
              )}

              {loading ? (
                <Row className="d-flex align-items-center justify-content-center my-5">
                  <Spinner variant="primary" animation="border" />
                </Row>
              ) : (
                <>
                  <p className="mb-2">
                    <span className="font-weight-bold">Status: </span> {isInstalled ? "connected" : "disconnected"}
                  </p>
                  <Row>
                    <Col lg={4} className="d-flex align-items-center">
                      <p className="mb-0">SENTRY_DSN:</p>
                      <Form.Control
                        disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                        style={{ height: "28px" }}
                        placeholder={isInstalled ? "**********" : ""}
                        onChange={(e) => setSentryDSN(e.target.value)}
                        className="form-control overflow-hidden mx-2"
                      />
                    </Col>

                    <Col lg={3} className="p-lg-0 mt-lg-0 mt-sm-3">
                      {isInstalled ? (
                        <>
                          <Button
                            style={{ marginRight: "10px" }}
                            variant="outline-success"
                            size="sm"
                            className="px-4"
                            disabled={sentryDSN || currentCollaboratorDetails.role !== "collaborator" ? false : true}
                            onClick={() => toggleIntegration(isInstalled, true)}
                          >
                            Update
                          </Button>
                          <Button
                            onClick={() => toggleIntegration(isInstalled)}
                            variant="outline-danger"
                            size="sm"
                            className="px-4"
                            disabled={currentCollaboratorDetails.role === "collaborator" ? true : false}
                          >
                            Disconnect
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="outline-success"
                          size="sm"
                          className="px-4"
                          disabled={sentryDSN || currentCollaboratorDetails.role !== "collaborator" ? false : true}
                          onClick={() => toggleIntegration(isInstalled)}
                        >
                          Connect
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SentryDetails;
