import React from "react";
import { Alert, Col, Row } from "react-bootstrap";

export const CLILoginSuccess = () => {
  return (
    <React.Fragment>
      <div className="page bg-primary">
        <div className="page-single">
          <div className="container" style={{ marginTop: "89px" }}>
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin">
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="">
                        <div className="main-signup-header">
                          <h2 className="text-center mb-4">CLI Login in progress</h2>
                          <Alert variant="success">
                            Login successful. You can close this window now and return to your terminal.
                          </Alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
