import { useState, useEffect } from "react";
import { Col, Row, Card, Breadcrumb, Spinner, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import useProjectDetails from "../../hooks/useProjectDetails";
// import NoCollaborators from "./NoCollaborators";
import CollaboratorsList from "./CollaboratorsList";
import AddCollaboratorModal from "../../components/AdvancedUI/Modals/AddCollaboratorModal";
import RemoveCollaboratorModal from "../../components/AdvancedUI/Modals/RemoveCollaboratorModal";
import ChangeCollaborationRoleModal from "../../components/AdvancedUI/Modals/ChangeCollaboratorRoleModal";
import { getCollaborators, getCollaborationRoles, getCurrentCollaboratorDetails } from "../../network/ApiAxios";
import { GetCollaboratorsRolesResponse, GetCollaborators, CollaboratorDetails } from "../../models/CollaborationModels";
import { ToastContainer } from "react-toastify";

const collaborationEnum = { projectId: "", envId: "" };

const Collaboration = () => {
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });
  const [owner, setOwner] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [addCollaboratorModal, setAddCollaboratorModal] = useState(false);
  const [removeCollaboratorModal, setRemoveCollaboratorModal] = useState<boolean>(false);
  const [changeCollaboratorRoleModal, setChangeCollaboratorRoleModal] = useState<boolean>(false);
  const [selectedRemoveCollaborator, setSelectedRemoveCollaborator] = useState<any>({});
  const [selectedChangeRoleCollaborator, setSelectedChangeRoleCollaborator] = useState<any>({});
  const [selectedRole, setSelectedRole] = useState<any>("");
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  collaborationEnum.projectId = projectId || collaborationEnum.projectId;
  collaborationEnum.envId = envId || collaborationEnum.envId;
  const { project, activeEnv } = useProjectDetails(projectId || "", envId || "");

  const navigate = useNavigate();

  const fetchCollaborators = async () => {
    setLoading(true);
    const res: GetCollaborators = await getCollaborators(projectId || "");
    if (res.status === 200 && res.data.status === "ok") {
      setCollaborators(res.data.collaborators);
      setInvitations(res.data.invitations);
      setOwner(res.data.owner);
    }
    setLoading(false);
  };

  const fetchCollaborationRoles = async () => {
    const res: GetCollaboratorsRolesResponse = await getCollaborationRoles();
    if (res.status === 200 && res.data.status === "ok") {
      setRoles(res.data.roles);
    }
  };

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  const handleRemoveCollaborator = (collaborator: any) => {
    setSelectedRemoveCollaborator(collaborator);

    setRemoveCollaboratorModal(true);
  };

  const handleChangeRoleCollaborator = (collaborator: any, role: string) => {
    setSelectedChangeRoleCollaborator(collaborator);
    setSelectedRole(role);
  };

  useEffect(() => {
    fetchCollaborators();
    fetchCollaborationRoles();
    fetchCurrentCollaboratorDetails();
  }, []);

  useEffect(() => {
    document.title = "Genezio | Collaboration";
  }, []);

  return (
    <>
      <ToastContainer />
      {/* Add collaborator modal */}
      <AddCollaboratorModal
        open={addCollaboratorModal}
        handleCollaboratorModal={(state: boolean) => setAddCollaboratorModal(state)}
        projectCollaborators={[owner, ...collaborators, ...invitations]}
        fetchCollaborators={() => fetchCollaborators()}
        roles={roles}
        project={project}
      />
      {/* Remove collaborator modal */}
      <RemoveCollaboratorModal
        open={removeCollaboratorModal}
        handleCollaboratorModal={(state: boolean) => setRemoveCollaboratorModal(state)}
        fetchCollaborators={() => fetchCollaborators()}
        currentCollaboratorDetails={currentCollaboratorDetails}
        collaborator={selectedRemoveCollaborator}
      />
      {/* Change collaborator role modal */}
      <ChangeCollaborationRoleModal
        handleRoleChangeModal={(state: boolean) => setChangeCollaboratorRoleModal(state)}
        fetchCollaborators={() => fetchCollaborators()}
        fetchCollaboratorDetails={() => fetchCurrentCollaboratorDetails()}
        open={changeCollaboratorRoleModal}
        collaborator={selectedChangeRoleCollaborator}
        selectedRole={selectedRole}
      />
      <Row className="mt-3">
        <Col sm={12}>
          <Card>
            <Card.Body className="pt-0">
              <Row className="pt-3 pb-1">
                <Col md={5} className="d-flex justify-content-between align-items-center">
                  <Card.Header className="pb-0">
                    <h5 className="card-title" style={{ textTransform: "none" }}>
                      Manage access
                    </h5>
                  </Card.Header>
                  {currentCollaboratorDetails.role !== "collaborator" ? (
                    <Button className="mt-2" size="sm" onClick={() => setAddCollaboratorModal(true)}>
                      Invite collaborators
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Card.Body>
                {loading ? (
                  <Row>
                    <Col md={5} className="d-flex justify-content-center">
                      <Spinner variant="primary" animation="border" />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <CollaboratorsList
                      setSelectedRemoveCollaborator={(collaborator: any) => handleRemoveCollaborator(collaborator)}
                      setSelectedChangeRole={(collaborator: any, role: any) =>
                        handleChangeRoleCollaborator(collaborator, role)
                      }
                      currentCollaboratorDetails={currentCollaboratorDetails}
                      collaborators={collaborators}
                      invitations={invitations}
                      owner={owner}
                      roles={roles}
                      fetchCollaborators={() => fetchCollaborators()}
                      fetchCollaboratorDetails={() => fetchCurrentCollaboratorDetails()}
                      handleRoleChangeModal={(state: boolean) => setChangeCollaboratorRoleModal(state)}
                    />
                  </>
                )}
              </Card.Body>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Collaboration;
