import { useState } from "react";
import { Button, Modal, Spinner, Form, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { createNeonProject } from "../../../network/ApiAxios";
import { regions, postgresVersion, sendToast } from "../../../views/Integrations/utils";
import { AddNeonProjectModalProps, CreateNeonProjectFormData } from "../../../models/IntegrationModels";

const AddNeonProjectModal: React.FC<AddNeonProjectModalProps> = ({ show, hideModal, handleProjectSelectDefault }) => {
  const [projectData, setProjectData] = useState<CreateNeonProjectFormData>({
    name: "",
    pg_version: 14,
    region_id: "aws-us-east-1",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (name: string, value: string) => {
    setProjectData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const res: any = await createNeonProject(projectData);
    setIsLoading(false);

    if (res?.response?.data?.error.code && res.response.data.error.message) {
      sendToast(res.response.data.error.message, "error");
      return;
    }

    if (res.data) {
      handleProjectSelectDefault(res?.data?.project.project);
    }

    toast.success("Project Created", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    handleHideModal();
  };

  const handleHideModal = () => {
    hideModal();
    setProjectData({
      name: "",
      pg_version: 14,
      region_id: "aws-us-east-1",
    });
  };

  return (
    <Modal show={show} onHide={() => handleHideModal()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "30px" }}>
        <h6 className="mb-3" style={{ fontWeight: "600" }}>
          Create Project
        </h6>

        <p style={{ fontWeight: "500" }} className="mb-1">
          Name
        </p>
        <FormGroup className="form-group">
          <Form.Control
            type="text"
            className="form-control"
            placeholder="Will be autogenerated if left blank"
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        </FormGroup>

        <p style={{ fontWeight: "500" }} className="mb-1">
          Postgres Version
        </p>
        <FormGroup className="form-group">
          <Select
            options={postgresVersion}
            onChange={(e: any) => handleInputChange("pg_version", e.value)}
            placeholder="Postgres Version"
            classNamePrefix="selectform"
            value={postgresVersion.find((option) => option.value === projectData.pg_version)}
          />
        </FormGroup>

        <p style={{ fontWeight: "500" }} className="mb-1">
          Region
        </p>
        <FormGroup className="form-group">
          <Select
            options={regions}
            onChange={(e: any) => handleInputChange("region_id", e.value)}
            placeholder="Region"
            classNamePrefix="selectform"
            value={regions.find((option) => option.value === projectData.region_id)}
          />
        </FormGroup>

        <div className="mt-4 d-flex align-items-center justify-content-end">
          <Button onClick={() => handleHideModal()} className="border rounded py-1 px-4 mx-2 env-vars-btn">
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            className="btn py-1 px-4 d-flex align-items-center justify-content-center"
            disabled={isLoading}
          >
            Create Project
            {isLoading && <Spinner className="ms-2" animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNeonProjectModal;
