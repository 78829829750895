import React from "react";
import { Button, Modal, Alert } from "react-bootstrap";

type DatabaseInfoModalProps = {
  show: boolean;
  onHide: () => void;
};

const DatabaseInfo: React.FC<DatabaseInfoModalProps> = ({ show, onHide }) => {
  const handleClose = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      style={{ borderRadius: "50px" }}
      className="smaller-modal-width"
      centered
    >
      <Modal.Body style={{ padding: "30px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Database
        </h3>

        <Alert variant="success" style={{ color: "#842029" }}>
          <p className="text-dark">The database was created and linked to the current project successfully.</p>
        </Alert>

        <p className="text-muted  mb-3" style={{ fontWeight: "500" }}>
          An environment variable has been added to your project. To ensure everything works correctly, please open your
          local backend/.env file and manually add this new environment variable there.
        </p>

        <hr style={{ borderColor: "#E5E5E5" }} />
        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleClose()}>
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DatabaseInfo;
