import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import { RemoveCollaboratorModalProps, RemoveCollaborationResponse } from "../../../models/CollaborationModels";
import { removeCollaborator } from "../../../network/ApiAxios";
import { sendToast } from "../../../views/Integrations/utils";

const RemoveCollaboratorModal: React.FC<RemoveCollaboratorModalProps> = ({
  open,
  collaborator,
  handleCollaboratorModal,
  fetchCollaborators,
  currentCollaboratorDetails,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const selfRemove = currentCollaboratorDetails.email === collaborator.email ? true : false;

  const navigate = useNavigate();

  const handleCancel = () => {
    handleCollaboratorModal(false);
  };

  const handleRemove = async () => {
    setLoading(true);

    const res: RemoveCollaborationResponse = await removeCollaborator(collaborator.id);
    if (res.status === 200 && res.data.status === "ok") {
      handleCancel();
      fetchCollaborators();

      if (selfRemove) {
        navigate("/dashboard");
      }
    } else {
      sendToast("Unable to remove collaborator", "error");
    }
    setLoading(false);
  };

  return (
    <Modal show={open} onHide={() => handleCancel()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "30px" }}>
        <h3 className="mb-3" style={{ fontSize: "24px", lineHeight: "22px" }}>
          {selfRemove ? "Leave Project" : "Delete Collaborator"}
        </h3>

        <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />

        <Alert variant="danger">
          This action will permanently delete{" "}
          <b>{selfRemove ? "You" : collaborator.name ? collaborator.name : collaborator.email}</b> from the project!
        </Alert>

        <p className="mb-2">
          {selfRemove ? (
            "Are you sure you want to leave this project?"
          ) : (
            <>
              Are you sure you want to remove: <b>{collaborator.name ? collaborator.name : collaborator.email}</b>?
            </>
          )}
        </p>

        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleCancel()}>
            Close
          </Button>
          <Button disabled={loading} className="px-4" size="sm" onClick={() => handleRemove()}>
            {selfRemove ? "Leave" : "Remove"}
            {loading && <Spinner className="ms-2" animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveCollaboratorModal;
