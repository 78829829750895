import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Breadcrumb, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function SubscriptionCallback() {
  const navigation = useNavigate();
  const [msgStr, setMsgStr] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // get "success" from query params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get("success");

    if (success === "true") {
      const session_id = urlParams.get("session_id");
      setSuccessMsg("Subscription successful. You will be redirected to the billing page in 5 seconds.");
    } else {
      setErrorMsg("Subscription failed. You will be redirected to the billing page in 5 seconds.");
    }
    setTimeout(() => {
      navigation("/billing");
    }, 5000);
  }, []);

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="breadcrumb-item"
              onClick={() => navigation("/")}
              aria-current="page"
              style={{ paddingTop: "1px" }} // hack don;t change it
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item" aria-current="page" onClick={() => navigation("/billing")}>
              Billing
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item" aria-current="page" active>
              Subscription Response
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h5 className="card-title" style={{ textTransform: "none", color: "#333333" }}>
                Subscription Response
              </h5>
            </Card.Header>
            <Card.Body className="pt-0 example1-table">
              {successMsg ? (
                <Alert key="success" variant="success">
                  {successMsg}
                </Alert>
              ) : null}
              {errorMsg ? (
                <Alert key="danger" variant="danger">
                  {errorMsg}
                </Alert>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
