import React, { useEffect, useState } from "react";
import {
  filterFns,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Col, Card, Button, InputGroup, Form, Row, Spinner, DropdownButton, Dropdown } from "react-bootstrap";
import { PROJECT_TABLE_COLUMNS } from "../../configs/DashboardConfig";
import { CollaboratorDetails } from "../../models/CollaborationModels";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectById, getCurrentCollaboratorDetails, changeClassStatus } from "../../network/ApiAxios";

const GlobalFilter = (props: { filter: string; setFilter: any; tableInstance: any; setPageCount: any }) => {
  return (
    <Col md={3} className="p-0">
      <span className="d-flex ms-auto ">
        <InputGroup className="mb-3">
          <InputGroup.Text>
            <i className="fa fa-search"></i>
          </InputGroup.Text>
          <Form.Control
            value={props.filter || ""}
            onChange={async (e) => {
              props.setFilter(e.target.value);
              await props.tableInstance.setGlobalFilter(e.target.value);
              if (props.tableInstance.getPageCount() === 0) {
                props.setPageCount(1);
                props.tableInstance.setPageIndex(0);
              } else {
                props.setPageCount(props.tableInstance.getPageCount());
                if (props.tableInstance.getState().pagination.pageIndex >= props.tableInstance.getPageCount()) {
                  props.tableInstance.setPageIndex(props.tableInstance.getPageCount() - 1);
                }
              }
            }}
            placeholder="Search..."
          />
        </InputGroup>
      </span>
    </Col>
  );
};

interface BackendProps {
  isFunction?: boolean;
}

const Backend: React.FC<BackendProps> = ({ isFunction }) => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const navigate = useNavigate();
  const [project, setProject] = useState<any>({});
  const [pageCount, setPageCount] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");
  const [classes, setClasses] = useState<any>([]);
  const [functions, setFunctions] = useState<any>([]);
  const [error, setError] = React.useState("");
  const [activeEnv, setActiveEnv] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isChangingStatus, setIsChangingStatus] = React.useState(false);
  const [tooltipTexts, setTooltipTexts] = useState<any>({});

  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = React.useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const pageSize: number = 25;

  const tableInstance = useReactTable({
    columns: PROJECT_TABLE_COLUMNS,
    data: isFunction ? functions : classes,
    globalFilterFn: filterFns.includesString,
    initialState: { pagination: { pageIndex: 0, pageSize } },
    autoResetAll: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const runAsyncGetProjectInfo = async () => {
    const res: any = await getProjectById(projectId ?? "");
    if (res.data && res.data.status === "ok") {
      const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
      setActiveEnv(localActiveEnv);
      setProject(res.data.project);
      setClasses(localActiveEnv.classes);
      setFunctions(localActiveEnv.functions);
      let pgCt: number;
      if (isFunction) {
        pgCt = Math.ceil(localActiveEnv.functions.length / pageSize);
      } else {
        pgCt = Math.ceil(localActiveEnv.classes.length / pageSize);
      }
      setPageCount(pgCt);
      // setClasses(localActiveEnv.classes);
      setIsLoading(false);
    } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    runAsyncGetProjectInfo();
  }, []);

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  const handleChangeStatus = async (status: string, classInfo: any) => {
    if (status !== "active" && status !== "paused") {
      return;
    }

    setIsChangingStatus(true);
    setError("");
    const response = await changeClassStatus(classInfo.id ?? "", status, isFunction);

    if (response?.data?.status === "ok") {
      runAsyncGetProjectInfo();
    } else {
      setError(response.data.error.message);
    }
    setIsChangingStatus(false);
  };

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  const handleClick = (id: string, cloudUrl: string) => {
    navigator.clipboard.writeText(cloudUrl);
    setTooltipTexts((prev: any) => ({ ...prev, [id]: "Copied to clipboard!" }));
    setTimeout(() => {
      setTooltipTexts((prev: any) => ({ ...prev, [id]: cloudUrl }));
    }, 2000);
  };

  function truncateString(str: string) {
    if (str.length > 40) {
      return str.substring(0, 40) + "...";
    } else {
      return str;
    }
  }

  return (
    <Row className="mt-3">
      <Col sm={12} className="col-12">
        <Card>
          <Card.Body className="pt-0 p-0 example1-table">
            <div className="table-responsive">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <Card.Header>
                  <h4 className="card-title m-0">{isFunction ? "Functions" : "Classes"}</h4>
                </Card.Header>
                <GlobalFilter
                  filter={filter}
                  setFilter={setFilter}
                  tableInstance={tableInstance}
                  setPageCount={setPageCount}
                />
              </div>
              <div style={{ padding: "0 20px 0 20px" }}>
                <table className="table text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>
                        <span className="tabletitle">#</span>
                      </th>
                      <th>
                        <span className="tabletitle">{isFunction ? "Function" : "Class"} Name</span>
                      </th>
                      {isFunction && (
                        <th>
                          <span className="tabletitle">URL</span>
                        </th>
                      )}
                      <th>
                        <span className="tabletitle">Last Edit Date</span>
                      </th>
                      <th>
                        <span className="tabletitle">Created Date</span>
                      </th>
                      <th>
                        <span className="tabletitle">Status</span>
                      </th>

                      <th>
                        <span className="tabletitle">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableInstance.getRowModel().rows.map((row: any, index: number) => {
                      const elems = row.original;
                      return (
                        <tr key={elems.name}>
                          <td>{index + 1}</td>
                          <td>{elems.name}</td>
                          {isFunction && (
                            <td>
                              <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                                <p
                                  onClick={() => window.open(elems.cloudUrl, "_blank")}
                                  className="text-decoration-underline"
                                  style={{ cursor: "pointer", margin: "0" }}
                                >
                                  {truncateString(tooltipTexts[elems.id] || elems.cloudUrl)}{" "}
                                </p>

                                <img
                                  onClick={() => handleClick(elems.id, elems.cloudUrl)}
                                  className="mx-2"
                                  style={{
                                    cursor: "pointer",
                                    filter: darkMode
                                      ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                                      : "none",
                                  }}
                                  src={
                                    require("./../../assets/iconfonts/fontawesome-free/svgs/regular/copyUrl.svg")
                                      .default
                                  }
                                  alt="copyUrlIcon"
                                />
                              </div>
                            </td>
                          )}
                          <td>{moment.unix(elems.updatedAt).fromNow()}</td>
                          <td>{moment.unix(elems.createdAt).format("MMM D, YYYY [at] h:mm A")}</td>
                          <td
                            style={{
                              color: elems.status === "active" ? "#62C353" : "#FF0000",
                            }}
                          >
                            {elems.status.toUpperCase()}
                          </td>

                          <td>
                            <DropdownButton
                              size="sm"
                              id="dropdown-basic-button"
                              title="Actions"
                              variant="light"
                              autoClose={true}
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleChangeStatus("active", elems)}
                                disabled={
                                  isChangingStatus ||
                                  elems.status === "active" ||
                                  (activeEnv.name === "prod" && currentCollaboratorDetails.role === "collaborator")
                                    ? true
                                    : false
                                }
                              >
                                Activate
                              </Dropdown.Item>
                              {/* collaborators on prod cant pause */}
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleChangeStatus("paused", elems)}
                                disabled={
                                  isChangingStatus ||
                                  elems.status === "paused" ||
                                  (activeEnv.name === "prod" && currentCollaboratorDetails.role === "collaborator")
                                    ? true
                                    : false
                                }
                              >
                                Pause
                              </Dropdown.Item>
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {isLoading && (
                  <Col lg={12} style={{ textAlign: "center" }} className="my-3">
                    <Spinner variant="primary" animation="border" />
                  </Col>
                )}
                {tableInstance.getPageOptions().length === 0 && isLoading === false ? (
                  <Col lg={12} style={{ textAlign: "center" }} className="my-3">
                    There are no classes added yet.
                  </Col>
                ) : null}
                <div className="d-block d-sm-flex mt-4 ">
                  <span className="">
                    Page{" "}
                    <strong>
                      {tableInstance.getState().pagination.pageIndex + 1} of {pageCount}
                    </strong>{" "}
                  </span>
                  <span className="ms-sm-auto d-flex">
                    <Button
                      variant=""
                      className="btn-default tablebutton me-2 my-1"
                      onClick={() => {
                        tableInstance.setPageIndex(0);
                      }}
                      disabled={!tableInstance.getCanPreviousPage()}
                    >
                      {" << "}
                    </Button>
                    <Button
                      variant=""
                      className="btn-default tablebutton me-2 my-1"
                      onClick={() => {
                        tableInstance.previousPage();
                      }}
                      disabled={!tableInstance.getCanPreviousPage()}
                    >
                      {" < "}
                    </Button>
                    <Button
                      variant=""
                      className="btn-default tablebutton me-2 my-1"
                      onClick={() => {
                        tableInstance.nextPage();
                      }}
                      disabled={!tableInstance.getCanNextPage()}
                    >
                      {" > "}
                    </Button>
                    <Button
                      variant=""
                      className="btn-default tablebutton me-2 my-1"
                      onClick={() => {
                        tableInstance.setPageIndex(pageCount - 1);
                      }}
                      disabled={!tableInstance.getCanNextPage()}
                    >
                      {" >> "}
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Backend;
