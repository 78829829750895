import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import EmailTemplates from "./EmailTemplates";
import GeneralSettings from "./GeneralSettings";
import { SettingsTabProps } from "../../models/AuthModels";

const tabs = ["General Settings", "Email Templates"];

const SettingsTab: React.FC<SettingsTabProps> = ({
  generalSettingsData,
  envId,
  handleDisableAuth,
  databases,
  toggleDatabaseModal,
  GeneralSettingsSelectedDbValue,
  setGeneralSettingsSelectedDbValue,
  fetchAuth,
}) => {
  const [activeTab, setActiveTab] = useState("General Settings");

  return (
    <Row>
      <Col style={{ height: "50vh", borderRight: "1px solid #F2F2F2" }} className="col-5 col-md-2">
        <div className="mx-4" id="controlled-tab-example">
          {tabs.map((tab: string) => {
            const isActiveTab = tab === activeTab;
            return (
              <p
                style={{ cursor: "pointer" }}
                className={`${isActiveTab ? "fw-bold" : "fw-semibold text-muted"}`}
                key={tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </p>
            );
          })}
        </div>
      </Col>

      <Col className="mx-4 col-5 col-md-9">
        {activeTab === "General Settings" ? (
          <GeneralSettings
            envId={envId}
            fetchAuth={fetchAuth}
            generalSettingsData={generalSettingsData}
            handleDisableAuth={handleDisableAuth}
            databases={databases}
            toggleDatabaseModal={toggleDatabaseModal}
            GeneralSettingsSelectedDbValue={GeneralSettingsSelectedDbValue}
            setGeneralSettingsSelectedDbValue={(e) => setGeneralSettingsSelectedDbValue(e)}
          />
        ) : (
          <EmailTemplates envId={envId} />
        )}
      </Col>
    </Row>
  );
};

export default SettingsTab;
