import { Col, Row } from "react-bootstrap";
import PlaceholderLoading from "react-placeholder-loading";

export default function BillingBenefits(props: any) {
  const freePlanCondition = props.subscriptionPlan.price === 0 ? 0 : 1;
  return (
    <div>
      {!props.loadingPlans ? (
        <>
          {/* {freePlanCondition ? (
            <Row>
              <Col sm={11} className="planBadge d-flex justify-content-center align-items-center">
                <p>{props.subscriptionPlan.name === "Professional" ? "Hobby" : "Professional"} Plan +</p>
              </Col>
            </Row>
          ) : (
            ""
          )} */}
          {/* {props.subscriptionPlan.benefits?.slice(freePlanCondition, 3).map((item: any) => {
            return (
              <Row className="billing-col-subscription-checklist-row-border-none">
                <Col sm={11} className="billing-col-subscription-checklist-right-elem ">
                  <i className="fa fa-check"></i>
                  <span>{item}</span>
                </Col>
              </Row>
            );
          })} */}

          {props.subscriptionPlan.benefits?.map((item: any, idx: any) => {
            return (
              <Row
                className={`billing-col-subscription-checklist-row ${
                  idx === 3 ? "" : "plansBorderBottom"
                } d-flex justify-content-center align-items-center`}
              >
                <Col sm={10} className="billing-col-subscription-checklist-right-elem text-center">
                  <span>{item}</span>
                  {idx === 0 && (
                    <p
                      style={{
                        color: "black",
                        fontSize: "14px",
                        textAlign: "center",
                        fontWeight: "600",
                      }}
                      className="mt-4"
                    >
                      What’s included{" "}
                      <span className="m-0" style={{ color: "#6f42c1", fontWeight: "600" }}>
                        ?
                      </span>
                    </p>
                  )}
                </Col>
              </Row>
            );
          })}
        </>
      ) : (
        <>
          {Array(10)
            .fill(0)
            .map((_, i) => {
              return (
                <Row className="billing-col-subscription-checklist-row">
                  {/* react-loding-placeholder */}
                  <PlaceholderLoading shape="rect" width={250} height={25} />
                </Row>
              );
            })}
        </>
      )}
    </div>
  );
}
