import React from "react";
import { useNavigate } from "react-router-dom";

const RedirectLoginCLI = () => {
  let navigate = useNavigate();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect_url: string = urlParams.get("redirect_url")?.toString() || "";
    if (!redirect_url) {
      navigate("/auth/login");
      return;
    }
    localStorage.setItem("redirect_url_cli_login", redirect_url);
    navigate("/clilogin");
  }, []);

  return <div></div>;
};

export default RedirectLoginCLI;
