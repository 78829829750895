import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { getDeploymentDetails, getAllDeployments, getLinkedRepositories } from "../../network/ApiAxios";
import DeploymentCard from "./components/DeploymentCard";
import { sendToast } from "../../views/Integrations/utils";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import moment from "moment";

const DeploymentsHistoryDetails = () => {
  const { projectId, envId, deploymentId } = useParams<{ projectId?: string; envId: string; deploymentId: string }>();
  const [deploymentDetails, setDeploymentDetails] = useState<any>();
  const [current, setCurrent] = useState(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true); // Only for initial load spinner
  const [logs, setLogs] = useState<any>([]);
  const [logsLoading, setLogsLoading] = useState<boolean>(false);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);
  const [linkedRepos, setLinkedRepos] = useState([]);

  const fetchDeploymentDetails = async (isFirstLoad: boolean = false) => {
    if (deploymentId) {
      if (isFirstLoad) {
        setInitialLoading(true); // Show spinner only on the first load
      }
      const res: any = await getDeploymentDetails(deploymentId);

      if (res.status === 200 && res.data.status === "ok") {
        setDeploymentDetails(res.data);
        if (isFirstLoad) {
          setInitialLoading(false); // Hide spinner after first load
        }

        return;
      }

      sendToast(res?.response?.data?.error?.message || "", "error");
      if (isFirstLoad) {
        setInitialLoading(false);
      }
    }
  };

  async function fetchLogs() {
    try {
      setLogsLoading(true);
      const response = await axios.get(deploymentDetails?.presignedURL);
      setLogsLoading(false);
      setLogs(response.data);
    } catch (error) {
      setLogsLoading(false);
      sendToast("Error on fetching build logs" || "", "Error on fetching build logs");
    }
  }

  const fetchAllDeployments = async () => {
    if (envId) {
      const res: any = await getAllDeployments(envId);
      if (res.status === 200 && res.data.status === "ok") {
        const deployments = res.data.deployments?.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        const lastSucceededDeployment: any = deployments
          ?.slice()
          ?.find((deployment: any) => deployment.status === "SUCCEEDED");
        const isLastSucceeded = lastSucceededDeployment?.id === deploymentId;
        setCurrent(isLastSucceeded);
      }
    }
  };

  // Polling mechanism to check the deployment status every 3 seconds
  const startPolling = () => {
    if (!pollingInterval) {
      const intervalId = setInterval(async () => {
        await fetchDeploymentDetails(); // Call without setting loading state
        await fetchAllDeployments();
        if (
          deploymentDetails?.deployment?.status === "SUCCEEDED" ||
          deploymentDetails?.deployment?.status === "FAILED"
        ) {
          clearInterval(intervalId);
        }
      }, 4000);
      setPollingInterval(intervalId);
    }
  };

  const fetchLinkedRepos = async () => {
    const res: any = await getLinkedRepositories(projectId);

    if (res.status === 200 && res.data.status === "ok") {
      setLinkedRepos(res.data.repositories);
      return;
    }
    if (res?.response?.data?.error.code !== 3) {
      sendToast(res?.response?.data?.error?.message || "Unable to fetch linked repositories", "error");
    }
  };

  useEffect(() => {
    fetchLinkedRepos();
  }, []);

  useEffect(() => {
    document.title = "Genezio | Deployments History Details";
  }, []);

  useEffect(() => {
    fetchDeploymentDetails(true); // First call with loading state
    startPolling(); // Start polling when component is mounted
    return () => {
      if (pollingInterval) clearInterval(pollingInterval); // Clear polling on unmount
    };
  }, []);

  useEffect(() => {
    if (deploymentDetails) {
      fetchLogs();
    }
  }, [deploymentDetails]);

  useEffect(() => {
    fetchAllDeployments();
  }, []);

  return (
    <Row className="mt-3">
      <ToastContainer />
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header>
            <h4 className="card-title">deployments history</h4>
          </Card.Header>
          <Card.Body className="pt-0 example1-table">
            {initialLoading ? (
              // Show spinner only on first load
              <Row className="d-flex align-items-center justify-content-center py-2">
                <Spinner variant="primary" animation="border" />
              </Row>
            ) : deploymentDetails?.deployment ? (
              <>
                <DeploymentCard
                  projectId={projectId}
                  envId={envId}
                  deployment={deploymentDetails?.deployment}
                  disableLink={true}
                  currentDeploy={current}
                  linkedRepo={linkedRepos}
                />

                {logs && typeof logs === "string" ? ( // we need this check for old logs
                  <SyntaxHighlighter
                    language="bash"
                    style={dracula}
                    customStyle={{
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {logs}
                  </SyntaxHighlighter>
                ) : logs && logs.length > 0 ? (
                  <SyntaxHighlighter
                    language="bash"
                    style={dracula}
                    customStyle={{
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {logs
                      .map((item: any) => {
                        const date = moment(item.TransitionTime).format("YYYY-MM-DD HH:mm:ss");
                        const logContent = item.Stderr !== "" ? item.Stderr : item.Stdout;
                        return `${date} - ${item.Reason}\n${logContent}`;
                      })
                      .join("")}
                  </SyntaxHighlighter>
                ) : (
                  <p className="text-center fw-bold my-4">There are no logs at the moment.</p>
                )}
              </>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default DeploymentsHistoryDetails;
