import { useState, useEffect } from "react";
import { Button, Modal, Spinner, Col } from "react-bootstrap";
import { getRepositoryTree } from "../../../network/ApiAxios";
import { ProjectFolderModalProps } from "../../../models/NewProjectFlowModels";

// Directory component
const Directory = (props: any) => {
  const { dir, parentPath, onSelect, firstOne, selectedPath } = props;
  const [isOpen, setIsOpen] = useState(firstOne || false);
  const fullPath = firstOne ? "./" : `${parentPath}${dir.name}/`;

  const toggleOpen = () => {
    handleSelect();
    if (firstOne) return;
    setIsOpen(!isOpen);
  };

  const handleSelect = () => {
    console.log("handleSelect", fullPath);
    onSelect(fullPath);
  };

  return (
    <div style={{ marginLeft: "20px" }}>
      <div className="py-1" onClick={toggleOpen} style={{ cursor: "pointer", fontWeight: "bold" }}>
        <div onClick={toggleOpen} className="d-flex">
          <label className="radiobox">
            <input onClick={() => handleSelect()} type="radio" checked={selectedPath === fullPath} /> <span></span>
          </label>
          <p className="mx-1 mb-0">
            {isOpen ? <i className="fa fa-folder-open"></i> : <i className="fa fa-folder"></i>} {dir.name}
          </p>
        </div>
      </div>
      {isOpen && dir.subdirs && dir.subdirs.length > 0 && (
        <div>
          {dir.subdirs.map((subdir: any, index: any) => (
            <Directory
              key={index}
              dir={subdir}
              parentPath={`${fullPath}`}
              onSelect={onSelect}
              selectedPath={selectedPath}
              firstOne={false}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Modal
const ProjectFolderModal: React.FC<ProjectFolderModalProps> = ({
  show,
  onHide,
  githubRepositoryId,
  onSelect,
  selectedPath,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [repoTree, setRepoTree] = useState();
  const [localPath, setLocalPath] = useState(selectedPath);

  const fetchRepoTree = async () => {
    setLoading(true);
    const res = await getRepositoryTree(githubRepositoryId);
    if (res.status === 200 && res.data.status === "ok") {
      setRepoTree(res.data.tree);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRepoTree();
  }, []);

  return (
    <Modal show={show} onHide={() => onHide()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "40px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Root Directory
        </h3>
        <p className="mb-4 text-muted">Select the directory where your source code is located.</p>
        {loading ? (
          <Col className="d-flex justify-content-center">
            <Spinner variant="primary" animation="border" />
          </Col>
        ) : (
          <Directory dir={repoTree} parentPath="" onSelect={setLocalPath} firstOne={true} selectedPath={localPath} />
        )}
        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" onClick={() => onHide()}>
            Close
          </Button>
          <Button
            variant="primary"
            className="px-4"
            onClick={() => {
              onSelect(localPath);
              onHide();
            }}
          >
            Continue
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectFolderModal;
