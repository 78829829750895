import { Button, Modal, Spinner } from "react-bootstrap";

interface DeleteEnvProps {
  envName: string;
  isDeleting: boolean;
  onHide: () => void;
  handleDelete: () => void;
  isStored: boolean;
}

const DeleteEnv: React.FC<DeleteEnvProps> = ({ envName, onHide, handleDelete, isDeleting, isStored }) => {
  if (!isStored) {
    handleDelete();
    onHide();
  }

  return (
    <Modal show={true} onHide={() => onHide()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "54px 51px 54px 51px" }}>
        <div className="d-flex flex-column align-items-center tx-center">
          <h3 className="font-weight-normal mb-4" style={{ fontSize: "18px", lineHeight: "22px" }}>
            Delete Env
          </h3>
          <h4 className="text-danger" style={{ fontWeight: "600", fontSize: "12px", lineHeight: "16px" }}>
            This action will permanently delete your env variable! Data cannot be restored.
          </h4>
          <p className="mb-0 mt-1 mx-auto text-center" style={{ maxWidth: "420px", fontSize: "12px" }}>
            Are you sure you want to delete variable <b>{envName}</b>? You will not be able to use it anymore.
          </p>
          <Button
            variant="outline-danger"
            className="p-0 mt-4"
            style={{
              height: "30px",
              maxWidth: "120px",
              margin: "0 auto",
              width: "100%",
            }}
            disabled={isDeleting}
            onClick={() => handleDelete()}
          >
            Delete {isDeleting && <Spinner animation="border" className="ms-2" size="sm" />}
          </Button>{" "}
          <p
            onClick={() => onHide()}
            className="p-0 mt-3"
            style={{
              fontSize: "12px",
              color: "#999999",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            Cancel
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteEnv;
