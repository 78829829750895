import { useState } from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";

type DeleteProps = {
  projectName: string;
  show: boolean;
  onHide: () => void;
  handleDeleteProject: (toBeDeleted: any) => void;
};

const DeleteProject: React.FC<DeleteProps> = ({ show, onHide, projectName, handleDeleteProject }) => {
  const [inputDeleteName, setInputDeleteName] = useState<string>("");

  const handleClose = () => {
    setInputDeleteName("");
    onHide();
  };

  const handleDelete = () => {
    setInputDeleteName("");
    handleDeleteProject(projectName);
    onHide();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "40px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Delete Project
        </h3>
        <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />
        <p className="mb-2">
          Are you sure you want to delete: <b>{projectName}</b>?
        </p>
        <Alert variant="danger">This action will permanently delete your project! Data cannot be restored.</Alert>
        <p className="mb-2">Enter the name of the project if you want to proceed:</p>
        <Form.Control value={inputDeleteName || ""} onChange={(e) => setInputDeleteName(e.target.value)} />
        <hr style={{ borderColor: "#E5E5E5" }} />
        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            variant="danger"
            className="px-4"
            size="sm"
            disabled={inputDeleteName !== projectName}
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteProject;
