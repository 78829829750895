import { useState, useEffect } from "react";
import { Spinner, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import InfoTooltip from "../../../components/Tooltips/InfoTooltip";
// import ProjectFolderModal from "./ProjectFolderModal";
import {
  parseEnv,
  FrameworkSettingsFrontend,
  mapFrameworkFrontendToSettings,
  frameworks,
  FunctionConfig,
  backendFrameworks,
} from "../utils";
import { getProjectsForUser } from "../../../network/ApiAxios";
import { ProjectConfigurationProps } from "../../../models/NewProjectFlowModels";
import ProjectFolderModal from "./ProjectFolderModal";

const ProjectConfiguration: React.FC<ProjectConfigurationProps> = ({
  githubRepositoryId,
  handleDeployProject,
  projectConfiguration,
  frameworkFrontendProps,
  frameworkBackendProps,
  isForkFlow,
  githubAccountName,
  repoName,
  githubUserRepos,
  // We keep basePath for backward compatibility for Deploy buttons that already have it in the URL
  // If basePath is not provided, we default to "."
  basePath = ".",
  envVarsKeys,
  isGenezioDeployBtn,
}) => {
  const [isDeployingLoading, setIsDeployingLoading] = useState<boolean>(false);

  // modal state for frontend directory
  const [showFrontendDirectoryModal, setShowFrontendDirectoryModal] = useState<boolean>(false);
  const [selectedFrontendPath, setSelectedFrontendPath] = useState<string>(projectConfiguration?.frontend?.path || ".");

  // modal state for backend directory
  const [showBackendDirectoryModal, setShowBackendDirectoryModal] = useState<boolean>(false);
  const [selectedBackendPath, setSelectedBackendPath] = useState<string>(projectConfiguration?.backend?.path || "./");

  const [frameworkFrontend, setFrameworkFrontend] = useState<string>(frameworkFrontendProps);
  const [frameworkBackend, setFrameworkBackend] = useState<string>(frameworkBackendProps || "other");

  const [projectConfigurationInput, setProjectConfigurationInput] = useState<any>(projectConfiguration || {});
  const [allProjects, setAllProjects] = useState<any>([]);
  const [repositoryName, setRepositoryName] = useState<string>(repoName || "");
  const [privateRepo, setPrivateRepo] = useState<boolean>(false);
  const [frameworkSettingsFrontend, setFrameworkSettingsFrontend] = useState<FrameworkSettingsFrontend>(
    mapFrameworkFrontendToSettings(frameworkFrontend || "other"),
  );
  const [attemptedDeploy, setAttemptedDeploy] = useState<boolean>(false);
  // env
  const [env, setEnv] = useState<any>([]);
  const [envInput, setEnvInput] = useState<{
    name: string;
    value: string;
  }>({
    name: "",
    value: "",
  });
  // functions
  const [backendFunction, setBackendFunction] = useState<FunctionConfig>({
    entry: projectConfiguration?.backend?.functions ? projectConfiguration?.backend?.functions?.[0]?.entry || "" : "",
    handler: projectConfiguration?.backend?.functions
      ? projectConfiguration?.backend?.functions[0].handler || "handler"
      : "handler",
    name: projectConfiguration?.backend?.functions
      ? projectConfiguration?.backend?.functions?.[0]?.name || `function-${frameworkBackendProps || "hello-world"}`
      : "",
    path: projectConfiguration?.backend?.functions ? projectConfiguration?.backend?.functions[0].path || "." : ".",
    type: projectConfiguration?.backend?.functions ? projectConfiguration?.backend?.functions?.[0]?.type || "" : "",
  });

  const handleAddEnv = () => {
    const formatedEnv = parseEnv(envInput.name + "=" + envInput.value);
    setEnv((prevValue: any) => [...prevValue, ...formatedEnv]);
    setEnvInput({
      name: "",
      value: "",
    });
  };
  const handlePasteEnv = (event: any) => {
    const data = event.clipboardData.getData("text");
    if (!data.includes("=")) {
      return;
    }
    const parsedData = parseEnv(data);
    event.preventDefault();
    const updatedFileContent = env.filter((obj: any) => Object.keys(obj).length > 0);
    setEnv([...updatedFileContent, ...parsedData]);
    setEnvInput({
      name: "",
      value: "",
    });
  };
  const handleRemoveEnv = (indexToRemove: any) => {
    setEnv((prevEnv: any) => prevEnv.filter((_: any, idx: any) => idx !== indexToRemove));
  };

  useEffect(() => {
    async function fetchProjects() {
      const resProjects = await getProjectsForUser(0, Number.MAX_SAFE_INTEGER);
      setAllProjects(resProjects.data?.projects);
    }
    fetchProjects();
    if (envVarsKeys && envVarsKeys.length > 0) {
      envVarsKeys.forEach((key) => {
        setEnv((prevValue: any) => [...prevValue, { name: key, value: "" }]);
      });
    }
  }, []);

  const getDefaultEntry = (frameworkBackend: string) => {
    if (["express", "fastify", "node-function"].includes(frameworkBackend)) {
      return "index.mjs";
    }
    if (["flask", "django", "fastapi", "python-function"].includes(frameworkBackend)) {
      return "app.py";
    }
    return "";
  };

  return (
    <>
      {/* Modal for frontend directory */}
      <ProjectFolderModal
        githubRepositoryId={githubRepositoryId}
        onHide={() => setShowFrontendDirectoryModal(false)}
        onSelect={setSelectedFrontendPath}
        show={showFrontendDirectoryModal}
        selectedPath={selectedFrontendPath}
      />

      {/* Modal for backend directory */}
      <ProjectFolderModal
        githubRepositoryId={githubRepositoryId}
        onHide={() => setShowBackendDirectoryModal(false)}
        onSelect={setSelectedBackendPath}
        show={showBackendDirectoryModal}
        selectedPath={selectedBackendPath}
      />

      <Col lg={1}></Col>

      <Col className="border rounded p-4" lg={8}>
        {isForkFlow && (
          <div className="mb-4">
            {/* Project info */}
            <h4 className="m-0 mb-2 fw-bold">Create GitHub repository</h4>
            <p>
              To ensure seamless updates to your project after deployment, a GitHub repository must be created. Each
              push to this GitHub repository will trigger an automatic deployment.
            </p>
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 mt-3">GitHub account</Form.Label>
              <Form.Control value={githubAccountName} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 mt-3">Repository name</Form.Label>
              <Form.Control
                value={repositoryName}
                onChange={(e) => {
                  setRepositoryName(e.target.value);
                }}
              />
              <div className={`invalid-feedback ${!repositoryName && attemptedDeploy ? "d-block" : ""}`}>
                Repository name can't be empty.
              </div>
              <div
                className={`invalid-feedback ${
                  githubUserRepos && githubUserRepos.find((r: any) => r.name === repositoryName) ? "d-block" : ""
                }`}
              >
                Repository with this name already exists.
              </div>
            </Form.Group>
            <Form.Group className="mb-3 text-dark">
              <Form.Check
                type="checkbox"
                label="Create private Git Repository"
                name="visibility"
                id="public"
                checked={privateRepo}
                onChange={(e) => setPrivateRepo(e.target.checked)}
              />
            </Form.Group>
          </div>
        )}
        {/* Project info */}
        <h4 className="m-0 fw-bold">Set up your project</h4>
        <Form.Group className="mb-3">
          <Form.Label className="mb-2 mt-3">Project Name</Form.Label>
          <Form.Control
            onChange={(e) => {
              setProjectConfigurationInput((prevValue: any) => ({ ...prevValue, name: e.target.value }));
            }}
            value={projectConfigurationInput.name}
            name="subject"
            placeholder="Project name"
          />
          <div
            className={`invalid-feedback ${
              !/^[a-zA-Z][-a-zA-Z0-9]*$/.test(projectConfigurationInput.name) && attemptedDeploy ? "d-block" : ""
            }`}
          >
            Project name must start with a letter and can contain letters, numbers, and hyphens. No spaces or special
            characters are allowed.
          </div>
          <div
            className={`invalid-feedback ${
              allProjects.find((p: any) => p.name === projectConfigurationInput.name) ? "d-block" : ""
            }`}
          >
            Project name already exists.
          </div>
        </Form.Group>
        {projectConfigurationInput.region && (
          <Form.Group className="mb-3 text-dark">
            <Form.Label className="mb-2 mt-3">Project Region:</Form.Label>
            <Form.Select
              style={{ color: "#4a4a69", height: "40px" }}
              onChange={(e: any) => {
                setProjectConfigurationInput((prevValue: any) => ({ ...prevValue, region: e.target.value }));
              }}
              value={projectConfigurationInput.region}
            >
              <option value="us-east-1">US East (N. Virginia)</option>
              <option value="eu-central-1">Europe (Frankfurt) </option>
            </Form.Select>
          </Form.Group>
        )}

        {!isGenezioDeployBtn ? (
          <>
            <Accordion
              className="border rounded p-3 mb-3"
              // active only if projectConfigurationInput.frontend
              defaultActiveKey={
                projectConfigurationInput.frontend ||
                projectConfigurationInput.nextjs ||
                projectConfigurationInput.nuxt ||
                projectConfigurationInput.nitro
                  ? ["0"]
                  : ["1"]
              }
              alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header className="mb-0">Frontend Configuration</Accordion.Header>

                <Accordion.Body className="p-0">
                  <Form.Group className="mb-3 text-dark">
                    <Form.Label className="mb-2 mt-3 d-flex align-items-center">
                      Frontend Directory
                      <InfoTooltip>Path to your frontend</InfoTooltip>
                    </Form.Label>
                    <Row className="d-flex align-items-start">
                      <Col lg={10}>
                        <Form.Group className="mb-0">
                          <Form.Control
                            value={selectedFrontendPath || "./"}
                            name="path"
                            placeholder="./"
                            onChange={(e) => {
                              if (e.target.value.startsWith("/")) {
                                e.target.value = e.target.value.slice(1);
                              }
                              setSelectedFrontendPath(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <button
                          onClick={() => setShowFrontendDirectoryModal(true)}
                          className="btn btn-outline-primary w-100"
                        >
                          Change
                        </button>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3 text-dark">
                    <Form.Label className="mb-2 mt-3">
                      Framework Preset
                      <InfoTooltip>
                        Select the frontend framework your project uses to configure optimal build settings. Choose
                        "Other" if not listed.
                      </InfoTooltip>
                    </Form.Label>
                    <Form.Select
                      style={{ color: "#4a4a69", height: "40px" }}
                      onChange={(e: any) => {
                        setFrameworkFrontend(e.target.value);
                        setFrameworkSettingsFrontend(mapFrameworkFrontendToSettings(e.target.value));
                      }}
                      value={frameworkFrontend}
                    >
                      {frameworks.map((f) => (
                        <option key={f.slug} value={f.slug} selected={f.slug === frameworkFrontend}>
                          {f.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {/* Build Settings */}

                  {/* <Accordion className="border rounded p-3 mb-3" defaultActiveKey={["0"]} alwaysOpen>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="mb-2">
                      Build Settings
                      <InfoTooltip>You can modify this later in the genezio.yaml file.</InfoTooltip>
                      </Accordion.Header>
                        <Accordion.Body className="p-0 "> */}
                  {!["next", "nuxt", "nitro"].includes(frameworkFrontend) && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-2 mt-3 d-flex align-items-center">
                          Build Command
                          <InfoTooltip>
                            Input the command for compiling your project, typically npm run build.
                          </InfoTooltip>
                        </Form.Label>
                        <Form.Control
                          placeholder="Build Command"
                          value={frameworkSettingsFrontend.buildCommand}
                          onChange={(e) => {
                            setFrameworkSettingsFrontend((prevValue: any) => ({
                              ...prevValue,
                              buildCommand: e.target.value,
                            }));
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-2 mt-3 d-flex align-items-center">
                          Build Directory
                          <InfoTooltip>
                            Specify the directory for your compiled files, usually build, or dist.
                          </InfoTooltip>
                        </Form.Label>
                        <Form.Control
                          placeholder="Build Directory"
                          value={frameworkSettingsFrontend.buildOutput}
                          onChange={(e) => {
                            setFrameworkSettingsFrontend((prevValue: any) => ({
                              ...prevValue,
                              buildOutput: e.target.value,
                            }));
                          }}
                        />
                      </Form.Group>
                    </>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 mt-3">Install Dependencies Command</Form.Label>
                    <Form.Control
                      placeholder="Install Dependencies Command"
                      value={frameworkSettingsFrontend.installCommand}
                      onChange={(e) => {
                        setFrameworkSettingsFrontend((prevValue: any) => ({
                          ...prevValue,
                          installCommand: e.target.value,
                        }));
                      }}
                    />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion
              className="border rounded p-3 mb-3"
              // active only if projectConfigurationInput.backend
              defaultActiveKey={projectConfigurationInput.backend ? ["0"] : ["1"]}
              alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header className="mb-0">Backend Configuration</Accordion.Header>

                <Accordion.Body className="p-0">
                  {frameworkBackend !== "other" && (
                    <Form.Group className="mb-3 text-dark">
                      <Form.Label className="mb-2 mt-3 d-flex align-items-center">
                        Backend Directory
                        <InfoTooltip>Path to your backend</InfoTooltip>
                      </Form.Label>
                      <Row className="d-flex align-items-start">
                        <Col lg={10}>
                          <Form.Group className="mb-0">
                            <Form.Control
                              value={selectedBackendPath || "./"}
                              name="path"
                              placeholder="./"
                              onChange={(e) => {
                                if (e.target.value.startsWith("/")) {
                                  e.target.value = e.target.value.slice(1);
                                }
                                setSelectedBackendPath(e.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2}>
                          <button
                            onClick={() => setShowBackendDirectoryModal(true)}
                            className="btn btn-outline-primary w-100"
                          >
                            Change
                          </button>
                        </Col>
                      </Row>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3 text-dark">
                    <Form.Label className="mb-2 mt-3">
                      Framework
                      <InfoTooltip>
                        Select the backend framework your project uses to configure optimal build settings.
                      </InfoTooltip>
                    </Form.Label>
                    <Form.Select
                      style={{ color: "#4a4a69", height: "40px" }}
                      onChange={(e: any) => {
                        setBackendFunction((prevValue: any) => ({
                          ...prevValue,
                          type: e.target.value,
                          name: `function-${e.target.value}`,
                        }));
                        setFrameworkBackend(e.target.value);
                      }}
                      value={frameworkBackend}
                    >
                      {backendFrameworks.map((f) => (
                        <option key={f.slug} value={f.slug} selected={f.slug === frameworkBackend}>
                          {f.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {frameworkBackend !== "other" && frameworkBackend !== "genezio-typesafe" && (
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-2 mt-3">Main File</Form.Label>
                      <Form.Control
                        placeholder={getDefaultEntry(frameworkBackend)}
                        value={backendFunction.entry ?? getDefaultEntry(frameworkBackend)}
                        onChange={(e) => {
                          setBackendFunction((prevValue: any) => ({ ...prevValue, entry: e.target.value }));
                        }}
                      />
                    </Form.Group>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        ) : (
          <>
            <p>
              We detected a genezio.yaml file and prefilled the project configuration for you. You can modify this later
              in the genezio.yaml file.
            </p>
          </>
        )}
        {((envVarsKeys && envVarsKeys.length > 0) || isGenezioDeployBtn) && (
          <>
            {/* Environment Variables */}
            <Accordion className="border rounded p-3 mb-3" defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="mb-0">
                  Environment Variables
                  <InfoTooltip>
                    You can change this later on in your project dashboard under the Environment Variables section.
                  </InfoTooltip>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <Row className="d-flex align-items-end mb-3">
                    <Col lg={5}>
                      <Form.Group className="w-100">
                        <Form.Label className="mb-2 mt-3">Key</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setEnvInput((prevValue: any) => ({ name: e.target.value, value: prevValue.value }));
                          }}
                          onPaste={(e) => handlePasteEnv(e)}
                          value={envInput.name}
                          name="name"
                          placeholder="EXAMPLE_NAME"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={5} className="d-flex">
                      <Form.Group className="w-100">
                        <Form.Label className="mb-2 mt-3">Value</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setEnvInput((prevValue: any) => ({ name: prevValue.name, value: e.target.value }));
                          }}
                          value={envInput.value}
                          name="value"
                          placeholder="EXAMPLE_VALUE"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <button
                        disabled={envInput.name && envInput.value ? false : true}
                        onClick={() => handleAddEnv()}
                        className="btn btn-outline-primary w-100"
                      >
                        Add
                      </button>
                    </Col>
                  </Row>
                  <p>
                    <b>TIP:</b> Paste .env content above to automatically fill in the form
                  </p>
                  {/* Map added env */}
                  {env?.map((currentEnv: any, index: any) => (
                    <Row key={index} className="d-flex align-items-start mb-3">
                      <Col lg={5}>
                        <Form.Group className="w-100">
                          <Form.Control
                            onChange={(e) => {
                              setEnv((prevEnv: any) =>
                                prevEnv.map((item: any, idx: any) =>
                                  idx === index ? { ...item, name: e.target.value } : item,
                                ),
                              );
                            }}
                            value={currentEnv.name}
                            name="name"
                            placeholder="EXAMPLE_NAME"
                          />
                          <div className={`invalid-feedback ${!currentEnv.name && attemptedDeploy ? "d-block" : ""}`}>
                            Key can't be empty.
                          </div>
                          <div
                            className={`invalid-feedback ${
                              currentEnv.name.length > 0 && currentEnv.name.length < 2 && attemptedDeploy
                                ? "d-block"
                                : ""
                            }`}
                          >
                            Key be at least 2 characters{" "}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={5} className="d-flex">
                        <Form.Group className="w-100">
                          <Form.Control
                            onChange={(e) => {
                              setEnv((prevEnv: any) =>
                                prevEnv.map((item: any, idx: any) =>
                                  idx === index ? { ...item, value: e.target.value } : item,
                                ),
                              );
                            }}
                            value={currentEnv.value}
                            name="value"
                            placeholder="EXAMPLE_VALUE"
                          />
                          <div className={`invalid-feedback ${!currentEnv.value && attemptedDeploy ? "d-block" : ""}`}>
                            Value can't be empty.
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={2} className="d-flex justify-content-center">
                        <Button variant="outline-danger" className="w-75" onClick={() => handleRemoveEnv(index)}>
                          <i className="mx-1 far fa-trash-alt" />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )}
        {/* Deploy */}
        <Button
          disabled={isDeployingLoading}
          className="w-100"
          onClick={async (e) => {
            e.preventDefault();
            setAttemptedDeploy(true);
            if (
              !projectConfigurationInput.name ||
              allProjects.find((p: any) => p.name === projectConfigurationInput.name) ||
              !/^[a-zA-Z][-a-zA-Z0-9]*$/.test(projectConfigurationInput.name)
            ) {
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;
            }
            // check if any env[].name is empty or any env[].value is empty
            const invalidEnv = env.some((e: any) => !e.name || !e.value);
            if (invalidEnv) {
              return;
            }
            if (isForkFlow && (!repositoryName || githubUserRepos.find((r: any) => r.name === repositoryName))) {
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;
            }
            let finalProjectConfig: any = {
              yamlVersion: 2,
            };
            finalProjectConfig.name = projectConfigurationInput.name;
            finalProjectConfig.region = projectConfigurationInput.region;
            if (!isGenezioDeployBtn) {
              // frontend settings
              if (frameworkFrontend === "next") {
                finalProjectConfig.nextjs = {
                  path: selectedFrontendPath,
                  packageManager: "npm",
                  scripts: {
                    deploy: [frameworkSettingsFrontend.installCommand],
                  },
                };
              } else if (frameworkFrontend === "nuxt") {
                finalProjectConfig.nuxt = {
                  path: selectedFrontendPath,
                  packageManager: "npm",
                  scripts: {
                    deploy: [frameworkSettingsFrontend.installCommand],
                  },
                };
              } else if (frameworkFrontend === "nitro") {
                finalProjectConfig.nitro = {
                  path: selectedFrontendPath,
                  packageManager: "npm",
                  scripts: {
                    deploy: [frameworkSettingsFrontend.installCommand],
                  },
                };
              } else if (frameworkSettingsFrontend.buildOutput) {
                finalProjectConfig.frontend = {
                  path: selectedFrontendPath,
                  publish: frameworkSettingsFrontend.buildOutput,
                  scripts: {
                    build: [frameworkSettingsFrontend.buildCommand],
                    deploy: [frameworkSettingsFrontend.installCommand],
                  },
                };
              }

              if (projectConfiguration?.frontend?.environment) {
                finalProjectConfig.frontend.environment = projectConfiguration.frontend.environment;
              }

              if (frameworkBackend === "genezio-typesafe") {
                finalProjectConfig.backend = projectConfiguration.backend;
                finalProjectConfig.frontend = projectConfiguration.frontend;
              } else if (frameworkBackend === "other") {
                finalProjectConfig.backend = projectConfiguration.backend;
              }

              if (backendFunction?.entry) {
                finalProjectConfig.backend = {
                  path: selectedBackendPath,
                  scripts: projectConfiguration.backend?.scripts,
                  language: projectConfiguration.backend?.language || {
                    name: "js",
                  },
                  functions: [
                    {
                      name: backendFunction.name,
                      path: backendFunction.path || ".",
                      handler: backendFunction.handler,
                      type: backendFunction.type,
                      entry: backendFunction.entry,
                    },
                  ],
                };
              }
            }
            setIsDeployingLoading(true);
            await handleDeployProject(
              githubRepositoryId,
              basePath,
              finalProjectConfig,
              env,
              repositoryName,
              privateRepo,
            );
            setIsDeployingLoading(false);
          }}
          id={isForkFlow ? `${repoName}-create-btn` : "import-project-create-btn"}
        >
          Create {isDeployingLoading && <Spinner animation="border" size="sm" className="ms-1" />}
        </Button>
      </Col>
    </>
  );
};
export default ProjectConfiguration;
