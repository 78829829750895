import axios from "axios";
import { getPresignedURLForProjectCode, getPresignedURLForProjectCodeUpload } from "./network/ApiAxios";
import { fileTypeFromBlob, FileTypeResult } from "file-type";
import JSZip from "jszip";

export function getUTMSignupSource(urlParams: URLSearchParams): string {
  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmCampaign = urlParams.get("utm_campaign");

  if (!utmSource && !utmMedium && !utmCampaign) {
    return "";
  }

  return [utmSource, utmMedium, utmCampaign].map((e) => (Boolean(e) ? e : "*")).join("/");
}

export function getGoogleClickId(urlParams: URLSearchParams): string {
  return urlParams.get("gclid") || "";
}

export const capitalizeFirstLetter = (word: string) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
};

export function copyToClipboard(
  textToCopy: string,
  btn: string,
  buttonCopyState: string,
  setButtonCopyState: (value: string) => void,
) {
  if (buttonCopyState) {
    return;
  }

  setButtonCopyState(btn);
  navigator.clipboard.writeText(textToCopy);
  setTimeout(() => {
    setButtonCopyState("");
  }, 500);
}

export const defaultReservedEnvNames = ["NODE_OPTIONS"];

export const DEFAULT_STAGE_NAME = "prod";

export const nonBinaryFileExtensions = [
  "txt",
  "json",
  "yaml",
  "yml",
  "js",
  "ts",
  "jsx",
  "tsx",
  "md",
  "html",
  "css",
  "scss",
  "less",
  "graphql",
  "gql",
  "xml",
  "csv",
  "log",
  "env",
  "sh",
  "bash",
  "zsh",
  "fish",
  "ps1",
  "bat",
  "cmd",
  "py",
  "rb",
  "php",
  "java",
  "go",
  "pl",
  "perl",
  "r",
  "lua",
  "swift",
  "kt",
  "kts",
  "kotlin",
  "scala",
  "groovy",
  "rust",
  "rs",
  "cs",
  "csharp",
  "c",
  "h",
  "cpp",
  "hpp",
  "hxx",
  "h++",
  "cc",
  "cxx",
  "c++",
  "m",
  "mm",
  "makefile",
  "mk",
  "make",
  "dockerfile",
  "gradle",
  "properties",
  "ini",
  "conf",
  "toml",
  "lock",
  "gitignore",
  "npmrc",
  "yarnrc",
  "editorconfig",
  "gitattributes",
  "babelrc",
  "eslintrc",
  "prettierrc",
  "stylelintrc",
  "eslintignore",
  "prettierignore",
  "stylelintignore",
  "gitignore",
  "dockerignore",
  "npmignore",
  "yarnignore",
  "jshintrc",
  "flowconfig",
];

const fileNameToNotShowInEditor = ["package-lock.json", "yarn.lock", "pnpm-lock.yaml"];

export async function getEmbedProject(projectName: string, region: string, stage: string) {
  const presignedUrl = await getPresignedURLForProjectCode(projectName, region, stage);

  // download the archive from the presigned URL
  const response = await axios
    .get(presignedUrl, {
      responseType: "blob",
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

  if (!response) {
    return null;
  }

  const blob = response.data;
  const zip = new JSZip();
  await zip.loadAsync(blob);
  const files = zip.files;
  const filesContent: any = {};
  const binaryFilesContent: any = {};
  const isBase64EncodedContent: any = {};

  async function readFilesContent(files: any, prefix: string) {
    if (!files) return;

    for (const file of Object.keys(files)) {
      if (
        file.includes("dist/") ||
        file.includes("build/") ||
        file.includes("node_modules/") ||
        fileNameToNotShowInEditor.includes(file)
      ) {
        continue;
      }

      if (files[file].dir) {
        await readFilesContent(files[file].files, `${prefix}${file}/`);
      } else {
        let isBinaryFile: FileTypeResult | boolean | undefined = false;
        // Check if the file extension is in the list of nonBinaryFileExtensions
        const extension = file.split(".").pop();
        if (extension && extension.length > 0 && !nonBinaryFileExtensions.includes(extension)) {
          isBinaryFile = await fileTypeFromBlob(await files[file].async("blob"));
        }
        if (!isBinaryFile) {
          filesContent[`${prefix}${file}`] = await files[file].async("string");
          isBase64EncodedContent[`${prefix}${file}`] = false;
        } else {
          filesContent[`${prefix}${file}`] = "";
          binaryFilesContent[`${prefix}${file}`] = await files[file].async("base64");
          isBase64EncodedContent[`${prefix}${file}`] = true;
        }
      }
    }
  }

  await readFilesContent(files, "");

  return { files, filesContent, binaryFilesContent, isBase64EncodedContent };
}

export async function uploadProjectFilesToS3(projectName: string, region: string, stage: string, files: any) {
  const zip = new JSZip();
  for (const file of Object.keys(files)) {
    if (files[file].dir) {
      zip.folder(file);
    } else {
      zip.file(file, files[file]);
    }
  }

  const blob = await zip.generateAsync({ type: "blob" });
  const formData = new FormData();
  formData.append("file", blob);

  const urlResponse = await getPresignedURLForProjectCodeUpload(projectName, region, stage);
  const presignedUrl = urlResponse.data.presignedURL;
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open("PUT", presignedUrl);

    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.setRequestHeader("Content-Length", blob.size.toString());

    // Update progress
    xhr.upload.onprogress = () => {};

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(true);
      } else {
        reject(new Error(`Upload failed with status: ${xhr.status}`));
      }
    };

    xhr.onerror = (e) => {
      reject(e);
    };

    xhr.send(formData);
  });
}
