import { useState, useEffect } from "react";
import { Col, Card, Row, Tabs, Tab, Button, InputGroup, Breadcrumb, Form, Spinner } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import ReactEcharts from "echarts-for-react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { getAllMetrics, getProjectsForUser } from "../../network/ApiAxios";
import {
  getErrorLogOption,
  getBandwidthOption,
  getInvocationOption,
  getDurationOption,
  getPerformanceOption,
  getNoMetricsOptions,
} from "./utils";

export const timelineOptions = [
  { label: "Last 12h", value: 12 * 3600 * 1000 },
  { label: "Last 24h", value: 24 * 3600 * 1000 },
  { label: "Last 48h", value: 48 * 3600 * 1000 },
  { label: "Last Week", value: 168 * 3600 * 1000 },
  { label: "Last Month", value: 720 * 3600 * 1000 },
  { label: "Custom", value: "custom" },
];

const chartOptions = {
  text: "Loading...",
  color: "#6f42c1", // Spinner color
  zlevel: 0,
};

const timeGranularityOptions = [
  { label: "Hourly", value: "hourly" },
  { label: "Daily", value: "daily" },
];

const Monitoring = () => {
  const { projectId, envId } = useParams();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(timelineOptions[0]);
  const [selectedTimeGranularity, setSelectedTimeGranularity] = useState<any>(timeGranularityOptions[0]);
  const [metricsData, setMetricsData] = useState<any>();
  const [allProjects, setAllProjects] = useState<any>([]);
  const [projectFilter, setProjectFilter] = useState<any>(projectId);
  const [customTimeline, setCustomTimeline] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [customDates, setCustomDates] = useState<any>({});

  const [isFromProjectPage, setIsFromProjectPage] = useState(projectId ? true : false);

  const pageKey = "monitoring";

  useEffect(() => {
    document.title = "Genezio | Monitoring";
  }, []);

  useEffect(() => {
    fetchUserProjects();
  }, []);

  useEffect(() => {
    fetchMonitoring();
  }, [selectedFilter, selectedTimeGranularity, projectFilter]);

  const navigation = useNavigate();

  const fetchMonitoring = async () => {
    setLoading(true);
    const timeNow = new Date().getTime();
    const res = await getAllMetrics(
      customDates?.startTime ? customDates?.startTime : timeNow - selectedFilter?.value,
      customDates?.endTime ? customDates?.endTime : timeNow,
      projectFilter,
    );

    if (res?.data?.status === "ok" && res?.status === 200) {
      setMetricsData(res?.data);
    } else {
      alert("Unable to get monitoring data");
    }

    setFirstLoad(false);
    setLoading(false);
  };

  const fetchUserProjects = async () => {
    setProjectsLoading(true);
    const res: any = await getProjectsForUser(0, 0);
    if (res.status === 200 && res.data.status === "ok") {
      setAllProjects(res.data.projects);
      setProjectsLoading(false);
      return;
    }

    alert("Unable to get projects");
    setProjectsLoading(false);
  };

  const handleTimeFilter = (e: any) => {
    setCustomDates({});

    if (e.value === "custom") {
      setCustomTimeline(true);
      return;
    }

    setCustomTimeline(false);
    setSelectedFilter(e);
  };

  const handleProjectFilter = (e: any) => {
    setProjectFilter(e);

    if (isFromProjectPage) {
      navigation(`/monitoring/${e}`, { replace: true });
    }
  };

  const handleCustomDate = (e: any, name: string) => setCustomDates((prevValue: any) => ({ ...prevValue, [name]: e }));

  return (
    <>
      {!isFromProjectPage ? (
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item
                className="breadcrumb-item"
                onClick={() => navigation("/")}
                aria-current="page"
                style={{ paddingTop: "1px" }} // hack don;t change it
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item" aria-current="page" active>
                Monitoring
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      ) : (
        ""
      )}

      <Row className="mt-3">
        <Col sm={12} className="col-12">
          <Card>
            {!isFromProjectPage ? (
              <Tabs
                id="controlled-tab-example"
                activeKey={pageKey}
                onSelect={(k) => {
                  if (k === "profile") navigation("/profile");
                  if (k === "billing") navigation("/billing");
                }}
              >
                <Tab eventKey="profile" title="Profile"></Tab>
                <Tab eventKey="billing" title="Billing"></Tab>
                <Tab eventKey="monitoring" title="Monitoring" active disabled></Tab>
              </Tabs>
            ) : (
              ""
            )}

            <Card.Header>
              <h4 className="card-title" style={{ textTransform: "none" }}>
                Monitoring
              </h4>
            </Card.Header>
            <Card.Body className="pt-0 pb-2">
              <Row className="p-0">
                {!isFromProjectPage ? (
                  <Col lg={3} className="px-0">
                    <Select
                      isLoading={projectsLoading}
                      onChange={(e) => {
                        handleProjectFilter(e?.value);

                        console.log(e, "wasd");
                      }}
                      defaultValue={
                        allProjects
                          ?.filter((x: any) => x?.id === projectFilter)
                          ?.map((project: any) => ({ label: project.name, value: project?.id }))
                          ? allProjects
                              ?.filter((x: any) => x?.id === projectFilter)
                              ?.map((project: any) => ({ label: project.name, value: project?.id }))
                          : { label: "All Projects", value: "" }
                      }
                      value={
                        allProjects
                          ?.filter((x: any) => x?.id === projectFilter)
                          ?.map((project: any) => ({ label: project.name, value: project?.id }))?.length
                          ? allProjects
                              ?.filter((x: any) => x?.id === projectFilter)
                              ?.map((project: any) => ({ label: project.name, value: project?.id }))
                          : { label: "All Projects", value: "" }
                      }
                      options={[
                        { label: "All Projects", value: "" },
                        ...allProjects?.map((project: any) => ({ label: project.name, value: project?.id })),
                      ]}
                      placeholder="Project filter"
                      className="mb-3 mb-lg-0"
                    />
                  </Col>
                ) : (
                  ""
                )}
                <Col lg={3} className="px-lg-2 px-0">
                  <Select
                    options={timeGranularityOptions}
                    value={selectedTimeGranularity}
                    onChange={(e) => setSelectedTimeGranularity(e)}
                    placeholder="Time Granularity"
                    className="mb-3 mb-lg-0"
                  />
                </Col>
                {/* Filters Col */}
                <Col
                  lg={!isFromProjectPage ? 6 : 9}
                  xs={12}
                  className="d-flex justify-content-end p-0 flex-lg-nowrap flex-wrap"
                >
                  {timelineOptions?.map((timeline, index) => (
                    <Button
                      onClick={() => handleTimeFilter(timeline)}
                      className={`ms-0 ms-lg-2 mb-2 mb-lg-0 w-100 w-lg-auto`}
                      variant={
                        (selectedFilter?.label === timeline?.label && !customTimeline) ||
                        (timeline?.label === "Custom" && customTimeline)
                          ? "primary"
                          : "outline-primary"
                      }
                      key={index}
                    >
                      {timeline?.label}
                    </Button>
                  ))}
                </Col>
              </Row>

              {customTimeline && (
                <Row className="d-flex justify-content-end px-0 mt-3">
                  <Col md={2} className="px-0">
                    <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                      Start Time
                    </p>
                    <InputGroup className="input-group col-xl-12 datetimepicker-2 px-0">
                      <Form className="form-control" noValidate>
                        <TextField
                          id="datetime-local"
                          type="datetime-local"
                          onChange={(e) => {
                            handleCustomDate(new Date(e.target.value).getTime(), "startTime");
                          }}
                        />
                      </Form>
                    </InputGroup>
                  </Col>
                  <Col md={2} className="px-0 ms-2 mt-lg-0 mt-3">
                    <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                      End Time
                    </p>
                    <InputGroup className="input-group col-xl-12 datetimepicker-2 px-0">
                      <Form className="form-control" noValidate>
                        <TextField
                          id="datetime-local"
                          type="datetime-local"
                          onChange={(e) => {
                            handleCustomDate(new Date(e.target.value).getTime(), "endTime");
                          }}
                        />
                      </Form>
                    </InputGroup>
                  </Col>
                  <Col md={2} className="d-flex align-items-end ps-lg-2 px-0 mt-lg-0 mt-3">
                    <Button
                      disabled={!customDates?.startTime || !customDates?.endTime || loading ? true : false}
                      className="w-100"
                      onClick={() => fetchMonitoring()}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              )}

              {firstLoad ? (
                <Row>
                  <Col className="d-flex align-items-center justify-content-center py-5">
                    <Spinner variant="primary" animation="border" />
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className="mt-3">
                    <Col xs={12} md className="p-0">
                      <div className="border d-flex justify-content-center align-items-center pt-3">
                        <ReactEcharts
                          showLoading={loading}
                          loadingOption={chartOptions}
                          option={
                            metricsData?.backendMetrics?.length
                              ? getInvocationOption(metricsData, selectedTimeGranularity)
                              : getNoMetricsOptions(
                                  selectedTimeGranularity,
                                  customTimeline && customDates?.startTime && customDates?.endTime
                                    ? {
                                        value: "Custom",
                                        startTime: customDates?.startTime,
                                        endTime: customDates?.endTime,
                                      }
                                    : selectedFilter,
                                  { title: "Invocations", tooltip: "Invocations", yAxisTitle: "Invocations Count" },
                                )
                          }
                          style={{ height: "400px", width: "100%" }}
                        />
                      </div>
                    </Col>

                    <Col xs={12} md className="mx-md-0 mx-md-3 px-md-3 px-0 my-3 my-md-0">
                      <div className="border d-flex justify-content-center align-items-center pt-3">
                        <ReactEcharts
                          showLoading={loading}
                          loadingOption={chartOptions}
                          option={
                            metricsData?.backendMetrics?.length || metricsData?.frontendMetrics?.length
                              ? getBandwidthOption(metricsData, selectedTimeGranularity)
                              : getNoMetricsOptions(
                                  selectedTimeGranularity,
                                  customTimeline && customDates?.startTime && customDates?.endTime
                                    ? {
                                        value: "Custom",
                                        startTime: customDates?.startTime,
                                        endTime: customDates?.endTime,
                                      }
                                    : selectedFilter,
                                  { title: "Bandwidth Usage", tooltip: "GB", yAxisTitle: "Bandwidth (GB)" },
                                )
                          }
                          style={{ height: "400px", width: "100%" }}
                        />
                      </div>
                    </Col>

                    <Col xs={12} md className="my-3 p-0 my-md-0">
                      <div className="border pt-3">
                        <ReactEcharts
                          showLoading={loading}
                          loadingOption={chartOptions}
                          option={
                            metricsData?.backendMetrics?.length
                              ? getPerformanceOption(metricsData, selectedTimeGranularity)
                              : getNoMetricsOptions(
                                  selectedTimeGranularity,
                                  customTimeline && customDates?.startTime && customDates?.endTime
                                    ? {
                                        value: "Custom",
                                        startTime: customDates?.startTime,
                                        endTime: customDates?.endTime,
                                      }
                                    : selectedFilter,
                                  {
                                    title: "Performance",
                                    tooltip: "GB/hour",
                                    yAxisTitle: "AVG GB/hour",
                                  },
                                )
                          }
                          style={{ height: "400px", width: "100%" }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-md-5 mt-3">
                    <Col xs={12} md className="p-0">
                      <div className="border pt-3">
                        <ReactEcharts
                          showLoading={loading}
                          loadingOption={chartOptions}
                          option={
                            metricsData?.backendMetrics?.length
                              ? getDurationOption(metricsData, selectedTimeGranularity)
                              : getNoMetricsOptions(
                                  selectedTimeGranularity,
                                  customTimeline && customDates?.startTime && customDates?.endTime
                                    ? {
                                        value: "Custom",
                                        startTime: customDates?.startTime,
                                        endTime: customDates?.endTime,
                                      }
                                    : selectedFilter,
                                  {
                                    title: "Function Duration",
                                    tooltip: "GB/hour",
                                    yAxisTitle: "GB/hour",
                                  },
                                )
                          }
                          style={{ height: "400px", width: "100%" }}
                        />
                      </div>
                    </Col>
                    <Col md={0.5}></Col>

                    <Col xs={12} md className="p-0">
                      <div className="border d-flex justify-content-center align-items-center pt-3">
                        <ReactEcharts
                          showLoading={loading}
                          loadingOption={chartOptions}
                          option={
                            metricsData?.errorLogs?.length
                              ? getErrorLogOption(metricsData, selectedTimeGranularity)
                              : getNoMetricsOptions(
                                  selectedTimeGranularity,
                                  customTimeline && customDates?.startTime && customDates?.endTime
                                    ? {
                                        value: "Custom",
                                        startTime: customDates?.startTime,
                                        endTime: customDates?.endTime,
                                      }
                                    : selectedFilter,
                                  { title: "Error Log Count", tooltip: "Errors", yAxisTitle: "Errors Count" },
                                )
                          }
                          style={{ height: "400px", width: "100%" }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Monitoring;
