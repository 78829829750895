import { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { oauthRedirect } from "../../network/ApiAxios";

const NeonRedirect = () => {
  const currentUrl = window.location.href;

  const extractCodeFromUrl = (url: string) => {
    const urlSearchParams = new URLSearchParams(url.split("?")[1]);
    const codeValue = urlSearchParams.get("code");
    return codeValue ? `code=${codeValue}` : null;
  };

  const extractScopeFromUrl = (url: string) => {
    const urlSearchParams = new URLSearchParams(url.split("?")[1]);
    const scopeValue = urlSearchParams.get("scope");
    return scopeValue ? `scope=${scopeValue}` : null;
  };

  const extractStateFromUrl = (url: string) => {
    const urlSearchParams = new URLSearchParams(url.split("?")[1]);
    const stateValue = urlSearchParams.get("state");
    return stateValue ? `state=${stateValue}` : null;
  };

  const handleRedirect = async () => {
    try {
      const code = extractCodeFromUrl(currentUrl)!;
      const scope = extractScopeFromUrl(currentUrl)!;
      const state = extractStateFromUrl(currentUrl)!;

      const status = await oauthRedirect("neon", `${code}&${scope}&${state}`);

      if (status === "ok") {
        localStorage.setItem("neon-status", status);
        window.close();
      } else {
        alert("Something went wrong!");
        window.close();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    handleRedirect();
  }, []);

  return (
    <Modal show={true} className="welcome-modal pb-5 pb-lg-0" animation={false} centered>
      <Modal.Body className="text-black mb-4 d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
      </Modal.Body>
    </Modal>
  );
};

export default NeonRedirect;
